import React from 'react';
import Tab from '../../common/Tab/Tab';
import { useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const navList = [
  {
    icon: 'dashboard',
    name: 'Dashboard',
    link: '/dashboard',
    badge: '',
  },
  {
    icon: 'feed',
    name: 'Feed',
    link: '/feed',
    badge: '',
  },
  {
    icon: 'bookmark',
    name: 'Saved',
    link: '/dashboard/saved',
    badge: '',
  },
  {
    icon: 'play',
    name: 'Your learning',
    link: '/dashboard/your-learnings',
    badge: '',
  },
  // {
  //   icon: 'marketplace',
  //   name: 'Marketplace',
  //   link: '/marketplace',
  //   badge: '',
  // },
];

const SidebarAuthNav = ({ handleSidebar }) => {
  const isMobile = useReactiveVar(setIsMobile);

  const {
    push,
    location: { pathname },
  } = useHistory();

  const handleTab = (link) => {
    push(link);

    if (isMobile) handleSidebar();
  };

  return (
    <div className="sidebar-nav">
      {navList.map(({ icon, name, link, badge }) => (
        <Tab
          onClick={() => handleTab(link)}
          key={name}
          typeLeft="item"
          typeRight="badge"
          textLeft={name}
          iconLeft={icon}
          active={pathname === link}
          badge={badge}
          color="whitePurple"
        />
      ))}
    </div>
  );
};

export default SidebarAuthNav;
