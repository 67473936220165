import _ from 'lodash';

export const listenerKeyDown = (setPage, prev) => (event) => {
  if ((event.isComposing || event.keyCode === 8) && !event.target.innerText) {
    const {
      id: [index],
    } = event.target;
    event.target.contentEditable = false;

    const i = +index;
    const one = _.slice(prev, 0, i);
    const two = _.slice(prev, i + 1);
    setPage([...one, ...two]);
  }
};

export const listenerFocus =
  (setPage) =>
  ({ target }) => {
    if (target.innerText) {
      const {
        id: [index],
      } = target;
      setPage((prev) => {
        const newData = prev;
        prev[index].value = target.innerText;
        return [...newData];
      });
    }
  };

export const contentEditable = ({ target }) => {
  const {
    id: [index],
  } = target;
  if (index !== undefined) {
    target.contentEditable = true;
    target.focus();
  }
};
