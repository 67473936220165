import React, { useCallback, useMemo, useState } from 'react';
import Button from '../../common/Button/Button';
import TagButton from '../../common/Tag/TagButton';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../gql/cache';
import { interestsQueryGql } from '../../gql/query';
import _ from 'lodash';
import { updateUserMutationGql } from '../../gql/mutation';

const onClick = (fn, prev, item) => () => {
  if (_.includes(prev, item)) {
    return fn([..._.remove(prev, (i) => i !== item)]);
  }
  return fn([...prev, item]);
};

const UserOnboardingSecondStep = ({ handleStep }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const { data } = useQuery(interestsQueryGql);
  const [updateUser] = useMutation(updateUserMutationGql, {
    onCompleted() {
      handleStep((p) => p + 1);
    },
  });
  const [selected, setSelected] = useState([]);

  const tagsArray = useMemo(() => {
    if (data) {
      return data.interests;
    }
    return [];
  }, [data]);

  const nextStep = useCallback(() => {
    updateUser({
      variables: {
        input: {
          where: {
            id: setIsUserId(),
          },
          data: {
            interests: _.map(
              _.filter(data.interests, (i) => _.includes(selected, i.interestTitle)),
              'id'
            ),
          },
        },
      },
    });
  }, [data, selected, updateUser]);

  return (
    <>
      <div className="onboarding__title">Let us know your interests</div>
      <div className="onboarding__subtitle">
        Please, select at least 3. You can always change them later.
      </div>

      <div className="onboarding-tags">
        <div className="onboarding-tags__list">
          {tagsArray.map((value) => (
            <TagButton
              key={value.id}
              text={value.interestTitle}
              icon="topic-filled"
              size="lg"
              onClick={onClick(setSelected, selected, value.interestTitle)}
              active={_.includes(selected, value.interestTitle)}
            />
          ))}
        </div>

        <div className="onboarding-footer">
          <div className="onboarding-footer__buttons">
            <Button
              onClick={() => handleStep((p) => p - 1)}
              text="Back"
              type="outline-purple"
              size={isMobile ? 'md' : 'lg'}
              block={isMobile}
            />

            <Button
              onClick={nextStep}
              text="Next step"
              type="primary"
              size={isMobile ? 'md' : 'lg'}
              block={isMobile}
              disabled={selected.length < 3}
            />
          </div>

          <div className="onboarding-footer__filled">
            {selected.length} from {tagsArray.length} interests selected
          </div>
        </div>
      </div>
    </>
  );
};

export default UserOnboardingSecondStep;
