import React from 'react';
import PageTemplate from '../../common/PageTemplate/PageTemplate';
import SeeAllArticles from '../../common/PageTemplate/SeeAllArticles';

const KnowledgeBaseLearning = () => {
  return (
    <PageTemplate title="Learning" pageFilter={false} onlyDropdown={false}>
      <div className="knowledge-base-learning">
        <SeeAllArticles title="Last added publications" />
        <SeeAllArticles title="Last added courses" />
      </div>
    </PageTemplate>
  );
};

export default KnowledgeBaseLearning;
