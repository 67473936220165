import React, { useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile, setIsUserId, setArticlesArguments } from '../../gql/cache';
import { useParams } from 'react-router-dom';
import ProfileTabBar from './ProfileTabBar';
import ProfileArticles from './ProfileArticles';
import ProfileSidebarUser from './ProfileSidebarComponents/ProfileSidebarUser';
import ProfileSidebarFooter from './ProfileSidebarComponents/ProfileSidebarFooter';
import EmptyDashedArticleGrid from '../../components/Articles/EmptyDashedArticleGrid';
import { useQuery } from '@apollo/client';
import { aggregateConnectionUserProfileGql } from '../../gql/query';
import ProfilePublicEmpty from './ProfileEmptyStates/ProfilePublicEmpty';
import _ from 'lodash';
import { USER_INFO } from '../../constants';

const ProfileContent = ({ user }) => {
  const userInfo = useMemo(() => {
    return _.map(USER_INFO, (item) => {
      return {
        ...item,
        value: _.isArray(user[item.value])
          ? _.get(user, `${item.value}[0].name`, item.value)
          : _.get(user, item.value, item.value),
      };
    });
  }, [user]);

  const [firstName, lastName] = _.map(_.words(user.username), _.capitalize);

  const profileTabsArr = useMemo(
    () => [
      {
        label: 'General info',
        type: 'info',
        content: () => (
          <>
            <ProfileSidebarUser userInfo={userInfo} />
            {/*<ProfileSidebarFooter />*/}
          </>
        ),
      },
      {
        label: 'Publications',
        type: 'publications',
        badge: 0,
        content: (props) => {
          if (!props.articles.length) {
            return user.id === setIsUserId() ? (
              <EmptyDashedArticleGrid
                title="You haven’t published any materials yet"
                image="pensil.svg"
                btn="Add new material"
                btnIcon
                {...props}
              />
            ) : (
              <ProfilePublicEmpty
                title={`${firstName} ${lastName} hasn’t published any materials yet`}
              />
            );
          }
          return <ProfileArticles {...props} />;
        },
      },
      {
        label: 'Claps',
        type: 'claps',
        badge: 0,
        content: (props) => {
          // TODO: it's only empty state, add Articles if Claps length > 0 !!!!
          return user.id === setIsUserId() ? (
            <EmptyDashedArticleGrid
              title="You haven’t clapped to any materials yet"
              image="star.svg"
              btn="Browse feed"
              {...props}
            />
          ) : (
            <ProfilePublicEmpty
              title={`${firstName} ${lastName} hasn’t clapped to any materials yet`}
            />
          );
        },
      },
      // {
      //   label: 'Completed courses',
      //   type: 'courses',
      //   badge: 0,
      //   content: (props) => {
      //     return user.id === setIsUserId() ? (
      //       <EmptyDashedArticleGrid
      //         title="You haven’t completed any course yet"
      //         image="suitcase.svg"
      //         btn="Browse courses"
      //         {...props}
      //       />
      //     ) : (
      //       <ProfilePublicEmpty title={`${user.username} hasn’t completed any course yet`} />
      //     );
      //   },
      // },
    ],
    [user.id, user.username]
  );

  const isMobile = useReactiveVar(setIsMobile);
  const argumentsArticles = useReactiveVar(setArticlesArguments);
  const [badgeAmount, setBadgeAmount] = useState({
    claps: 0,
    courses: 0,
    publications: 0,
  });
  const { id } = useParams();
  const [active, setActive] = useState(isMobile ? 'info' : 'publications');
  const { data: dataArticles } = useQuery(aggregateConnectionUserProfileGql, {
    variables: {
      author: id || user.id,
      articleType: argumentsArticles.articleType,
      sort: argumentsArticles.sort,
    },
    onCompleted({ claps, courses, publications }) {
      setBadgeAmount({
        claps: claps.aggregate.count,
        courses: courses.aggregate.count,
        publications: publications.aggregate.count,
      });
    },
  });

  const tabs = useMemo(() => {
    const tabs = profileTabsArr.filter((item) => item.type !== (isMobile ? '' : 'info'));
    return tabs;
  }, [isMobile, profileTabsArr]);

  const [{ content: Content }] = useMemo(() => {
    return tabs.filter((item) => item.type === active);
  }, [active, tabs]);

  return (
    <div className="profile__inner-content">
      <ProfileTabBar
        active={active}
        setActive={setActive}
        profileTabs={tabs}
        badgeAmount={badgeAmount}
      />
      {dataArticles && (
        <Content
          size={isMobile && 'xs'}
          masonry={!isMobile}
          articles={dataArticles[active]?.values}
        />
      )}
    </div>
  );
};

export default ProfileContent;
