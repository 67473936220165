import React, { useRef, useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { createArticlesElement } from '../../helper/mappingArticles';
import CmsTypeDropdown from './CmsTypeDropdown';
import CmsAddDropdown from './CmsAddDropdown';
import Button from '../../common/Button/Button';
import Textarea from '../../common/Textarea/Textarea';
import { useMutation, useReactiveVar } from '@apollo/client';
import { createArticleMutationGql } from '../../gql/mutation';
import { organizationsFragmentGql } from '../../gql/fragment';
import { setIsMobile, setIsUserId } from '../../gql/cache';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { contentEditable, listenerKeyDown, listenerFocus } from './helper';
import slugify from 'slugify';

const Cms = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const pageRef = useRef(null);
  const [headline, setHeadline] = useState('');
  const [page, setPage] = useState([]);
  const [typeUpload, setTypeUpload] = useState('');
  const { push } = useHistory();
  const [saveArticle, { client }] = useMutation(createArticleMutationGql, {
    onCompleted: ({
      createArticle: {
        article: { slug: id },
      },
    }) => {
      push(`/preparing-material/${id}`);
    },
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.onclick = contentEditable;
      pageRef.current.addEventListener('keydown', listenerKeyDown(setPage, page), false);
      pageRef.current.addEventListener('focusout', listenerFocus(setPage), false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.length]);

  useEffect(() => {
    if (pageRef.current) {
      _.forEach(pageRef.current.children, (item) => {
        item.contentEditable = false;
      });
    }
  }, [page.length]);

  const onClickSave = useCallback(async () => {
    const {
      organizations: [{ id }],
    } = client.readFragment({
      id: `UsersPermissionsUser:${setIsUserId()}`,
      fragment: organizationsFragmentGql,
    });

    const slug = slugify(`${headline} author ${setIsUserId()}`, { lower: true, trim: true });

    saveArticle({
      variables: {
        input: {
          data: {
            contents: page,
            organization: id,
            draft: true,
            author: setIsUserId(),
            slug,
            headline,
          },
        },
      },
    });
  }, [client, headline, page, saveArticle]);

  const Pages = useCallback(() => {
    return page.map(({ type, value, ...props }, index) => {
      return createArticlesElement({ type, value, props }, index);
    });
  }, [page]);

  const pageCmsClassName = cx('page__cms', { mobile: isMobile });

  return (
    <div className={pageCmsClassName}>
      <div className="add-material-container">
        <div className="material-cms">
          <div className="article-modal__header">
            <Button iconLeft="arrow-left" text="Previous page" type="text" size="md" />
          </div>

          <div>
            <Textarea
              className="material-preparing-card__name"
              placeholder="Headline"
              value={headline}
              onChange={setHeadline}
            />
            <div className="material-cms__inputs" ref={pageRef}>
              <Pages />
            </div>
            <div className={cx('add-block-to-material type-to-filter', { open: isOpen })}>
              <div className="add-block-to-material-outside">
                {/*<div className="main-loader">*/}
                {/*  <div className="inside-loader" />*/}
                {/*</div>*/}
                {/*<img src="/images/loader.svg" />*/}
                <CmsTypeDropdown
                  setPage={setPage}
                  handleDropdown={handleDropdown}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setTypeUpload={setTypeUpload}
                />
              </div>
              {isOpen ? <span>Type to filter</span> : <span>Press “+” to add a block</span>}
            </div>
          </div>

          {/* <div className="add-block-to-material-modal">
            <CmsAddDropdown label="Add a video" btnEmbed="Embed video" />
          </div> */}

          <div className="add-block-to-material-modal">
            {typeUpload === 'image' && (
              <CmsAddDropdown
                type={typeUpload}
                label="Add an image"
                btnEmbed="Embed image"
                setTypeUpload={setTypeUpload}
                setPage={setPage}
              />
            )}

            {typeUpload === 'video' && (
              <CmsAddDropdown
                type={typeUpload}
                label="Add an video"
                btnEmbed="Embed video"
                setTypeUpload={setTypeUpload}
                setPage={setPage}
              />
            )}
          </div>
        </div>
        <div className="material-preparing-footer">
          <div className="material-preparing-footer__inner">
            <Button text="Publish" type="primary" size="md" onClick={onClickSave} />
            <Button text="Save to drafts" type="secondary" size="md" onClick={onClickSave} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cms;
