import React from 'react';
import Button from '../../common/Button/Button';
import { useMutation, useReactiveVar } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../gql/cache';
import { updateUserMutationGql } from '../../gql/mutation';
import { useHistory } from 'react-router';

const descriptionsList = [
  {
    title: 'Learn something new using the knowledge base',
    text: 'Discover the information about your company, take courses to obtain new skills',
    icon: 'engaging',
  },
  {
    title: 'Discover feed',
    text: 'Stay updated to news and materials your collegues and partners share around',
    icon: 'knowledge',
  },
  {
    title: 'Create and share content',
    text: 'Create and share courses, articles, quizes, and other materials ',
    icon: 'create',
  },
];

const UserOnboardingSecondStep = ({ handleStep }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const { push } = useHistory();
  const [updateUser] = useMutation(updateUserMutationGql, {
    variables: {
      input: {
        where: {
          id: setIsUserId(),
        },
        data: {
          onboardingPassed: true,
        },
      },
    },
    onCompleted() {
      push('/dashboard');
    },
  });

  return (
    <>
      <div className="onboarding__title">Let us know your interests</div>

      <div className="onboarding-descriptions">
        <div className="onboarding-desctiptions__list">
          {descriptionsList.map(({ title, text, icon }) => (
            <div key={icon} className="onboarding-descriptions-item">
              <img
                className="onboarding-descriptions-item__icon"
                src={`icons/${icon}.svg`}
                alt={icon}
              />

              <div className="onboarding-descriptions-item__inner">
                <div className="onboarding-descriptions-item__title">{title}</div>
                <div className="onboarding-descriptions-item__text">{text}</div>
              </div>
            </div>
          ))}
        </div>

        <div className="onboarding-footer">
          <div className="onboarding-footer__buttons">
            <Button
              onClick={() => handleStep((p) => p - 1)}
              text="Back"
              type="outline-purple"
              size={isMobile ? 'md' : 'lg'}
            />

            <Button
              onClick={updateUser}
              text="Finish onboarding"
              type="primary"
              size={isMobile ? 'md' : 'lg'}
              block={isMobile}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserOnboardingSecondStep;
