import React from 'react';
import { Input } from 'antd';
import Svg from '../../common/SVG';

const handleInput = (e) => {
  console.log(e.target.value);
};

const DropdownSearch = ({ id }) => {
  return (
    <div className="dropdown-search">
      <Input
        onChange={(e) => handleInput(e)}
        className="dropdown-search__input"
        placeholder="Search for a topic"
        id={id}
      />

      <label htmlFor={id} className="dropdown-search__label">
        <Svg name="search" />
      </label>
    </div>
  );
};

export default DropdownSearch;
