import React, { useCallback, useState } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { updateUserMutationGql } from '../../gql/mutation';
import { interestsQueryGql } from '../../gql/query';
import { setIsMobile, setIsUserId } from '../../gql/cache';
import { interestsFragmentGql } from '../../gql/fragment';
import _ from 'lodash';
import Tab from '../../common/Tab/Tab';
import ShowMore from '../../common/ShowMore/ShowMore';
import { useFeedFilter } from './feedFilter';

const FeedSidebarTopics = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const { setTopics } = useFeedFilter();
  const [isTopicsShowMore, setTopicsShowMore] = useState(false);
  // TODO: Feed filtration
  // const [isFiltered, setFiltered] = useState(true);
  const countTopicsToShow = 5;
  const [updateUser] = useMutation(updateUserMutationGql);
  const { data, client } = useQuery(interestsQueryGql);
  const { interests } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: interestsFragmentGql,
  });

  const onClick = useCallback(
    (active, id) => {
      const input = {
        where: { id: setIsUserId() },
        data: {},
      };
      if (active) {
        input.data.interests = _.without(_.map(interests, 'id'), id);
      } else {
        input.data.interests = [..._.map(interests, 'id'), id];
      }
      updateUser({
        variables: {
          input,
        },
      });
    },
    [interests, updateUser]
  );

  if (!data) {
    return null;
  }
  const activeItems =
    isTopicsShowMore || isMobile ? data.interests : data.interests.slice(0, countTopicsToShow);

  return (
    <>
      {activeItems.map(({ id, interestTitle }) => {
        const active = _.some(interests, ['id', id]);
        return (
          <Tab
            key={id}
            typeRight="follow"
            onClick={() => setTopics({ id, name: interestTitle })}
            onClickFollow={() => onClick(active, id)}
            textLeft={interestTitle}
            iconLeft="topic-filled"
            btnFollowLabel
            active={active}
            color="transparentNone"
            // TODO: Feed filtration
            // className={isFiltered && 'white-purple active'}
          />
        );
      })}
      {!isMobile && data.interests.length >= 5 && (
        <ShowMore
          isShowMore={isTopicsShowMore}
          setShowMore={setTopicsShowMore}
          openLabel="Show less topics"
          closeLabel="Show more topics"
        />
      )}
    </>
  );
};
export default FeedSidebarTopics;
