import React, { useEffect, useMemo, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import TagButton from '../../common/Tag/TagButton';
import SwipeWrapper from '../../common/SwipeWrapper/SwipeWrapper';
import { interestsQueryGql } from '../../gql/query';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { setArticlesArguments } from '../../gql/cache';

const onClick = (fn, prev, item) => () => {
  if (_.includes(prev, item)) {
    return fn([..._.remove(prev, (i) => i !== item)]);
  }
  return fn([...prev, item]);
};

const SwipeTagsList = () => {
  const { data } = useQuery(interestsQueryGql);
  const [selected, setSelected] = useState([]);

  const tagsArray = useMemo(() => {
    if (data) {
      return data.interests;
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (data) {
      const Interests = _.map(
        _.filter(data.interests, (i) => _.includes(selected, i.interestTitle)),
        'id'
      );
      setArticlesArguments({
        Interests,
      });
      return () => setArticlesArguments({});
    }
  }, [data, selected]);

  return (
    <SwipeWrapper spaceBetween={16} className="swipe-tags">
      {tagsArray.map((value) => (
        <SwiperSlide key={value.id}>
          <TagButton
            text={value.interestTitle}
            icon="topic-filled"
            size="md"
            onClick={onClick(setSelected, selected, value.interestTitle)}
            active={_.includes(selected, value.interestTitle)}
          />
        </SwiperSlide>
      ))}
    </SwipeWrapper>
  );
};

export default SwipeTagsList;
