import React, { useState } from 'react';
import Tab from '../../common/Tab/Tab';
import OutsideClickWrapper from '../../common/OutsideClickWrapper/OutsideClickWrapper';
import Svg from '../../common/SVG';

const CmsTypeDropdown = ({ setPage, handleDropdown, isOpen, setIsOpen, setTypeUpload }) => {
  const cmsTypes = [
    {
      icon: 'headline',
      name: 'Headline',
      type: 'h2',
    },
    {
      icon: 'text',
      name: 'Text',
      type: 'p',
    },
    {
      icon: 'edit',
      name: 'Highlight',
      type: 'mark',
    },
    {
      icon: 'link',
      name: 'Link',
      type: 'link',
    },
    {
      icon: 'quote',
      name: 'Quote',
      type: 'q',
    },
    // {
    //   icon: 'draft',
    //   name: 'Image (img)',
    //   type: 'img',
    // },
    // {
    //   icon: 'draft',
    //   name: 'Video (iframe)',
    //   type: 'video',
    // },
  ];

  const onClick = (fn, item) => () => {
    handleDropdown();
    return fn((prev) => [...prev, { type: item, value: '' }]);
  };

  return (
    <OutsideClickWrapper close={setIsOpen}>
      <div className="dropdown-page">
        {isOpen ? (
          <div className="dropdown-btn-icon" onClick={handleDropdown}>
            <Svg name="cancel" />
          </div>
        ) : (
          <div className="dropdown-btn-icon" onClick={handleDropdown}>
            <Svg name="plus" />
          </div>
        )}

        {isOpen && (
          <div className="dropdown-content-list">
            <div className="dropdown-content-list__nav">
              {cmsTypes.map(({ name, icon, type }, index) => (
                <Tab
                  key={index}
                  textLeft={name}
                  iconLeft={icon}
                  block
                  color="whitePurple"
                  onClick={onClick(setPage, type)}
                />
              ))}
              <Tab
                textLeft="Image"
                iconLeft="image"
                block
                color="whitePurple"
                onClick={() => {
                  handleDropdown();
                  setTypeUpload('image');
                }}
              />
              <Tab
                textLeft="Iframe"
                iconLeft="draft"
                block
                color="whitePurple"
                onClick={() => {
                  handleDropdown();
                  setTypeUpload('video');
                }}
              />
            </div>
          </div>
        )}
      </div>
    </OutsideClickWrapper>
  );
};

export default CmsTypeDropdown;
