import React, { useMemo, useState } from 'react';
import PageTemplate from '../../common/PageTemplate/PageTemplate';
import { nav, useTypes } from '../../common/PageTemplate/articleTypesFilter';
import { useProgress } from '../../gql/gqlHooks';
import FeedFollowingTab from './FeedFollowingTab';
import FeedEverythingTab from './FeedEverythingTab';
import { useQuery } from '@apollo/client';
import { articlesQueryShortFormGql } from '../../gql/query';
import { setIsUserId } from '../../gql/cache';
import { userFollowFragmentGql } from '../../gql/fragment';
import _ from 'lodash';
import FeedFilters from './FeedFilters';
import { useFeedFilter } from './feedFilter';

const Feed = () => {
  const { feedPassed } = useProgress();
  const { authorList, interestsList } = useFeedFilter();
  const [typesSort, setTypesSort] = useState('Newest');
  const { types, selectedIds, selected, onSelect } = useTypes();
  const [activeTab, setActiveTab] = useState(0);
  const sort = useMemo(() => {
    return typesSort === 'Newest' ? 'updated_at:DESC' : 'updated_at:ASC';
  }, [typesSort]);

  const { data: feedEverythingData, client } = useQuery(articlesQueryShortFormGql, {
    variables: {
      where: {
        draft: false,
        _or: [{ interests: interestsList }, { author: authorList }],
      },
    },
  });

  const author = useMemo(() => {
    const dataFollow = client.readFragment({
      id: `UsersPermissionsUser:${setIsUserId()}`,
      fragment: userFollowFragmentGql,
    });

    return _.map(dataFollow.followings, 'id');
    // const articleIds = _.map(feedFollowingData?.articles, 'article.id');
  }, [client]);

  const { data: feedFollowingData } = useQuery(articlesQueryShortFormGql, {
    skip: !author,
    variables: {
      sort,
      where: {
        draft: false,
        _or: [{ articleType: selectedIds }, { author }],
      },
    },
  });

  const feedTabsArr = [
    {
      label: 'Everything',
      content: <FeedEverythingTab isFeedPage data={feedEverythingData} />,
      badgeAmount: feedEverythingData?.articles.length,
    },
    {
      label: 'Following',
      content: <FeedFollowingTab isFeedPage data={feedFollowingData} />,
      badgeAmount: feedFollowingData?.articles.length,
    },
  ];

  return (
    <PageTemplate
      title="Feed"
      pageFilter
      dropdownPosition="right"
      dropdownLabelArr={nav}
      types={types}
      selectedTypes={selected}
      onSelectTypes={onSelect}
      selectedLabel={[typesSort]}
      onSelectLabel={setTypesSort}
      isFeed="page__right-sidebar page__feed"
      tabList
      tabsArr={feedTabsArr}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      welcomeBlock={!Boolean(feedPassed)}
      welcomeBlockType="feedPassed"
      welcomeBlockTitle="Welcome to Feed"
      welcomeBlockDescription="Discover publications your teammates and friends share"
    >
      <FeedFilters />
      {feedTabsArr[activeTab].content}
    </PageTemplate>
  );
};

export default Feed;
