import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InputText from '../../common/Inputs/InputText';
import Select from '../../common/Select/Select';
import Button from '../../common/Button/Button';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../gql/cache';
import { departmentsQueryGql, userQueryGql } from '../../gql/query';
import _ from 'lodash';
import { updateUserMutationGql } from '../../gql/mutation';

const onChange = (fn, key) => (event) => {
  if (key === 'departments') {
    return fn((prev) => ({
      ...prev,
      [key]: event,
    }));
  }
  return fn((prev) => ({
    ...prev,
    [key]: event.target.value,
  }));
};

const UserOnboardingFirstStep = ({ handleStep }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const [userFormData, setUserFormData] = useState({
    username: '',
    jobTitle: '',
    location: '',
    departments: [],
  });
  const { data: dataDepartments } = useQuery(departmentsQueryGql);
  const { data: dataUser } = useQuery(userQueryGql, {
    variables: {
      id: setIsUserId(),
    },
  });
  const [updateUser] = useMutation(updateUserMutationGql, {
    onCompleted() {
      handleStep((p) => p + 1);
    },
  });

  const options = useMemo(() => {
    if (dataDepartments) {
      return _.map(dataDepartments.departments, 'name');
    }
    return [];
  }, [dataDepartments]);

  useEffect(() => {
    if (dataUser) {
      const data = _.mapValues(_.pick(dataUser.user, _.keys(userFormData)), (v) => {
        if (_.isNull(v)) {
          return '';
        }
        if (_.isArray(v)) {
          return _.map(v, 'name');
        }
        return v;
      });
      setUserFormData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser]);

  const number = useMemo(() => {
    return _.reduce(
      userFormData,
      (r, v) => {
        if (v) {
          if (_.isArray(v) && !v.length) {
            return r;
          }
          return r + 1;
        }
        return r;
      },
      0
    );
  }, [userFormData]);

  const nextStep = useCallback(() => {
    updateUser({
      variables: {
        input: {
          where: {
            id: setIsUserId(),
          },
          data: {
            ...userFormData,
            departments: _.map(
              _.filter(dataDepartments.departments, (i) =>
                _.includes(userFormData.departments, i.name)
              ),
              'id'
            ),
          },
        },
      },
    });
  }, [updateUser, userFormData, dataDepartments]);

  return (
    <>
      <div className="onboarding__title">Welcome! First things first...</div>
      <div className="onboarding__subtitle">Tell a bit about yourself</div>

      <div className="onboarding-form">
        <InputText
          value={userFormData.username}
          labelText="Full name"
          placeholder="Enter your full name"
          onChange={onChange(setUserFormData, 'username')}
        />

        <InputText
          value={userFormData.jobTitle}
          labelText="Job title"
          placeholder="Enter your job title"
          onChange={onChange(setUserFormData, 'jobTitle')}
        />

        <Select
          options={options}
          labelText="Team"
          value={userFormData.departments}
          onChange={onChange(setUserFormData, 'departments')}
          placeholder="Select your department"
        />

        <InputText
          value={userFormData.location}
          labelText="Location"
          placeholder="e.g. Tallin, Estonia"
          onChange={onChange(setUserFormData, 'location')}
        />

        <div className="onboarding-footer">
          <Button
            onClick={nextStep}
            text="Next step"
            type="primary"
            size={isMobile ? 'md' : 'lg'}
            block={isMobile}
            disabled={number !== 4}
          />
          <div className="onboarding-footer__filled">{number} from 4 fields filled</div>
        </div>
      </div>
    </>
  );
};

export default UserOnboardingFirstStep;
