import React from 'react';
import Button from '../../common/Button/Button';

const CourseModal = ({ children, nextClick, prevClick, text }) => {
  return (
    <div className="article-modal">
      <div className="article-modal__container">
        <div className="article-modal__header">
          <Button iconLeft="arrow-left" text="Previous page" type="text" size="md" />
        </div>
        <div className="article-modal__content">
          <div className="article-modal__body">
            {children}
            <div className="article-modal__footer">
              <div className="course-buttons">
                <Button
                  type="outline-purple"
                  size="md"
                  text="Back"
                  iconLeft="arrow-left"
                  onClick={prevClick}
                />
                <Button
                  type="primary"
                  size="md"
                  text={text}
                  iconRight="arrow-right"
                  onClick={nextClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseModal;
