import React from 'react';
import Tab from '../Tab/Tab';
import { useQuery } from '@apollo/client';
import { userQueryGql } from '../../gql/query';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { setIsUserId } from '../../gql/cache';

const PeopleFollowItem = ({ id, username, avatar, isActive, onClick }) => {
  const { push } = useHistory();

  const { data: dataUser } = useQuery(userQueryGql, {
    variables: { id: id ? id : setIsUserId() },
  });

  const [firstName, lastName] = _.map(_.words(username), _.capitalize);
  return (
    <Tab
      key={id}
      typeRight="follow"
      avatar
      avatarImg={avatar}
      // onClickLeft={() => push(`/profile/${dataUser.user.slug ? dataUser.user.slug : id}`)}
      onClickLeft={() => push(`/profile/${id}`)}
      onClickFollow={() => onClick(id)}
      textLeft={firstName + ' ' + lastName}
      btnFollowLabel
      active={isActive(id)}
      userId={id}
      color="transparentNone"
    />
  );
};

export default PeopleFollowItem;
