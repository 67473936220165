import React, { useState, useMemo } from 'react';
import ArticlesItem from '../../../../components/Articles/ArticlesItem';
import { useMediaQuery } from '../../ResizeArticleInPage';
import PageTemplate from '../../../../common/PageTemplate/PageTemplate';
import { nav, useTypes } from '../../../../common/PageTemplate/articleTypesFilter';
import { useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../../../gql/cache';
import { coursesQueryGql } from '../../../../gql/query';
import client from '../../../../gql/client';
import { organizationsFragmentGql } from '../../../../gql/fragment';

const YourLearningPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [typesSort, setTypesSort] = useState('Newest');

  const {
    learningPassed,
    organizations: [{ id }],
  } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: organizationsFragmentGql,
  });

  const isMobile = useReactiveVar(setIsMobile);
  let articleSize = useMediaQuery('(min-width: 1920px)');

  const variables = useMemo(() => {
    return {
      sort: typesSort === 'Newest' ? 'updated_at:DESC' : 'updated_at:ASC',
      where: {
        usersPassed: { id: setIsUserId() },
      },
    };
  }, []);

  const inProgressCourse = useMemo(() => {
    return {
      sort: typesSort === 'Newest' ? 'updated_at:DESC' : 'updated_at:ASC',
      where: {
        organization: { id },
        _or: [{ usersPassed_null: true }, { usersPassed: { id_nin: setIsUserId() } }],
      },
    };
  }, []);

  const { data: completedCourseData } = useQuery(coursesQueryGql, {
    variables,
  });

  const { data: inProgressCourseData } = useQuery(coursesQueryGql, {
    inProgressCourse,
  });

  const learningTabsArr = [
    {
      label: 'In progress',
      content: inProgressCourseData?.courses.map((item) => {
        return (
          <ArticlesItem
            key={item.id}
            {...item}
            learning
            size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
            direction="col"
          />
        );
      }),
      badgeAmount: inProgressCourseData?.courses.length,
    },
    {
      label: 'Completed',
      content: completedCourseData?.courses.map((item) => {
        return (
          <ArticlesItem
            key={item.id}
            {...item}
            related
            size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
            direction="col"
          />
        );
      }),
      badgeAmount: completedCourseData?.courses.length,
    },
  ];

  const { content } = learningTabsArr[activeTab];
  const { types } = useTypes();

  return (
    <PageTemplate
      title="Your learning"
      pageFilter
      dropdownPosition="right"
      dropdownLabelArr={nav}
      dropdownCheckboxArr={types}
      tabList
      selected={[typesSort]}
      tabsArr={learningTabsArr}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      onSelect={setTypesSort}
      welcomeBlock={!learningPassed}
      welcomeBlockType="learningPassed"
      welcomeBlockTitle="Welcome to Learnings"
      welcomeBlockDescription="Discover publications your teammates and friends share"
    >
      <div className="cards-grid">{content}</div>
    </PageTemplate>
  );
};

export default YourLearningPage;
