import React from 'react';
import { SwiperSlide } from 'swiper/react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import { useMediaQuery } from '../../pages/Dashboard/ResizeArticleInPage';
import { articlesQueryShortFormGql } from '../../gql/query';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import SwipeWrapper from '../SwipeWrapper/SwipeWrapper';
import ArticlesItem from '../../components/Articles/ArticlesItem';

const SeeAllArticles = ({ title, linkTo }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const articleSize = useMediaQuery('(min-width: 1920px)');
  const { data } = useQuery(articlesQueryShortFormGql, {
    variables: {
      sort: 'updated_at:DESC',
      limit: 4,
      where: {
        draft: false,
      },
    },
  });

  if (!data) return null;

  return (
    <div className="see-all-articles">
      <div className="see-all-articles-header">
        <span className="m-5">{title}</span>
        <Link to={linkTo}>
          <Button type="text" size="md" text="See all" />
        </Link>
      </div>
      <div className="see-all-articles-content">
        {isMobile ? (
          <SwipeWrapper spaceBetween={16} navigation={false}>
            {data?.articles.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <ArticlesItem
                    {...item}
                    related
                    size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
                    direction="col"
                  />
                </SwiperSlide>
              );
            })}
          </SwipeWrapper>
        ) : (
          data?.articles.map((item) => {
            return (
              <ArticlesItem
                key={item.id}
                {...item}
                related
                size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
                direction="col"
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default SeeAllArticles;
