import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { articlesQueryShortFormGql, coursesQueryGql } from '../../../gql/query';
import ArticlesItem from '../ArticlesItem';
import SwipeWrapper from '../../../common/SwipeWrapper/SwipeWrapper';
import { setIsMobile } from '../../../gql/cache';
import { SwiperSlide } from 'swiper/react';
import { useParams } from 'react-router-dom';

const RelatedArticles = ({ course }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const { id } = useParams();

  const { data } = useQuery(course ? coursesQueryGql : articlesQueryShortFormGql, {
    variables: {
      limit: 2,
      where: {
        draft: false,
        id_ne: id,
      },
    },
  });

  const type = course ? 'courses' : 'articles';

  return (
    <div className="related-articles-container">
      <h3>Related publications</h3>
      <div className="related-articles">
        {isMobile ? (
          <SwipeWrapper spaceBetween={16} navigation={false}>
            {data &&
              data[type].map((item) => (
                <SwiperSlide key={item.id}>
                  <ArticlesItem learning={course} related key={item.id} {...item} size="xs" />
                </SwiperSlide>
              ))}
          </SwipeWrapper>
        ) : (
          data &&
          data[type].map((item) => (
            <ArticlesItem learning={course} related key={item.id} {...item} size="lg" />
          ))
        )}
      </div>
    </div>
  );
};
export default RelatedArticles;
