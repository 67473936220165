import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../../gql/cache';
import TagButton from '../../../common/Tag/TagButton';
import ModalWrapper from '../../../components/Modals/ModalWrapper';
import Tab from '../../../common/Tab/Tab';
import SwipeWrapper from '../../../common/SwipeWrapper/SwipeWrapper';
import { SwiperSlide } from 'swiper/react';
import { interestsQueryGql } from '../../../gql/query';
import { interestsFragmentGql } from '../../../gql/fragment';
import { updateUserMutationGql } from '../../../gql/mutation';

const ProfileSidebarTags = ({ tagsArray, userName }) => {
  const [updateUser] = useMutation(updateUserMutationGql);
  const isMobile = useReactiveVar(setIsMobile);
  const [isModalVisible, setModalVisible] = useState(false);

  const { data, client } = useQuery(interestsQueryGql);
  const { interests } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: interestsFragmentGql,
  });

  const handleClick = () => {
    setModalVisible(!isModalVisible);
  };

  const firstTagsArray = useMemo(() => {
    return _.take(tagsArray, 5);
  }, [tagsArray]);

  const remainsTags = useMemo(() => {
    return tagsArray.length - firstTagsArray.length;
  }, [firstTagsArray.length, tagsArray.length]);

  const onClick = useCallback(
    (active, id) => {
      const input = {
        where: { id: setIsUserId() },
        data: {},
      };
      if (active) {
        input.data.interests = _.without(_.map(interests, 'id'), id);
      } else {
        input.data.interests = [..._.map(interests, 'id'), id];
      }
      updateUser({
        variables: {
          input,
        },
      });
    },
    [interests, updateUser]
  );

  if (!data) {
    return null;
  }

  return (
    <div className="profile-sidebar-tags">
      {isMobile ? (
        <SwipeWrapper spaceBetween={8} className="swipe-tags" navigation={false}>
          {firstTagsArray.map(({ interestTitle }) => (
            <SwiperSlide key={interestTitle}>
              <TagButton text={interestTitle} icon="topic-filled" size="xs" active={false} />
            </SwiperSlide>
          ))}
        </SwipeWrapper>
      ) : (
        <>
          {firstTagsArray.map(({ interestTitle }) => (
            <TagButton key={interestTitle} text={interestTitle} icon="topic" size="xs" />
          ))}
          <button onClick={handleClick} type="button" className="profile-sidebar-tags__amount">
            {`${remainsTags ? remainsTags : ''} more`}
          </button>
        </>
      )}

      <ModalWrapper
        isModalVisible={isModalVisible}
        handleClick={handleClick}
        className="modal__profile-sidebar"
      >
        <div className="modal__profile-container interests-modal">
          <div className="modal__profile-header">
            <span title={`${userName}’s`}>{`${userName}’s`}</span>
            <span>interests</span>
          </div>
          <div className="modal__profile-content">
            {data.interests.map(({ id, interestTitle }) => {
              const active = _.some(interests, ['id', id]);
              return (
                <Tab
                  key={id}
                  typeRight="follow"
                  onClick={() => onClick(active, id)}
                  textLeft={interestTitle}
                  iconLeft="topic-filled"
                  btnFollowLabel
                  active={active}
                  color="transparentNone"
                />
              );
            })}
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default ProfileSidebarTags;
