import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import TagLabel from '../../common/Tag/TagLabel';
import Bookmark from '../../common/Bookmark/Bookmark';
import ArticleItemFooter from './ArticleItemFooter';
import ArticleItemHeader from './ArticleItemHeader';
import ArticleOptions from './ArticleOptions';
import ArticleItemHeaderAuthorized from './ArticleItemHeaderAuthorized';
import { useHistory } from 'react-router';
import { setIsAuthorized, setIsUserId, setOpenModal } from '../../gql/cache';
import { useReactiveVar } from '@apollo/client';
import { getImageURL } from '../../helper';
import _ from 'lodash';

const ArticlesItem = ({
  id,
  slug,
  author: { username, avatar, id: authorId },
  articleType,
  headline,
  subtitle,
  shortLead,
  coverImage,
  likes = [],
  comments = [],
  interests = [],
  classic,
  related,
  learning,
  draft,
  size,
  direction = 'col',
  personal,
  handleClick,
  lessons = [],
  updated_at,
  viewList,
}) => {
  const { push } = useHistory();
  const authorized = useReactiveVar(setIsAuthorized);
  const openModal = useReactiveVar(setOpenModal);

  const classicClass = { classic: classic, [`${size}`]: size };
  const learningClass = { [`article-item-learning ${size}`]: learning };
  const relatedClass = { [`article-item-related ${size}`]: related };
  const draftClass = { [`article-item-draft ${size}`]: draft };
  const viewListClass = { [`article-item-view-list`]: viewList };

  const articleClassName = cx(
    'article-item',
    direction,
    classicClass,
    relatedClass,
    learningClass,
    draftClass,
    viewListClass
  );

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (openModal) {
        return;
      }
      if (!authorized) {
        return null;
      }
      if (authorized) {
        if (draft) {
          return push({ pathname: `/preparing-material/${slug ? slug : id}`, id: id });
        }
        if (learning) {
          return push({ pathname: `/dashboard/your-learnings/${slug ? slug : id}`, id: id });
        }
        return push({ pathname: `/article/${slug ? slug : id}`, id: id });
      }
      handleClick(id);
    },
    [authorized, draft, handleClick, id, learning, openModal, push, slug]
  );

  const progress = useMemo(() => {
    if (learning) {
      const value = _.filter(lessons, ({ usersPassed }) => {
        return _.includes(_.map(usersPassed, 'id'), setIsUserId());
      }).length;

      const newValue = (value / lessons.length) * 100;

      if (isNaN(newValue)) return 0;

      return newValue;
    }
    return 0;
  }, [lessons, learning]);

  return (
    <article className={articleClassName}>
      {classic &&
        (authorized ? (
          <ArticleItemHeaderAuthorized
            personal={personal}
            author={username}
            authorLogo={avatar}
            authorId={authorId}
          />
        ) : (
          <ArticleItemHeader author={username} authorLogo={avatar} authorId={authorId} />
        ))}
      <div className="article-item-content">
        <div
          className={cx('article-item-content__image', { 'article-cover-image': !coverImage })}
          onClick={onClick}
        >
          {coverImage && coverImage.url && <img src={getImageURL(coverImage.url)} alt="" />}
          <div className="article-item-content-absolute">
            {/*{!draft && (*/}
            {/*  <div className="article-item-content__image-title" title={headline}>*/}
            {/*    <span>{headline}</span>*/}
            {/*  </div>*/}
            {/*)}*/}

            {draft ? (
              <div className="article-item-content__bookmark">
                <ArticleOptions />
              </div>
            ) : (
              <div className="article-item-content__bookmark">
                {authorized && <Bookmark articleId={id} />}
              </div>
            )}

            {articleType && (
              <div className="article-item-content__type">
                <TagLabel text={articleType.type} icon="image" />
              </div>
            )}
          </div>
        </div>
        <div className="article-item-content__body">
          <h3 className="article-item-content__body-title" onClick={onClick}>
            {headline}
          </h3>
          {(classic || learning || viewList) && (
            <div className="article-item-content__body-description" onClick={onClick}>
              {shortLead}
            </div>
          )}
          {classic && interests && interests.length > 0 && (
            <div className="article-item-topics">
              {interests.map((value, index) => (
                <div className="article-item-topics__topic" key={index}>
                  <TagLabel text={value.interestTitle} className="article-topics" />
                </div>
              ))}
            </div>
          )}
          <ArticleItemFooter
            id={id}
            draft={draft}
            related={related}
            learning={learning}
            author={username}
            authorLogo={avatar}
            likes={likes}
            comments={comments}
            authorId={authorId}
            progress={progress}
            updatedAt={updated_at}
            slug={slug}
          />
        </div>
      </div>
    </article>
  );
};

export default ArticlesItem;
