import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import client from '../gql/client';
import { session } from './helper';
import { setIsUserId, setIsAuthorized } from '../gql/cache';
import { meQueryGql, userQueryGql } from '../gql/query';

const options = {
  locale: 'en',
  extensions: [new OAuthExtension()],
};

const Auth = new Magic(process.env.REACT_APP_MAGIC_LINK_KEY, options);

export const loginWithGoogle = async () => {
  await Auth.oauth.loginWithRedirect({
    provider: 'google',
    redirectURI: `${window.location.origin}/oauth`,
  });
};

export const checkAuthGoogle = async (push, setPercent) => {
  try {
    setPercent(10);
    await Auth.oauth.getRedirectResult();
    const token = await Auth.user.getIdToken();
    session.set('token', token);
    const {
      data: { me },
    } = await client.query({ query: meQueryGql });
    setPercent(45);
    const {
      data: {
        user: { onboardingPassed },
      },
    } = await client.query({ query: userQueryGql, variables: { id: me.id } });
    setPercent(75);
    setIsUserId(me.id);
    setIsAuthorized(!!token);
    setPercent(100);
    if (onboardingPassed) {
      push('/dashboard');
    } else {
      push('/onboarding');
    }
  } catch (e) {
    console.error(e);
  }
};
