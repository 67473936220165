import React, { useCallback, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import { useMutation } from '@apollo/client';
import { createCommentMutationGql } from '../../gql/mutation';
import { userDataFragmentGql } from '../../gql/fragment';
import { setIsUserId } from '../../gql/cache';
import { articleQueryGql } from '../../gql/query';

const CommentAdd = ({ articleId, slug }) => {
  const [comment, setAddComment] = useState('');
  const [addComment, { client }] = useMutation(createCommentMutationGql, {
    onCompleted: () => {
      setAddComment('');
    },
    refetchQueries: [
      {
        query: articleQueryGql,
        variables: {
          id: slug,
        },
      },
    ],
  });

  const {
    id: user,
    username,
    avatar,
  } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: userDataFragmentGql,
  });

  const onClick = useCallback(() => {
    if (!comment) {
      return;
    }
    addComment({
      variables: {
        input: {
          data: {
            comment,
            user,
            article: articleId,
          },
        },
      },
    });
  }, [addComment, articleId, comment, user]);

  return (
    <div className="modal-add-comment__header">
      <div className="modal-add-comment__input">
        <Avatar size="xlg" avatarImg={avatar} author={username} />
        <TextArea
          className="input-comment"
          placeholder="Add a comment"
          autoSize={{ minRows: 1, maxRows: 5 }}
          value={comment}
          onChange={({ target: { value } }) => setAddComment(value)}
        />
      </div>
      <div className="modal__buttons">
        <Button text="Cancel" size="md" type="outline-purple" onClick={() => setAddComment('')} />
        <Button text="Publish" size="md" type="primary" onClick={onClick} />
      </div>
    </div>
  );
};

export default CommentAdd;
