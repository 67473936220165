import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import Avatar from '../../../common/Avatar/Avatar';
import { useReactiveVar, useMutation } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../../gql/cache';
import { uploadMutationGql, updateUserMutationGql } from '../../../gql/mutation';
import Svg from '../../../common/SVG';
import { Upload, message } from 'antd';

const ProfileSidebarHeader = ({ name, position, dataUser, changeAvatar = true }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const [fileList, setFileList] = useState([]);
  const [updateUser] = useMutation(updateUserMutationGql, {
    onCompleted: () => {
      setFileList([]);
    },
  });
  const [uploadFile] = useMutation(uploadMutationGql, {
    onCompleted: ({ upload: { id } }) => {
      return updateUser({
        variables: {
          input: {
            where: {
              id: setIsUserId(),
            },
            data: {
              avatar: id,
            },
          },
        },
      });
    },
  });
  const onChange = useCallback(({ fileList: newFileList }) => {
    setFileList(newFileList);
  }, []);
  const [firstName, lastName] = _.map(_.words(name), _.capitalize);

  const beforeUpload = useCallback((file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }, []);

  if (!dataUser) {
    return null;
  }

  return (
    <div className="profile-sidebar__header">
      <div className="avatar-edit">
        <Avatar
          size={isMobile ? 'xx' : 'xxx'}
          author={dataUser.username}
          avatarImg={dataUser.avatar}
        />
        {changeAvatar && (
          <Upload
            beforeUpload={beforeUpload}
            showUploadList={false}
            action={async (file) =>
              await uploadFile({
                variables: {
                  file,
                },
              })
            }
            fileList={fileList}
            multiple={false}
            onChange={onChange}
          >
            <button className="avatar-edit__btn">
              <Svg name="image" />
            </button>
          </Upload>
        )}
      </div>

      <div className="profile-sidebar__name">
        {firstName} {lastName}
      </div>
      <div className="profile-sidebar__position">{position}</div>
    </div>
  );
};

export default ProfileSidebarHeader;
