import { Magic } from 'magic-sdk';
import client from '../gql/client';
import { meQueryGql, userQueryGql } from '../gql/query';
import { setIsUserId, setIsAuthorized } from '../gql/cache';
import { session } from './helper';
const options = {
  locale: 'en',
  // testMode: process.env.NODE_ENV === 'development',
};

const Auth = new Magic(process.env.REACT_APP_MAGIC_LINK_KEY, options);

export const checkAuth = async (setLoading, push) => {
  try {
    const authOk = await Auth.user.isLoggedIn();
    if (authOk) {
      const token = await Auth.user.getIdToken();
      session.set('token', token);
      const {
        data: { me },
      } = await client.query({ query: meQueryGql });
      const {
        data: {
          user: { onboardingPassed },
        },
      } = await client.query({ query: userQueryGql, variables: { id: me.id } });
      setIsUserId(me.id);
      if (!onboardingPassed) {
        push('/onboarding');
      }
    } else {
      await Auth.user.logout();
      session.remove('token');
    }
    setIsAuthorized(authOk);
  } catch (a) {
    await Auth.user.logout();
    setIsAuthorized(false);
    session.remove('token');
    Auth.user.logout();
  }
  setLoading(false);
};

export const logout = (push) => {
  session.remove('token');
  setIsAuthorized(false);
  push('/');
};

export const login = async (email, push) => {
  try {
    const token = await Auth.auth.loginWithMagicLink({ email });
    session.set('token', token);
    const {
      data: { me },
    } = await client.query({ query: meQueryGql });
    const {
      data: {
        user: { onboardingPassed },
      },
    } = await client.query({ query: userQueryGql, variables: { id: me.id } });
    setIsUserId(me.id);
    setIsAuthorized(!!token);
    if (onboardingPassed) {
      push('/dashboard');
    } else {
      push('/onboarding');
    }
  } catch (e) {}
};
