import React from 'react';
import cx from 'classnames';
import { useReactiveVar } from '@apollo/client';
import { setIsAuthorized, setIsMobile } from '../../gql/cache';
import Masonry from 'react-masonry-css';
import ResizeArticle from '../../components/Articles/ResizeArticle';
import ArticlesItem from '../../components/Articles/ArticlesItem';
import EmptyDashedArticleGrid from '../../components/Articles/EmptyDashedArticleGrid';

const FeedFollowingTab = ({ isFeedPage, data }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const authorized = useReactiveVar(setIsAuthorized);

  ResizeArticle(isFeedPage);

  const masonryClass = cx(
    'masonry-articles-list',
    { 'feed-page': isFeedPage },
    !isFeedPage && (authorized ? 'authorized' : 'unauthorized')
  );

  if (!data || !data?.articles.length) {
    return (
      <EmptyDashedArticleGrid
        title="Follow people and topics to start seeing publications they share"
        image="user.svg"
        masonry={!isMobile}
      />
    );
  }

  return (
    <Masonry breakpointCols={2} className={masonryClass} columnClassName="masonry-articles-col">
      {data?.articles.map((item) => (
        <ArticlesItem key={item.id} {...item} classic size={data?.articles.length === 1 && 'md'} />
      ))}
    </Masonry>
  );
};

export default FeedFollowingTab;
