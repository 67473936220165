import React, { useMemo, useState } from 'react';
import { Upload } from 'antd';
import OutsideClickWrapper from '../../common/OutsideClickWrapper/OutsideClickWrapper';
import TabLabel from '../../common/Tab/TabLabel';
import Button from '../../common/Button/Button';
import InputText from '../../common/Inputs/InputText';
import { useMutation } from '@apollo/client';
import { uploadMutationGql } from '../../gql/mutation';
import { getImageURL } from '../../helper';

const CmsTypeDropdown = ({ label, btnEmbed, setTypeUpload, setPage, type }) => {
  const [active, setActive] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [value, setValue] = useState('');
  const [uploadFile] = useMutation(uploadMutationGql, {
    onCompleted: ({ upload: { url } }) => {
      setPage((prev) => {
        return [...prev, { type: 'img', value: getImageURL(url) }];
      });
      setTypeUpload('');
    },
  });

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const profileTabsArr = useMemo(
    () => [
      {
        label: 'Upload File',
        content: (
          <div className="cms-add__modal-input">
            <Upload
              action={(file) =>
                uploadFile({
                  variables: {
                    file,
                  },
                })
              }
              fileList={fileList}
              multiple={false}
              onChange={onChange}
            >
              <div className="cms-add__modal-btn">
                <Button text={btnEmbed} type="primary" size="lg" />
              </div>
            </Upload>
          </div>
        ),
      },
      {
        label: 'Embed link',
        content: (
          <div className="cms-add__modal-input">
            <InputText
              onChange={({ target }) => setValue(target.value)}
              value={value}
              labelText="*Entity* link"
              placeholder="Add an *entity* link"
              id="demo-modal-email"
            />
            <div className="cms-add__modal-btn">
              <Button
                text={btnEmbed}
                type="primary"
                size="lg"
                onClick={() => {
                  setPage((prev) => {
                    return [...prev, { type, value }];
                  });
                  setTypeUpload('');
                }}
              />
            </div>
          </div>
        ),
      },
    ],
    [btnEmbed, fileList, setPage, setTypeUpload, type, uploadFile, value]
  );

  const Content = useMemo(() => {
    if (type === 'video') {
      return profileTabsArr[1].content;
    }
    return profileTabsArr[active].content;
  }, [active, profileTabsArr, type]);

  return (
    <OutsideClickWrapper close={() => setTypeUpload('')}>
      <div className="dropdown-page">
        <div className="add-to-cms__label" onClick={() => setTypeUpload('')}>
          {label}
        </div>
        <div className="dropdown-content-list cms-add__modal-dropdown">
          {type !== 'video' && (
            <div className="tab-label-list modal">
              {profileTabsArr.map(({ label }, key) => (
                <TabLabel
                  transparent
                  key={label}
                  label={label}
                  onClick={() => setActive(key)}
                  active={active === key}
                />
              ))}
            </div>
          )}
          {Content}
        </div>
      </div>
    </OutsideClickWrapper>
  );
};

export default CmsTypeDropdown;
