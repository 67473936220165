import React, { useCallback, useState, useMemo } from 'react';
import Svg from '../SVG';
import cx from 'classnames';
import { createLikeMutationGql, deleteLikeMutationGql } from '../../gql/mutation';
import { useMutation, useQuery } from '@apollo/client';
import { setIsUserId } from '../../gql/cache';
import { find } from 'lodash';
import { likesGql } from '../../gql/query';

export const ClapIcon = ({ active }) => {
  return active ? <Svg className="clap-filled" name="clap-filled" /> : <Svg name="clap" />;
};

const ClapCount = ({ count }) => {
  return <span className="clap-count">{count && <span>{count}</span>}</span>;
};

const CountTotal = ({ countTotal, count, short }) => {
  const countTotalClass = cx('clap-count-total', count && 'active');
  return (
    <span className={countTotalClass}>
      {countTotal} {!short && 'Claps'}
    </span>
  );
};

const ClapComments = ({ label, className, disabled, short, total, commentId }) => {
  const [isClicked, setIsClicked] = useState('');
  const { data } = useQuery(likesGql, {
    variables: {
      where: {
        comment: commentId,
      },
    },
  });

  const like = useMemo(
    () =>
      find(data?.likes, (l) => {
        if (l.user) {
          return l.user.id === setIsUserId();
        }
      }),
    [data?.likes]
  );

  const [createLike] = useMutation(createLikeMutationGql, {
    variables: {
      input: {
        data: {
          comment: commentId,
          user: setIsUserId(),
        },
      },
    },
    refetchQueries: [
      {
        query: likesGql,
        variables: {
          where: {
            comment: commentId,
          },
        },
      },
    ],
    onCompleted: () => setIsClicked(''),
  });

  const [deleteLike] = useMutation(deleteLikeMutationGql, {
    variables: {
      input: {
        where: { id: like ? like.id : '' },
      },
    },
    refetchQueries: [
      {
        query: likesGql,
        variables: {
          where: {
            comment: commentId,
          },
        },
      },
    ],
    onCompleted: () => setIsClicked(''),
  });

  const onClick = useCallback(
    (event) => {
      if (disabled) return;
      event.preventDefault();
      event.stopPropagation();
      if (like) {
        setIsClicked('-1');
        return deleteLike();
      }
      setIsClicked('+1');
      createLike();
    },
    [createLike, deleteLike, disabled, like]
  );

  const clapClass = cx('claps-container', className, isClicked && 'active', disabled && 'disabled');

  return (
    <>
      <div className={clapClass} onClick={onClick}>
        <ClapIcon active={!!like} />
        {label && <CountTotal countTotal={total} count={isClicked} short={short} />}
        <ClapCount count={isClicked} />
      </div>
    </>
  );
};

export default ClapComments;
