import React, { useCallback, useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Button from '../../common/Button/Button';
import Tab from '../../common/Tab/Tab';
import { Dropdown } from 'antd';
import UpcomingEventsPush from './UpcomingEventsPush';
import { excludeRoute } from '../../constants';
import ButtonIcon from '../../common/Button/ButtonIcon';
import Svg from '../../common/SVG';
import cx from 'classnames';
import DrawerModal from '../../common/Drawer/Drawer';
import SidebarAuthMobile from '../Sidebar/SidebarAuthMobile';
import { logout } from '../../helper';
import Avatar from '../../common/Avatar/Avatar';
import { useLazyQuery, useQuery } from '@apollo/client';
import { meQueryGql, userQueryGql } from '../../gql/query';
import SearchData from './SearchData';

const accountNav = [
  {
    icon: 'user',
    name: 'profile',
    to: '/profile',
  },
  {
    icon: 'draft',
    name: 'drafts',
    to: '/drafts',
  },
];

const notificationNewList = [
  {
    avatar: '/images/avatar-person-1.png',
    title: 'Dima mentioned you in the comments',
    text: '«AI-powered tools for Product Designers»',
  },
  {
    avatar: '/images/avatar-person-2.png',
    title: 'Dima mentioned you in the comments',
    text: '«AI-powered tools for Product Designers»',
  },
  {
    avatar: '/images/avatar-person-3.png',
    title: 'Dima mentioned you in the comments',
    text: '«AI-powered tools for Product Designers»',
  },
];

const notificationEarlierList = [
  {
    avatar: '/images/avatar-person-1.png',
    user: 'Vadim Abramov',
    title: 'followed you',
  },
  {
    avatar: '/images/avatar-person-2.png',
    user: 'Vadim Abramov',
    title: 'followed you',
  },
  {
    avatar: '/images/avatar-person-3.png',
    user: 'Vadim Abramov',
    title: 'followed you',
  },
];

const HeaderMobile = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isVisibleSearch, setVisibleSearch] = useState(false);
  // const [shadowElement, setShadowElement] = useState('');

  const { pathname } = useLocation();
  const { push } = useHistory();

  const handleSearchInput = () => {
    setVisibleSearch(!isVisibleSearch);
  };

  const handleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const onClick = useCallback(() => {
    logout(push);
  }, [push]);

  const [getUser, { data: dataUser }] = useLazyQuery(userQueryGql);

  useQuery(meQueryGql, {
    onCompleted({ me }) {
      getUser({ variables: { id: me.id } });
    },
  });

  const accountMenu = (
    <>
      <div className="dropdown-content-list__nav">
        {accountNav.map(({ name, icon, to }, index) => (
          <Link to={to} key={index}>
            <Tab key={name} textLeft={name} iconLeft={icon} block color="whitePurple" />
          </Link>
        ))}
      </div>

      <Tab textLeft="Log out" iconLeft="logout" block color="whitePurple" onClick={onClick} />
    </>
  );

  const notificationMenu = (
    <>
      <div className="header-notification-status new">
        <div className="header-notification-status__label">New</div>
        <div className="header-notification-status__line" />
      </div>

      <div className="header-notification__list new">
        {notificationNewList.map(({ avatar, title, text }, index) => (
          <div className="header-notification-item" key={index}>
            <div
              style={{ backgroundImage: `url(${avatar})` }}
              className="header-notification-item__avatar"
            />

            <div className="header-notification-item__inner">
              <div className="header-notification-item__title">{title}</div>

              <div className="header-notification-item__text">{text}</div>
            </div>

            <Button onClick={() => alert('Add new material')} text="See it" type="primary" />
          </div>
        ))}
      </div>

      <div className="header-notification-status earlier">
        <div className="header-notification-status__label">Earlier</div>
        <div className="header-notification-status__line" />
      </div>

      <div className="header-notification__list earlier">
        <UpcomingEventsPush />

        {notificationEarlierList.map(({ avatar, user, title }) => (
          <div className="header-notification-item" key={title}>
            <div
              style={{ backgroundImage: `url(${avatar})` }}
              className="header-notification-item__avatar"
            />

            <div className="header-notification-item__inner">
              <div className="header-notification-item__title">
                <Link to="#user">{user}</Link> {title}
              </div>
            </div>

            <Button onClick={() => alert('Add new material')} text="Follow back" type="primary" />
          </div>
        ))}
      </div>
    </>
  );

  if (excludeRoute.includes(pathname) || !dataUser) {
    return null;
  }

  return (
    <header className={cx('header mobile')}>
      <div className="header__inner">
        <button onClick={handleSidebar} type="button" className="header__burger">
          <Svg name="menu-collapsed" />
        </button>
        <Link className="header-logo" to="/">
          <img className="header-logo__img" src="/images/logo.png" alt="logo" />
        </Link>

        <div className="header__controls">
          <ButtonIcon
            icon="search"
            size="xs"
            active={isVisibleSearch}
            onClick={handleSearchInput}
          />

          <div className="header-notification">
            <Dropdown
              overlay={notificationMenu}
              trigger={['click']}
              placement="bottomRight"
              className="header-notification-dropdown-mobile"
              overlayClassName="header-notification-modal-mobile"
              disabled
            >
              <ButtonIcon disabled icon="bell" size="xs" />
            </Dropdown>
          </div>

          <div className="header-account">
            <Dropdown
              overlay={accountMenu}
              trigger={['click']}
              placement="bottomRight"
              className="header-account-dropdown"
              overlayClassName="header-account-modal-mobile"
            >
              <Link onClick={(e) => e.preventDefault()}>
                <Avatar
                  size="lg"
                  author={dataUser.user.username}
                  avatarImg={dataUser.user.avatar}
                />
                <Svg className="icon-arrow" name="arrow-down" />
              </Link>
            </Dropdown>
          </div>
        </div>
      </div>

      {isVisibleSearch && <SearchData />}

      {isSidebarVisible && (
        <DrawerModal
          isBurgerMenu
          width={240}
          placement="left"
          isDrawerVisible={isSidebarVisible}
          handleClick={handleSidebar}
          className={cx('modal-sidebar')}
        >
          <SidebarAuthMobile handleSidebar={handleSidebar} />
        </DrawerModal>
      )}
    </header>
  );
};

export default HeaderMobile;
