import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import ArticleModal from '../../components/Modals/ArticleModal';
import { articleQueryGql, courseQueryGql } from '../../gql/query';
import { createArticlesElement } from '../../helper';
import { setIsUserId } from '../../gql/cache';

const MaterialArticle = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const course = pathname.includes('your-learnings');
  const type = course ? 'course' : 'article';

  const { data } = useQuery(course ? courseQueryGql : articleQueryGql, {
    variables: {
      id,
    },
  });

  const lessons = useMemo(() => {
    return _.get(data, 'course.lessons', []);
  }, [data]);

  const activeClap = useMemo(() => {
    return _.some(_.get(data, 'article.likes', []), ['user.id', setIsUserId()]);
  }, [data]);

  if (!data || !data.article) {
    // return not found
    return null;
  }

  return (
    <div className="page__material">
      <div className="dashboard__content">
        <ArticleModal
          course={course}
          lessons={lessons}
          usersStarted={data[type].usersStarted}
          comments={data[type].comments}
          clapsNumber={data[type].likes.length}
          likes={data[type].likes}
          activeClap={activeClap}
          articleId={data[type].id}
          author={data[type].author}
          interests={data[type].interests}
          slug={data[type].slug}
        >
          <h1 className="l-1">{data[type].headline}</h1>
          {data[type].contents &&
            data[type].contents.map((item, index) => createArticlesElement(item, index))}
        </ArticleModal>
      </div>
    </div>
  );
};

export default MaterialArticle;
