import React, { useState } from 'react';
import Button from '../../common/Button/Button';
import DemoModal from '../Modals/DemoModal';
import ModalWrapper from '../Modals/ModalWrapper';
import { Link } from 'react-router-dom';
import LoginWithGoogle from '../../pages/Login/LoginWithGoogle';

const sidebarList = [
  {
    title: 'Knowledge base',
    text: 'Store all your company’s info — starting from headcount and ending with documentation, files, guides, articles, and many more',
    icon: 'knowledge',
  },
  {
    title: 'Engaging learning experience',
    text: 'Create courses, other learning materials, articles, news and share it directly to feed',
    icon: 'engaging',
  },
  {
    title: 'Dynamic recommendation system',
    text: 'With the integrated tag system you can show only related to users’ interests content',
    icon: 'dynamic',
  },
  {
    title: 'Slack integration',
    text: 'Send instant notifications and updates to your employees via Slack',
    icon: 'slack',
  },
];

const SidebarUnAuth = () => {
  const [isModalDemoVisible, setIsModalDemoVisible] = useState(false);

  const handleDemoClick = () => {
    setIsModalDemoVisible(!isModalDemoVisible);
  };

  return (
    <aside className="sidebar unauth">
      <div className="sidebar__box">
        <div className="sidebar__header">
          <Link className="sidebar-logo" to="/">
            <img className="sidebar-logo__img" src="/images/logo.png" alt="logo" />
          </Link>
        </div>

        <div className="sidebar__wrapper">
          <div className="sidebar__title">Home for your company’s content</div>

          <div className="sidebar__buttons">
            <Button
              onClick={() => alert('registration')}
              text="Register your company"
              type="primary"
              size="lg"
              block
              className="sidebar__signup"
            />

            <LoginWithGoogle />
          </div>
        </div>

        <div className="sidebar-list">
          {sidebarList.map(({ title, text, icon }) => (
            <div key={icon} className="sidebar-list-item">
              <img className="sidebar-list-item__icon" src={`icons/${icon}.svg`} alt={icon} />

              <div className="sidebar-list-item__inner">
                <div className="sidebar-list-item__title">{title}</div>
                <div className="sidebar-list-item__text">{text}</div>
              </div>
            </div>
          ))}
        </div>

        <ModalWrapper
          isModalVisible={isModalDemoVisible}
          handleClick={handleDemoClick}
          modalWidth="368px"
        >
          <DemoModal handleClick={handleDemoClick} />
        </ModalWrapper>
      </div>
    </aside>
  );
};

export default SidebarUnAuth;
