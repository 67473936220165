import React, { useEffect, useMemo, useState } from 'react';
import WelcomeBlock from '../../common/WelcomeBlock/WelcomeBlock';
import KnowledgeBaseCompanyList from './KnowledgeBaseCompanyList.jsx';
import { useTypes, nav2, nav } from '../../common/PageTemplate/articleTypesFilter';
import PageTemplate from '../../common/PageTemplate/PageTemplate';
import { useQuery, useReactiveVar } from '@apollo/client';
import { knowledgeBasesQueryGql, organizationQueryGql } from '../../gql/query';
import { userProgressFragmentGql } from '../../gql/fragment';
import { setArticlesArguments, setIsMobile, setIsUserId } from '../../gql/cache';
import { useParams } from 'react-router-dom';
import DropdownLabelPage from '../../components/Dropdown/DropdownLabelPage';

const KnowledgeBaseCompany = () => {
  const { id } = useParams();
  const { types, selectedIds } = useTypes();
  const { data, client } = useQuery(knowledgeBasesQueryGql);
  const [typesSort, setTypesSort] = useState('Newest');

  const [viewListBtn, setViewListBtn] = useState(true);
  const [viewGridBtn, setViewGridBtn] = useState(false);

  const handleViewListBtn = () => {
    if (viewGridBtn) {
      setViewGridBtn(false);
    }
    if (viewGridBtn && viewListBtn) {
      setViewListBtn(true);
    }
    setViewListBtn(true);
  };

  const handleViewGridBtn = () => {
    if (viewListBtn) {
      setViewListBtn(false);
    }
    if (viewGridBtn && viewListBtn) {
      setViewGridBtn(true);
    }
    setViewGridBtn(true);
  };

  useEffect(() => {
    setArticlesArguments({
      articleType: selectedIds,
    });
    return () => setArticlesArguments({});
  }, [selectedIds, typesSort]);

  // TODO: not work
  const list = useMemo(() => {
    if (data) {
      return data.knowledgeBases;
    }
    return [];
  }, [data]);

  const { knowledgeBasePassed } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: userProgressFragmentGql,
  });

  // TODO: not work
  const { data: dataOrganization } = useQuery(organizationQueryGql, {
    variables: {
      id,
    },
  });

  if (!dataOrganization) {
    return null;
  }

  return (
    <PageTemplate
      title={dataOrganization?.organization?.name}
      // onlyDropdown
      dropdownPosition="left"
      dropdownLabelArr={nav2}
      dropdownCheckboxArr={types}
      isKnowledge
      viewBtn
      viewListBtn={viewListBtn}
      viewGridBtn={viewGridBtn}
      onClickViewListBtn={handleViewListBtn}
      onClickViewGridBtn={handleViewGridBtn}
    >
      {!knowledgeBasePassed && (
        <WelcomeBlock
          type="knowledgeBasePassed"
          title={`Welcome to ${dataOrganization?.organization?.name} knowledge base`}
          description="Here you can find folders to discover more about your company"
        />
      )}

      <KnowledgeBaseCompanyList
        list={dataOrganization?.organization.knowledgeBases}
        name={dataOrganization?.organization?.name}
        viewListBtn={viewListBtn}
        viewGridBtn={viewGridBtn}
      />
    </PageTemplate>
  );
};

export default KnowledgeBaseCompany;
