import moment from 'moment';

const parseCookie = () => {
  const cookieArr = document.cookie.split(';');
  return cookieArr.reduce((acc, item) => {
    const [key, value] = item.trim().split('=');
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

export const session = {
  set: (key, value, remember) => {
    const date = moment().add(30, 'days');
    document.cookie = `${key}=${value};expires=${date};path=/`;
  },
  get: (key) => {
    const cookie = parseCookie();
    return cookie[key];
  },
  remove: (key) => {
    const cookie = parseCookie();
    const date = new Date('2007-07-07T07:07:07');
    document.cookie = `${key}=${cookie[key]};expires=${date};path=/`;
  },
};

export const isMobileDevice = () => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return regex.test(window.navigator.userAgent);
};

export const getImageURL = (url) => {
  return process.env.REACT_APP_API_URL + url;
};
