import React, { useCallback, useMemo } from 'react';
import Badge from '../../common/Badge/Badge';
import People from '../../common/People/People';
import ButtonIcon from '../../common/Button/ButtonIcon';
import moment from 'moment';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { eventsFragment } from '../../gql/fragment';
import { userQueryGql, eventsQueryGql } from '../../gql/query';
import { setIsUserId } from '../../gql/cache';
import { updateUserMutationGql } from '../../gql/mutation';

const UpcomingEventsItem = ({ date, type, name, auditory, id, getEvents }) => {
  const [updateEvents, { client }] = useMutation(updateUserMutationGql, {
    refetchQueries: [
      {
        query: userQueryGql,
        variables: {
          id: setIsUserId(),
        },
      },
      {
        query: eventsQueryGql,
        variables: {
          where: {
            date_gte: moment().format('YYYY-MM-DD'),
            date_lte: moment().add(30, 'days').format('YYYY-MM-DD'),
          },
        },
      },
    ],
  });

  const { events } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: eventsFragment,
  });

  const active = useMemo(() => _.some(events, ['id', id]), [events, id]);

  const onClick = useCallback(() => {
    if (active) {
      return updateEvents({
        variables: {
          input: {
            where: {
              id: setIsUserId(),
            },
            data: {
              events: _.filter(_.without(_.map(events, 'id'), id), null),
            },
          },
        },
      });
    }
    return updateEvents({
      variables: {
        input: {
          where: {
            id: setIsUserId(),
          },
          data: {
            events: _.filter([..._.map(events, 'id'), id], null),
          },
        },
      },
    });
  }, [active, events, id, updateEvents]);

  return (
    <div className="upcoming-events__item">
      <div className="upcoming-item-calendar">
        <span className="upcoming-item-calendar__date">{moment(date).format('DD')}</span>
        <span className="upcoming-item-calendar__month">{moment(date).format('MMM')}</span>
      </div>
      <div className="upcoming-item-info">
        <div className="upcoming-item-event">
          <div className="upcoming-item-event-info">
            <span className="upcoming-item-event-info__name">{name}</span>
            <span className="upcoming-item-event-info__status">
              <Badge label={type} size="xs" color="green" isIcon={false} />
            </span>
          </div>
          <People amount={auditory.length} people={auditory} />
        </div>
        <div className="upcoming-item-event-button">
          <ButtonIcon active={active} icon="add-to-calendar" size="md" onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default UpcomingEventsItem;
