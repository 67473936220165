import React from 'react';
import People from '../../../common/People/People';

const ProfileSidebarDescription = ({ text }) => {
  return (
    <div className="profile-sidebar-description">
      {text.map((item, index) => (
        <div key={index} className="profile-sidebar-description__item">
          {item}
        </div>
      ))}
    </div>
  );
};

export default ProfileSidebarDescription;
