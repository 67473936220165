import React, { useMemo, useState } from 'react';
import Svg from '../SVG';
import cx from 'classnames';
import People from '../People/People';
import _ from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { setIsAuthorized } from '../../gql/cache';
import { useHistory } from 'react-router';
import CommentModal from './CommentModal';

const Comment = ({
  label,
  className,
  active,
  disabled,
  comments,
  articleId,
  slug,
  isRenderAvatar = false,
}) => {
  const authorized = useReactiveVar(setIsAuthorized);
  const [openModalEmpty, setOpenModalEmpty] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { push } = useHistory();

  const commentsData = useMemo(() => {
    if (comments) {
      return comments;
    }
    return [];
  }, [comments]);

  const handleClickEmpty = (event) => {
    if (disabled) return;
    event.preventDefault();
    event.stopPropagation();
    if (authorized) {
      return setOpenModalEmpty(!openModalEmpty);
    }
    push('/login');
  };
  const handleClick = (event) => {
    if (disabled) return;
    event.preventDefault();
    event.stopPropagation();
    if (authorized) {
      return setOpenModal(!openModal);
    }
    push('/login');
  };

  const emptyCommentClass = cx(
    'empty-comment',
    className,
    active && 'active',
    (disabled || !authorized) && 'disabled'
  );

  const people = _.uniq(_.map(commentsData, 'user'));

  if (!commentsData.length || !authorized) {
    return (
      <>
        <div className={emptyCommentClass} onClick={handleClickEmpty}>
          <Svg name="comment" />
          {label && <span>Comment</span>}
        </div>
        <CommentModal
          slug={slug}
          openModal={openModalEmpty}
          handleClick={handleClickEmpty}
          articleId={articleId}
          commentsData={commentsData}
        />
      </>
    );
  }

  const commentPeopleClassName = cx('comment-people', { disabled });

  return (
    <>
      <div className={commentPeopleClassName} onClick={handleClick}>
        {commentsData.length && people.length && (
          <>
            {isRenderAvatar ? <People people={people} /> : <Svg name="comment" />}
            <span>{commentsData.length} comments</span>
          </>
        )}
      </div>

      <CommentModal
        openModal={openModal}
        handleClick={handleClick}
        articleId={articleId}
        commentsData={commentsData}
        slug={slug}
      />
    </>
  );
};

export default Comment;
