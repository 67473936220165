import { setIsAuthorized, setArticlesArguments, setIsMobile, setIsUserId } from './cache';

const typePolicies = {
  Query: {
    fields: {
      isAuthorized: {
        read() {
          return setIsAuthorized();
        },
      },
      articlesArguments: {
        read() {
          return setArticlesArguments();
        },
      },
      isMobile: {
        read() {
          return setIsMobile();
        },
      },
      isUserId: {
        read() {
          return setIsUserId();
        },
      },
    },
  },
};

export default typePolicies;
