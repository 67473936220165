import { handleServiceResponse } from './service.utils';

const getAbsoluteURL = (url) => {
  if (url.includes('http://') || url.includes('https://')) {
    return url;
  }
  return process.env.REACT_APP_API_URL + url;
};

const fetchData = (url, method, body, headers = null) => {
  const URL = getAbsoluteURL(url);
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };
  const options = {
    method: method,
    headers: headers ? Object.assign(defaultHeaders, headers) : defaultHeaders,
  };
  if (body) {
    Object.assign(options, { body: JSON.stringify(body) });
  }

  return fetch(URL, { ...options }).then(handleServiceResponse);
};

const instanceFetch = {
  get: async (url, headers = null) => await fetchData(url, 'GET', null, headers),
  post: async (url, body, headers = null) => await fetchData(url, 'POST', body, headers),
  patch: async (url, body, headers = null) => await fetchData(url, 'PATCH', body, headers),
  delete: async (url, headers = null) => await fetchData(url, 'DELETE', headers),
};

export { instanceFetch };
