import React from 'react';
import cx from 'classnames';
import { useReactiveVar } from '@apollo/client';
import { setIsAuthorized, setIsMobile } from '../../gql/cache';
import Masonry from 'react-masonry-css';
import ArticlesItem from '../../components/Articles/ArticlesItem';
import { useMediaQuery } from '../Dashboard/ResizeArticleInPage';
import ResizeArticle from '../../components/Articles/ResizeArticle';

const FeedEverythingTab = ({ isFeedPage, data }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const articleSize = useMediaQuery('(min-width: 1920px)');
  const authorized = useReactiveVar(setIsAuthorized);

  ResizeArticle(isFeedPage);

  const masonryClass = cx(
    'masonry-articles-list',
    { 'feed-page': isFeedPage },
    !isFeedPage && (authorized ? 'authorized' : 'unauthorized')
  );

  if (!data?.articles) return null;

  return (
    <Masonry breakpointCols={2} className={masonryClass} columnClassName="masonry-articles-col">
      {data?.articles.map((item) => (
        <ArticlesItem
          key={item.id}
          {...item}
          classic
          size={isMobile ? 'xs' : articleSize ? 'lg' : 'md' && data?.articles.length === 1 && 'md'}
        />
      ))}
    </Masonry>
  );
};

export default FeedEverythingTab;
