import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import TabLabel from '../../common/Tab/TabLabel';
import DropdownProfileTopBar from './DropdownProfileTopBar';
import SwipeWrapper from '../../common/SwipeWrapper/SwipeWrapper';
import { SwiperSlide } from 'swiper/react';
import { useReactiveVar } from '@apollo/client';
import { setArticlesArguments, setIsMobile } from '../../gql/cache';
import MobileModalPageDropdown from '../../components/Dropdown/MobilePageDropdown/MobileModalPageDropdown';
import { useTypes } from '../../common/PageTemplate/articleTypesFilter';

const ProfileTabBar = ({ active, setActive, profileTabs, badgeAmount }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const [shadowElement, setShadowElement] = useState('');

  const { types, selectedIds, setSelected, selected } = useTypes();
  const [typesSort, setTypesSort] = useState('Newest');

  useEffect(() => {
    setArticlesArguments({
      articleType: selectedIds,
    });
    return () => setArticlesArguments({});
  }, [selectedIds, typesSort]);

  const handleScroll = (e) => {
    const element = e.target;

    if (element.scrollTop > 420) {
      setShadowElement('shadow');
    } else setShadowElement('');
  };

  useEffect(() => {
    const scrollElement = document.getElementsByClassName('page__content')[0];

    scrollElement.addEventListener('scroll', handleScroll);

    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const profileTabClass = cx('profile-tab-bar', !isMobile && shadowElement);

  const profileTabContent = (
    <div className="profile-tab-bar__tab-items tab-label-list">
      {profileTabs.map(({ label, type }) => (
        <TabLabel
          transparent
          key={label}
          label={label}
          onClick={() => setActive(type)}
          active={active === type}
          badgeAmount={badgeAmount[type]}
        />
      ))}
    </div>
  );

  const profileDropdownContent = (
    <div className="mobile-modal__dropdown-items">
      <DropdownProfileTopBar />
    </div>
  );

  if (isMobile) {
    return (
      <div className={profileTabClass}>
        <SwipeWrapper>
          <SwiperSlide>{profileTabContent}</SwiperSlide>
        </SwipeWrapper>
        {active !== 'info' && (
          <MobileModalPageDropdown
            types={types}
            selectedTypes={selectedIds}
            selectedLabel={selected}
            onSelectTypes={setTypesSort}
            onSelectLabel={setSelected}
          />
        )}
      </div>
    );
  }
  return (
    <div className={profileTabClass}>
      {profileTabContent}
      {profileDropdownContent}
    </div>
  );
};

export default ProfileTabBar;
