import { useEffect, useMemo } from 'react';

const useResizeArticleProfile = (loading) => {
  const conf = useMemo(() => {
    if (loading) {
      const media1567 = window.matchMedia('(min-width: 1666px)');
      const mobileMedia = window.matchMedia('(min-width: 320px) and (max-width: 1244px)');
      const item = document.getElementsByClassName('masonry-articles-col').length;
      const col = [];

      for (let i = 0; i < item; i++) {
        col.push(i);
      }

      return {
        media1567,
        mobileMedia,
        col,
      };
    }
    return null;
  }, [loading]);

  useEffect(() => {
    if (!conf) {
      return;
    }

    const { col, media1567, mobileMedia } = conf;

    if (!col.length) {
      return;
    }

    const colArr = col.map(
      (value) => document.getElementsByClassName('masonry-articles-col')[value]
    );

    const arrFirstCol = [],
      arrSecondCol = [];

    colArr.forEach((item, index) => {
      const arrCol = index % 2;
      if (arrCol) {
        arrSecondCol.push(...item.children);
      } else {
        arrFirstCol.push(...item.children);
      }
    });

    const handle1567 = (media1567) => {
      if (media1567.matches) {
        arrFirstCol.map((value) => {
          value.classList.add('md');
          value.classList.remove('xs', 'xlg');
        });
        arrSecondCol.map((value) => {
          value.classList.add('xs');
          value.classList.remove('md', 'xlg');
        });
      } else {
        arrFirstCol.map((value) => {
          value.classList.add('xlg');
          value.classList.remove('md', 'xs');
        });
        arrSecondCol.map((value) => {
          value.classList.add('xlg');
          value.classList.remove('md', 'xs');
        });
      }
    };

    const handleMobile = (mobileMedia) => {
      if (mobileMedia.matches) {
        arrFirstCol.map((value) => {
          value.classList.add('xs');
          value.classList.remove('md', 'xlg');
        });
        arrSecondCol.map((value) => {
          value.classList.add('xs');
          value.classList.remove('md', 'xlg');
        });
      }
    };

    media1567.addListener(handle1567);
    mobileMedia.addListener(handleMobile);

    handle1567(media1567);
    handleMobile(mobileMedia);

    return () => {
      media1567.removeListener(handle1567);
      mobileMedia.removeListener(handleMobile);
    };
  }, [conf]);
};

export default useResizeArticleProfile;
