import React, { useMemo, useState, useCallback } from 'react';
import { useQuery, useReactiveVar, useMutation } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../gql/cache';
import cx from 'classnames';
import { Spin, Upload } from 'antd';
import { useParams } from 'react-router-dom';
import Button from '../../common/Button/Button';
import ProfileSidebar from './ProfileSidebar/ProfileSidebar';
import ProfileContent from './ProfileContent';
import ProfileSidebarMobile from './ProfileSidebar/ProfileSidebarMobile';
import { userQueryGql } from '../../gql/query';
import { getImageURL } from '../../helper';
import { updateUserMutationGql, uploadMutationGql } from '../../gql/mutation';

// TODO: for empty state public profile;
// <ProfilePublicEmpty title="Dima hasn’t published any materials yet" />
// <ProfilePublicEmpty title="Henrietta hasn’t clapped to any materials yet" />
// <ProfilePublicEmpty title="Henrietta hasn’t completed any course yet" />

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const isMobile = useReactiveVar(setIsMobile);
  const { id } = useParams();
  const { data: dataUser } = useQuery(userQueryGql, {
    variables: { id: id ? id : setIsUserId() },
  });

  const [updateUser] = useMutation(updateUserMutationGql, {
    onCompleted: () => {
      setLoading(false);
      setFileList([]);
    },
  });

  const itIsMe = useMemo(() => {
    if (dataUser) {
      return dataUser.user.id === setIsUserId();
    }
    return false;
  }, [dataUser]);

  const [uploadFile] = useMutation(uploadMutationGql, {
    onCompleted: ({ upload: { id } }) => {
      return updateUser({
        variables: {
          input: {
            where: {
              id: setIsUserId(),
            },
            data: {
              coverImage: id,
            },
          },
        },
      });
    },
  });

  const onClick = useCallback(() => {
    setLoading(true);

    if (fileList.length) {
      const [{ originFileObj: file }] = fileList;
      return uploadFile({
        variables: {
          file,
        },
      });
    }
  }, [fileList, uploadFile]);

  const imageCover = useMemo(() => {
    if (dataUser && dataUser.user.coverImage && dataUser.user.coverImage.url) {
      return `url(${getImageURL(dataUser.user.coverImage.url)})`;
    }
    return '';
  }, [dataUser]);

  if (!dataUser) {
    return <Spin tip="Loading..." />;
  }

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const profileInnerClass = cx('profile__inner', { mobile: isMobile });
  return (
    <div className="profile__container">
      <div className="profile-cover" style={{ backgroundImage: imageCover }}>
        {loading && (
          <div className="profile-cover__loader">
            <Spin size="large" />
          </div>
        )}

        {itIsMe && (
          <Upload
            listType="picture-card"
            fileList={fileList}
            multiple={false}
            onChange={onChange}
            onPreview={onPreview}
          >
            <Button
              text="Change cover"
              type="outline-white"
              iconLeft="image"
              size={isMobile ? 'xs' : 'md'}
            />
          </Upload>
        )}

        {!!fileList.length && (
          <Button
            onClick={onClick}
            text="Confirm"
            type="outline-purple"
            iconLeft="image"
            size="md"
          />
        )}
      </div>

      <div className={profileInnerClass}>
        {isMobile ? (
          <ProfileSidebarMobile user={dataUser.user} itIsMe={itIsMe} />
        ) : (
          <ProfileSidebar user={dataUser.user} itIsMe={itIsMe} />
        )}
        <ProfileContent user={dataUser.user} itIsMe={itIsMe} />
      </div>
    </div>
  );
};

export default Profile;
