import React from 'react';
import moment from 'moment';

const ArticlePublicationTime = ({ updatedAt }) => {
  let updateTime = moment(moment(updatedAt).format('YYYY-MM-DD HH:mm:ss'));
  let currentTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'));

  let year = updateTime.diff(currentTime, 'year');
  currentTime.add(year, 'years');

  let month = updateTime.diff(currentTime, 'months');
  currentTime.add(month, 'months');

  let day = updateTime.diff(currentTime, 'days');
  currentTime.add(day, 'days');

  let hour = updateTime.diff(currentTime, 'hours');
  currentTime.add(hour, 'hours');

  let minute = updateTime.diff(currentTime, 'minutes');
  currentTime.add(minute, 'minutes');

  let second = updateTime.diff(currentTime, 'seconds');
  currentTime.add(second, 'seconds');

  const days = Math.abs(day);
  const hours = Math.abs(hour);
  const minutes = Math.abs(minute);

  if (days === 0 && hours === 0 && minutes < 5) return <span>less than 5 minutes</span>;
  if (days === 0 && hours < 1 && minutes >= 5) return <span>less than 1 hour</span>;
  if (days === 0 && hours >= 1) return <span>{hours} hours ago</span>;
  if (days === 1) return <span>Yesterday</span>;
  if (days > 1) return <span>{moment(updatedAt).format('DD.MM.YYYY')}</span>;

  return <span>{moment(updatedAt).format('DD.MM.YYYY')}</span>;
};

export default ArticlePublicationTime;
