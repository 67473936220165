import React, { useState } from 'react';
import TabLabel from '../../common/Tab/TabLabel';
import SwipeWrapper from '../../common/SwipeWrapper/SwipeWrapper';
import { SwiperSlide } from 'swiper/react';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const PageTemplateTab = ({ activeTab, setActiveTab, tabsArr }) => {
  const isMobile = useReactiveVar(setIsMobile);

  const profileTabContent = tabsArr.map(({ label, badgeAmount }, key) => (
    <SwiperSlide>
      <TabLabel
        transparent
        key={label}
        label={label}
        badgeAmount={badgeAmount}
        onClick={() => setActiveTab(key)}
        active={activeTab === key}
      />
    </SwiperSlide>
  ));
  if (isMobile) {
    return (
      <div className="page-template-tabs">
        <div className="tab-label-list">
          <SwipeWrapper>{profileTabContent}</SwipeWrapper>
        </div>
      </div>
    );
  }
  return (
    <div className="tab-label-list">
      {tabsArr.map(({ label, badgeAmount }, key) => (
        <TabLabel
          transparent
          key={label}
          label={label}
          badgeAmount={badgeAmount}
          onClick={() => setActiveTab(key)}
          active={activeTab === key}
        />
      ))}
    </div>
  );
};

export default PageTemplateTab;
