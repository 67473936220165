import { createContext, memo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { setIsAuthorized, setIsUserId } from '../../gql/cache';
import { useHistory } from 'react-router-dom';
import { meQueryGql, userQueryGql } from '../../gql/query';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const authorized = useReactiveVar(setIsAuthorized);
  const {
    push,
    location: { pathname, search },
  } = useHistory();

  const { data } = useQuery(meQueryGql, {
    onCompleted: async ({ me }) => {
      setIsUserId(me.id);
    },
  });

  useQuery(userQueryGql, {
    variables: {
      id: data?.me.id,
    },
    skip: authorized || !data?.me.id,
    onCompleted: ({ user }) => {
      setIsAuthorized(true);
      if (user.onboardingPassed) {
        if (pathname === '/dashboard') {
          push('/dashboard');
        }
        push(pathname + search);
      } else {
        push('/onboarding');
      }
    },
  });

  return <AuthContext.Provider value={{ authorized }}>{children}</AuthContext.Provider>;
};

export default memo(AuthProvider);
