import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Tab from '../../common/Tab/Tab';
import SearchInput from '../../common/SearchInput/SearchInput';
import { useLazyQuery } from '@apollo/client';
import { aggregateConnectionGql } from '../../gql/query';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

const TYPES_SEARCH = {
  articles: { iconLeft: 'topic-filled', color: 'whitePurple', to: '/article', mainKey: 'headline' },
  courses: {
    iconLeft: 'learning',
    typeRight: 'category',
    color: 'whitePurple',
    formatIcon: 'learning',
    to: '/courses',
    mainKey: 'headline',
  },
  events: { iconLeft: 'document', color: 'whitePurple', to: '/events', mainKey: 'name' },
  interests: {
    iconLeft: 'document',
    color: 'whitePurple',
    to: '/interests',
    mainKey: 'interestTitle',
  },
  knowledge: {
    iconLeft: 'document',
    color: 'whitePurple',
    to: '/knowledge-base-company-folder',
    mainKey: 'name',
  },
  lessons: {
    iconLeft: 'topic-filled',
    typeRight: 'follow',
    color: 'whitePurple',
    to: '/lessons',
    mainKey: 'headline',
  },
  users: {
    iconLeft: 'topic-filled',
    typeRight: 'follow',
    color: 'whitePurple',
    to: '/profile',
    mainKey: 'username',
  },
};

const SearchData = () => {
  const [searchInput, setSearchInput] = useState('');
  const { push } = useHistory();
  const [showResult, setShowResult] = useState(false);
  const [searchArticles, { data }] = useLazyQuery(aggregateConnectionGql, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setShowResult(true);
    },
  });

  useEffect(() => {
    if (searchInput.length >= 4) {
      searchArticles({
        variables: {
          input: searchInput,
          limit: 5,
        },
      });
    }
  }, [searchArticles, searchInput]);

  const renderSearch = useMemo(() => {
    const items = [];
    if (data) {
      _.forIn(data, ({ aggregate, values }, key) => {
        if (key === 'articles' || key === 'users') {
          items.push(
            <div className="popups__group" key={key}>
              <div className="popups__title">
                {key} <span>({aggregate.count})</span>
              </div>
              {values.map((item) => {
                const { id, slug } = item;
                const { mainKey, to } = TYPES_SEARCH[key];
                const onClick = () => push(`${to}/${slug ? slug : id}`);
                return (
                  <Tab
                    key={item.id}
                    id={item.id}
                    x
                    {...TYPES_SEARCH[key]}
                    textLeft={item[mainKey]}
                    type={key}
                    onClick={onClick}
                  />
                );
              })}
            </div>
          );
        }
      });
    }
    return items;
  }, [data, push]);

  const goToToSearch = useCallback(
    ({ code }) => {
      if (code === 'Enter') {
        push(`/search?${searchInput}`);
      }
    },
    [push, searchInput]
  );

  return (
    <SearchInput
      id="header-search"
      value={searchInput}
      setSearch={setSearchInput}
      showChildren={showResult}
      close={() => setShowResult(false)}
      goToToSearch={goToToSearch}
      placeholder="Search for everything..."
    >
      {showResult && renderSearch}
    </SearchInput>
  );
};

export default SearchData;
