import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../../gql/cache';
import Button from '../../../common/Button/Button';
import ButtonIcon from '../../../common/Button/ButtonIcon';

const ProfileSidebarSocial = ({ handleEditClick, edit, follow, instagram, facebook, active }) => {
  const isMobile = useReactiveVar(setIsMobile);

  return (
    <div className="profile-sidebar__social">
      {follow && (
        <Button
          onClick={handleEditClick}
          type="primary"
          size={isMobile ? 'xs' : 'md'}
          text={active ? 'Unfollow' : 'Follow'}
          iconLeft={active ? 'checkmark' : 'plus'}
        />
      )}

      {edit && (
        <Button
          onClick={handleEditClick}
          type="outline-purple"
          size={isMobile ? 'xs' : 'md'}
          text="Edit profile"
          iconLeft="edit"
        />
      )}

      {instagram && (
        <ButtonIcon
          icon="instagram"
          size={isMobile ? 'xs' : 'md'}
          onClick={() => window.open(instagram, '_blank')}
        />
      )}

      {facebook && (
        <ButtonIcon
          icon="facebook"
          size={isMobile ? 'xs' : 'md'}
          onClick={() => window.open(facebook, '_blank')}
        />
      )}
    </div>
  );
};

export default ProfileSidebarSocial;
