import React from 'react';
import { Input } from 'antd';
import Label from '../Label/Label';
import Svg from '../SVG';

const InputText = (props) => {
  const {
    name = 'text',
    labelText,
    type = 'text',
    placeholder,
    id,
    error,
    prompt,
    value,
    onChange,
    social,
    onPressEnter,
  } = props;

  return (
    <div className={`field ${social ? 'social' : ''}`}>
      {labelText && <Label id={id} text={labelText} />}

      <div className="field__wrapper">
        {social && <Svg className="field__social" name={social} />}

        <Input
          type={type}
          name={name}
          id={id}
          className="field__input"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onPressEnter={onPressEnter}
        />
      </div>

      {!error && prompt && <div className="field__prompt">{prompt}</div>}
      {error && <div className="field__error">{error}</div>}
    </div>
  );
};

export default InputText;
