import React from 'react';
import Svg from '../../common/SVG';
import { Link } from 'react-router-dom';

const ArticleModalCard = ({ description = true }) => {
  return (
    <Link to="/" className="article-modal__card">
      <img className="card-img" alt="title" src="/images/article-card-img.png" />
      <div className="card-info">
        <span className="card-title">A Different Way of Thinking About Productivity</span>
        {description && (
          <span className="card-description">
            James Clear rites about habits, decision making, and continuous improvement. His first
            book, Atomic Habits, is a #1 New York Times bestseller and has sold over 4 millio...
          </span>
        )}
        <div className="card-link">
          <Svg className="card-link-svg" name="url" />
          JAMESCLEAR.COM
        </div>
      </div>
    </Link>
  );
};

export default ArticleModalCard;
