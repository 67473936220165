import React from 'react';
import Svg from '../../common/SVG';
import { useFeedFilter } from './feedFilter';

const FeedFilters = () => {
  const { clearAll, list, removeFilter } = useFeedFilter();

  return (
    <div className="feed-filters">
      <div className="feed-filters-items-container">
        {list.map((item) => {
          return (
            <div className="feed-filters__item" key={item.name}>
              <span>{item.name}</span>
              <Svg
                name="close"
                onClick={(e) => {
                  e.preventDefault();
                  removeFilter(item);
                }}
              />
            </div>
          );
        })}
      </div>

      {!!list.length && (
        <button className="btn feed-filters__clear-btn" onClick={clearAll}>
          Clear all filters
        </button>
      )}
    </div>
  );
};

export default FeedFilters;
