import React, { useContext } from 'react';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import { useLocation } from 'react-router-dom';
import SidebarAuthDesktop from './SidebarAuthDesktop';
import SidebarUnAuth from './SidebarUnAuth';
import SidebarCourse from './SidebarCourse';
import { AuthContext } from '../Guard/AuthContext';

const pathOne = /\/dashboard\/your-learnings\/\d\/\d/;
const pathTwo = '/preparing-material';

const SideBar = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const { authorized } = useContext(AuthContext);
  const { pathname } = useLocation();

  if (pathOne.test(pathname) || pathTwo === pathname) {
    return <SidebarCourse />;
  }

  if (authorized && !isMobile) {
    return <SidebarAuthDesktop />;
  }

  if (pathname === '/') {
    return <SidebarUnAuth />;
  }

  return null;
};

export default SideBar;
