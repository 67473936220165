import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import People from './People';
import ModalWrapper from '../../components/Modals/ModalWrapper';
import { useQuery } from '@apollo/client';
import { usersQueryGql } from '../../gql/query';
import { useFollowUser } from '../../gql/gqlHooks';
import _ from 'lodash';
import PeopleFollowItem from './PeopleFollowItem';

const PeopleFollow = ({
  followAmount,
  followers,
  following,
  course,
  direction = 'col',
  userIds,
  userName,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const [firstName, lastName] = _.map(_.words(userName), _.capitalize);

  const { data } = useQuery(usersQueryGql, {
    variables: {
      where: {
        id: userIds,
      },
    },
    skip: !userIds.length,
  });

  const handleClick = () => {
    setModalVisible(!isModalVisible);
  };

  const callBack = useCallback(() => {
    setModalVisible(!isModalVisible);
  }, [isModalVisible]);

  const { onClick, isActive } = useFollowUser(callBack);

  const peopleFollowClass = cx('people-follow', course, direction);

  if (!followAmount || !data) {
    return null;
  }

  const modalHeader = course ? (
    <>Learners</>
  ) : (
    <>
      <span>{`${firstName} ${lastName}`}</span>’s
      <span>
        {followers && 'followers'} {following && 'following'}
      </span>
    </>
  );

  return (
    <>
      <div className={peopleFollowClass} onClick={handleClick}>
        <People people={data?.users} maxPeopleToDisplay={3} />
        <div className="people-follow__amount">
          {followAmount} {followers && 'followers'} {following && 'following'}{' '}
          {course && 'Learners'}
        </div>
      </div>
      <ModalWrapper
        className="modal-with-sticky-header"
        isModalVisible={isModalVisible}
        handleClick={handleClick}
        modalWidth={424}
      >
        <div className="ant-modal-body-container">
          <div className="ant-modal-sticky-header">{modalHeader}</div>
          <div className="ant-modal-body-content">
            {data?.users.map(({ id, username, avatar }) => (
              <PeopleFollowItem
                key={id}
                id={id}
                username={username}
                avatar={avatar}
                isActive={isActive}
                onClick={onClick}
              />
            ))}
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default PeopleFollow;
