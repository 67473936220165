import React from 'react';
import fetchMeta from 'fetch-meta-tags';

const contentMap = {
  p: (v, index) =>
    React.createElement(
      'p',
      {
        'className': 's-2',
        'id': `${index}-content`,
        'data-placeholder': 'Enter paragraph',
      },
      v
    ),
  h1: (v, index) =>
    React.createElement(
      'h1',
      { 'className': 'l-2', 'id': `${index}-content`, 'data-placeholder': 'Enter headline' },
      v
    ),
  h2: (v, index) =>
    React.createElement(
      'h2',
      { 'className': 'l-5', 'id': `${index}-content`, 'data-placeholder': 'Enter headline 2' },
      v
    ),
  h3: (v, index) =>
    React.createElement(
      'h2',
      { 'className': 'l-3', 'id': `${index}-content`, 'data-placeholder': 'Enter headline 3' },
      v
    ),
  q: (v, index) =>
    React.createElement(
      'blockquote',
      {
        'data-placeholder': 'Enter blockquote',
        'className': 'quote-text',
        'id': `${index}-content`,
      },
      v
    ),
  img: (v, index) =>
    React.createElement('img', { src: v, className: 'article-img', id: `${index}-content` }),
  link: (v, index) => {
    const LinkMeta = () => {
      const [meta, setMeta] = React.useState(null);

      React.useEffect(() => {
        fetchMeta(v)
          .then((data) => setMeta(data))
          .catch(() => setMeta(null));
      }, []);

      if (!meta) {
        return React.createElement('a', {
          'href': v,
          'children': v,
          'target': '_blank',
          'data-placeholder': 'Enter link',
          'className': 'article-link-prepare',
          'id': `${index}-content`,
        });
      }
      const { url, title, description, icon } = meta;
      const ico = React.createElement('img', { src: icon, className: 'article-img' });
      const desc = React.createElement(
        'p',
        {
          className: 's-2',
        },
        description
      );

      return React.createElement('a', {
        'href': url,
        'children': [ico, title, desc],
        'target': '_blank',
        'data-placeholder': 'Enter link',
        'className': 'article-link-prepare',
        'id': `${index}-content`,
      });
    };
    return React.createElement(LinkMeta);
  },
  mark: (v, index) =>
    React.createElement(
      'mark',
      { 'data-placeholder': 'Enter mark text', 'className': 'mark-text', 'id': `${index}-content` },
      v
    ),
  video: (v) =>
    React.createElement(
      'div',
      { className: 'article-video' },
      <iframe
        src={v}
        width="560"
        height="315"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      />
    ),
};

export const createArticlesElement = ({ type, value }, index) => {
  const Element = () => contentMap[type](value, index);
  return <Element key={index} />;
};
