import React from 'react';
import Dropdown from './Dropdown';
import Tab from '../../common/Tab/Tab';

const DropdownIconPage = ({ className = 'dropdown-page', icon, array, position }) => {
  return (
    <Dropdown btnType="icon" className={className} icon={icon} position={position}>
      <div className="dropdown-content-list__nav">
        {array.map(({ name, icon, onClick }) => (
          <Tab
            key={name}
            textLeft={name}
            block
            iconLeft={icon}
            onClick={onClick}
            color="whitePurple"
          />
        ))}
      </div>
    </Dropdown>
  );
};

export default DropdownIconPage;
