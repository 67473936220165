import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Button from '../../common/Button/Button';
import PeopleFollow from '../../common/People/PeopleFollow';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { updateUserMutationGql } from '../../gql/mutation';
import { setIsUserId } from '../../gql/cache';

const ActionsCourse = ({ lessons, usersStarted }) => {
  const { push } = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [start] = useMutation(updateUserMutationGql, {
    variables: {
      input: {
        where: {
          id: setIsUserId(),
        },
        data: {
          coursesStarted: [id],
        },
      },
    },
  });

  const userIds = useMemo(() => {
    return _.map(usersStarted, 'id');
  }, [usersStarted]);

  const onClick = useCallback(() => {
    const { id: lessonId } = lessons[0];
    if (!_.includes(userIds, setIsUserId())) {
      start();
    }
    push(`${pathname}/${lessonId}`);
  }, [lessons, pathname, push, start, userIds]);

  return (
    <div className="actions-course">
      <Button
        type="primary"
        size="md"
        text="Start the course"
        iconRight="arrow-right"
        onClick={onClick}
      />
      <PeopleFollow followAmount={userIds.length} course direction="row" userIds={userIds} />
    </div>
  );
};

export default ActionsCourse;
