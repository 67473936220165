import React from 'react';
import InputText from '../../common/Inputs/InputText';
import { Form } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import Button from '../../common/Button/Button';
import { toast } from 'react-toastify';
import Svg from '../../common/SVG';
import { instanceFetch } from '../../helper/instanceFetch';

const DemoModal = ({ handleClick }) => {
  const onFinishFailed = (values) => {
    console.log('Failed:', values);
  };
  const onFinish = async (values) => {
    try {
      await instanceFetch.post(
        'https://www.corezoid.com/api/1/json/public/1058350/684cb51d917a227177201ea958918e47234281a3',
        values
      );
      toast.success(<div className="alert__text">Thank you! We'll contact you soon</div>, {
        position: toast.POSITION.TOP_CENTER,
        icon: <Svg name="checkmark" />,
      });
      handleClick();
    } catch (error) {
      toast.success(<div className="alert__text__bad">Sorry, bad request</div>, {
        position: toast.POSITION.TOP_CENTER,
        icon: <CloseCircleOutlined />,
      });
    }
  };
  return (
    <div className="demo-modal">
      <div className="demo-modal__header">
        <div className="demo-modal__title">Request a demo</div>
        <div className="demo-modal__text">We will contact you as soon as possible</div>
      </div>

      <div className="demo-modal__form">
        <Form autoComplete="off" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item name="name" rules={[{ required: true, message: 'Please input your name' }]}>
            <InputText labelText="Name" placeholder="Enter your name" id="demo-modal-name" />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[{ type: 'email', required: true, message: 'You input not valid E-mail' }]}
          >
            <InputText labelText="Email" placeholder="Enter your email" id="demo-modal-email" />
          </Form.Item>

          <div className="demo-modal__footer">
            <Button onClick={handleClick} text="Cancel" size="md" type="outline-purple" />
            <Button text="Send request" size="md" type="primary" typeHtml="submit" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DemoModal;
