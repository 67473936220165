import React from 'react';
import Button from '../../common/Button/Button';
import InputText from '../../common/Inputs/InputText';
import LoginWrapper from './LoginWrapper';

const CheckEmail = () => {
  return (
    <LoginWrapper>
      <div className="login__title">Check your email for a link</div>

      <div className="login__buttons">
        <Button
          onClick={() => alert('Open Gmail')}
          text="Open Gmail"
          type="outline-purple"
          size="lg"
          block
        >
          <img src="/images/gmail.png" />
        </Button>

        <Button
          onClick={() => alert('Open Outlook')}
          text="Open Outlook"
          type="outline-purple"
          size="lg"
          block
        >
          <img src="/images/outlook.png" />
        </Button>

        <Button
          onClick={() => alert('Open iCloud Mail')}
          text="Open iCloud Mail"
          type="outline-purple"
          size="lg"
          block
        >
          <img src="/images/icloud.png" />
        </Button>
      </div>
    </LoginWrapper>
  );
};

export default CheckEmail;
