import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useFollowUser } from '../../gql/gqlHooks';
import ArticleUser from './ArticleUser';
import Button from '../../common/Button/Button';
import ArticleOptions from './ArticleOptions';

const ArticleItemHeaderAuthorized = ({ author, authorLogo, personal, authorId }) => {
  const { push } = useHistory();
  const { onClick, isActive, itsMe } = useFollowUser();

  const handleClick = useCallback(() => {
    onClick(authorId);
  }, [authorId, onClick, push]);

  return (
    <div className="article-item-header">
      <ArticleUser authorLogo={authorLogo} author={author} authorId={authorId} />
      {personal ? (
        <ArticleOptions />
      ) : itsMe(authorId) ? null : (
        <Button
          type="secondary"
          size="xs"
          text={isActive(authorId) ? 'Following' : 'Follow'}
          iconLeft={isActive(authorId) ? 'checkmark' : 'plus'}
          onClick={handleClick}
        />
      )}
    </div>
  );
};
export default ArticleItemHeaderAuthorized;
