import React, { useMemo } from 'react';
import Timeline from '../../components/Timeline/Timeline';
import Button from '../../common/Button/Button';
import DashboardBlockInner from './DashboardBlockInner';
import { useProgress } from '../../gql/gqlHooks';
import { timeLineArray } from './DashboardOnboardingDesktop';
import { useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const DashboardOnboardingMobile = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const { push } = useHistory();
  const data = useProgress();

  const { done, line } = useMemo(() => {
    let done = 0;
    const complete = [];
    const waiting = [];

    timeLineArray.forEach((item) => {
      if (data[item.key] !== undefined) {
        item.type = data[item.key] ? 'done' : 'waiting';
      }
      if (item.type === 'done') {
        done = done + 1;
        complete.push(item);
      } else {
        waiting.push(item);
      }
    });
    return { line: complete.concat(waiting), done };
  }, [data]);

  if (line.length === done) {
    return null;
  }
  return (
    <DashboardBlockInner className="dashboard-onboarding">
      <div className="dashboard__block-header">
        <div className="m-5">
          Your next steps ({done}/{timeLineArray.length})
        </div>
      </div>
      <div className="dashboard-onboarding__inner">
        <Timeline timeLineArray={line} vertical />
        <Button
          text={line[done].text}
          type="primary"
          size="md"
          onClick={() => push(line[done].to)}
          block={isMobile}
        />
      </div>
    </DashboardBlockInner>
  );
};

export default DashboardOnboardingMobile;
