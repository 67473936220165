import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import InputText from '../../common/Inputs/InputText';
import Select from '../../common/Select/Select';
import Button from '../../common/Button/Button';
import { useQuery, useMutation } from '@apollo/client';
import { departmentsQueryGql } from '../../gql/query';
import { updateUserMutationGql } from '../../gql/mutation';
import { setIsUserId } from '../../gql/cache';
import DatepickerWithLabel from '../../common/DatepickerWithLabel/DatepickerWithLabel';
import InputPhone from '../../common/Inputs/InputPhone';

const onChange = (fn, key) => (event) =>
  fn((prev) => {
    if (key === 'departments') {
      return {
        ...prev,
        [key]: event,
      };
    }
    if (key === 'birthDate') {
      return {
        ...prev,
        [key]: event.format('YYYY-MM-DD'),
      };
    }
    return {
      ...prev,
      [key]: event.target.value,
    };
  });

const ProfileEditModal = ({ handleEditClick, user }) => {
  const [updateUser] = useMutation(updateUserMutationGql, {
    onCompleted() {
      handleEditClick();
    },
  });

  const [userData, setUserData] = useState({
    username: user?.username,
    jobTitle: user?.jobTitle,
    aboutMe: user?.aboutMe,
    phone: user?.phone,
    instagram: user?.instagram,
    facebook: user?.facebook,
    location: user?.location,
    birthDate: user?.birthDate,
    departments: _.map(user?.departments, 'name'),
  });

  const { data: dataDepartments } = useQuery(departmentsQueryGql);

  const options = useMemo(() => {
    if (dataDepartments) {
      return _.map(dataDepartments.departments, 'name');
    }
    return [];
  }, [dataDepartments]);

  const saveUser = useCallback(() => {
    updateUser({
      variables: {
        input: {
          where: {
            id: setIsUserId(),
          },
          data: {
            ...userData,
            departments: _.map(
              _.filter(dataDepartments.departments, (i) =>
                _.includes(userData.departments, i.name)
              ),
              'id'
            ),
          },
        },
      },
    });
  }, [dataDepartments, updateUser, userData]);

  return (
    <div className="ant-modal-body-container">
      <div className="ant-modal-sticky-header">Edit profile</div>

      <div className="ant-modal-body-content">
        <InputText
          labelText="Full name"
          value={userData.username}
          onChange={onChange(setUserData, 'username')}
        />

        <InputText
          labelText="Job title"
          value={userData.jobTitle}
          onChange={onChange(setUserData, 'jobTitle')}
        />

        <InputText
          labelText="About me"
          value={userData.aboutMe}
          onChange={onChange(setUserData, 'aboutMe')}
        />

        <Select
          onChange={onChange(setUserData, 'departments')}
          value={userData.departments}
          options={options}
          labelText="Team"
          placeholder="Select your department"
        />

        <InputText
          labelText="Location"
          value={userData.location}
          onChange={onChange(setUserData, 'location')}
        />

        <DatepickerWithLabel
          labelText="Date of birth"
          id="dateOfBirthday"
          value={userData.birthDate}
          allowClear={false}
          onChange={onChange(setUserData, 'birthDate')}
        />

        <InputPhone
          labelText="Phone number"
          value={userData.phone}
          onChange={onChange(setUserData, 'phone')}
        />

        <InputText
          labelText="Instagram"
          placeholder="Add a link to your Instagram"
          value={userData.instagram}
          social="instagram"
          onChange={onChange(setUserData, 'instagram')}
        />

        <InputText
          labelText="Facebook"
          placeholder="Add a link to your Facebook"
          value={userData.facebook}
          social="facebook"
          onChange={onChange(setUserData, 'facebook')}
        />

        <div className="modal__buttons">
          <Button onClick={handleEditClick} text="Cancel" type="outline-purple" size="md" />

          <Button onClick={saveUser} text="Save changes" type="primary" size="md" />
        </div>
      </div>
    </div>
  );
};

export default ProfileEditModal;
