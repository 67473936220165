import React, { useState, useMemo } from 'react';
import LoadMore from '../../common/LoadMore/LoadMore';
import SearchPeople from './SearchPeople';
import PageTemplate from '../../common/PageTemplate/PageTemplate';
import { nav, useTypes } from '../../common/PageTemplate/articleTypesFilter';
import SearchLearningMaterials from './SearchLearningMaterials';
import SearchFeedPublication from './SearchFeedPublication';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from '@apollo/client';
import { aggregateConnectionGql } from '../../gql/query';

const searchTabsArr = [
  {
    label: 'Feed publications',
    badgeAmount: '0',
    content: (v) => <SearchFeedPublication values={v} />,
    key: 'articles',
  },
  {
    label: 'People',
    badgeAmount: '0',
    key: 'users',
    content: (v) => <SearchPeople values={v} />,
  },
  {
    label: 'Learning materials',
    badgeAmount: '0',
    content: (v) => <SearchLearningMaterials values={v} />,
    key: 'knowledge',
  },
  // {
  //   label: 'Organizations',
  //   badgeAmount: '31',
  //   content: (
  //     <>
  //       <SearchPeople />
  //       {/* <LoadMore /> */}
  //     </>
  //   ),
  // },
];

const Search = () => {
  const {
    location: { search },
  } = useHistory();
  const searchValue = useMemo(() => search.replace('?', ''), [search]);
  const [typesSort, setTypesSort] = useState('Newest');
  const sort = useMemo(() => {
    return typesSort === 'Newest' ? 'updated_at:DESC' : 'updated_at:ASC';
  }, [typesSort]);
  const { data } = useQuery(aggregateConnectionGql, {
    variables: {
      input: searchValue,
      sort,
    },
  });
  const { types, selectedIds, selected, onSelect } = useTypes();

  const [activeTab, setActiveTab] = useState(0);

  const tabs = useMemo(() => {
    if (data) {
      return searchTabsArr.map((item) => {
        return {
          ...item,
          badgeAmount: data[item.key].aggregate.count,
        };
      });
    }
    return searchTabsArr;
  }, [data]);

  return (
    <PageTemplate
      title="Search results for "
      pageFilter
      dropdownPosition="right"
      dropdownLabelArr={nav}
      tabList
      types={types}
      selectedTypes={selected}
      onSelectTypes={onSelect}
      selectedLabel={[typesSort]}
      onSelectLabel={setTypesSort}
      tabsArr={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      searchRequest={searchValue}
    >
      {data && searchTabsArr[activeTab].content(data[searchTabsArr[activeTab].key].values)}
      {/*{searchTabsArr.map(({ content }, key) => {*/}
      {/*  return <React.Fragment key={key}>{activeTab === key && content}</React.Fragment>;*/}
      {/*})}*/}
    </PageTemplate>
  );
};

export default Search;
