import React from 'react';
import Svg from '../../../common/SVG';

const ProfileSidebarUser = ({ userInfo }) => {
  return (
    <div className="profile-sidebar-user">
      {userInfo.map(
        ({ icon, label, value }) =>
          value && (
            <div key={label} className="profile-sidebar-user-block">
              <Svg name={icon} />

              <div className="profile-sidebar-user-block__inner">
                <div className="profile-sidebar-user-block__label">{label}</div>
                <div className="profile-sidebar-user-block__value">{value}</div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default ProfileSidebarUser;
