import React, { useState } from 'react';
import cx from 'classnames';
import Svg from '../../../common/SVG';
import ModalWrapper from '../../Modals/ModalWrapper';
import SwipeWrapper from '../../../common/SwipeWrapper/SwipeWrapper';
import { nav } from '../../../common/PageTemplate/articleTypesFilter';
import { SwiperSlide } from 'swiper/react';
import TagLabel from '../../../common/Tag/TagLabel';
import Button from '../../../common/Button/Button';
import Badge from '../../../common/Badge/Badge';
import { includes } from 'lodash';

const MobileModalPageDropdown = ({
  types,
  selectedTypes,
  selectedLabel,
  onSelectLabel,
  onSelectTypes,
}) => {
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isSelected, setIsSelected] = useState(true);

  const handleEditClick = () => {
    setIsModalEditVisible(!isModalEditVisible);
  };

  const handleReset = () => {
    onSelectLabel('Newest');
    onSelectTypes('del');
  };

  return (
    <>
      <div
        className={cx(
          'mobile-modal__dropdown-btn',
          isModalEditVisible && 'active',
          isSelected && 'selected'
        )}
        onClick={handleEditClick}
      >
        <Svg name="filter-sorting" />
        {isSelected && <Badge color="violet" label="4" size="xs" isIcon={false} />}
      </div>

      <ModalWrapper
        isModalVisible={isModalEditVisible}
        handleClick={handleEditClick}
        className="mobile-modal__dropdown-filter-modal"
        modalWidth="100%"
      >
        <div className="profile-filter-modal">
          <div className="profile-filter-modal-header">Formats</div>
          <div className="profile-filter-modal-content">
            <SwipeWrapper
              spaceBetween={12}
              className="profile-filter-modal-content__tags"
              navigation={false}
            >
              {types.map(({ name }, index) => (
                <SwiperSlide key={index} onClick={() => onSelectTypes(name)}>
                  <TagLabel
                    key={index}
                    className={cx('profile-filter', {
                      active: includes(selectedTypes, name),
                    })}
                    text={name}
                    size="md"
                  />
                </SwiperSlide>
              ))}
            </SwipeWrapper>

            <SwipeWrapper
              className="profile-filter-modal-content__time"
              navigation={false}
              centeredSlides={true}
              freeMode={false}
              spaceBetween={100}
            >
              {nav.map((value, index) => (
                <SwiperSlide
                  onClick={() => onSelectLabel(value)}
                  key={index}
                  className={cx('profile-filter-modal-time-item', {
                    active: includes(selectedLabel, value),
                  })}
                >
                  <span>{value}</span>
                </SwiperSlide>
              ))}
            </SwipeWrapper>
          </div>

          <div className="profile-filter-modal-footer">
            <Button onClick={handleReset} type="outline-white" size="md" text="Reset" />
            <Button
              onClick={() => setIsModalEditVisible(false)}
              type="primary"
              size="md"
              text="Apply"
            />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default MobileModalPageDropdown;
