import React from 'react';
import ArticlesItem from '../../components/Articles/ArticlesItem';
import { useMediaQuery } from '../Dashboard/ResizeArticleInPage';

const SearchFeedPublication = ({ values }) => {
  const articleSize = useMediaQuery('(min-width: 1920px)');

  return (
    <div className="cards-grid">
      {values.map((item) => {
        return (
          <ArticlesItem
            {...item}
            key={item.id}
            related
            size={articleSize ? 'lg' : 'md'}
            direction="col"
          />
        );
      })}
    </div>
  );
};

export default SearchFeedPublication;
