import React from 'react';
import SidebarAuthNav from './SidebarAuthNav';
import { Link } from 'react-router-dom';

const SidebarAuthMobile = ({ handleSidebar }) => {
  return (
    <div className="sidebar auth mobile">
      <Link className="sidebar-logo" to="/dashboard">
        <img className="sidebar-logo__img" src="/images/logo.png" alt="logo" />
      </Link>

      <SidebarAuthNav handleSidebar={handleSidebar} />
    </div>
  );
};

export default SidebarAuthMobile;
