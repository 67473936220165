import React from 'react';
import Label from '../Label/Label';
import PhoneInput from 'react-phone-input-2';

const InputPhone = (props) => {
  const { labelText, id, error, prompt, social } = props;

  return (
    <div className={`field ${social ? 'social' : ''}`}>
      {labelText && <Label id={id} text={labelText} />}

      <div className="field__wrapper">
        <PhoneInput country="ua" inputClass="ant-input field__input" />
      </div>

      {!error && prompt && <div className="field__prompt">{prompt}</div>}
      {error && <div className="field__error">{error}</div>}
    </div>
  );
};

export default InputPhone;
