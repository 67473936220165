import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { session } from '../helper';
import typePolicies from './typePolicy';

const cache = new InMemoryCache({
  typePolicies,
});

const connectionParams = () => {
  const token = session.get('token');
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
  return {};
};

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...connectionParams(),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(from([uploadLink, httpLink])),
  cache,
});

export default client;
