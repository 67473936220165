import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { useMutation, useReactiveVar } from '@apollo/client';
import { createSavedMutationGql, deleteSavedMutationGql } from '../../gql/mutation';
import { userQueryGql } from '../../gql/query';
import { setIsUserId, setSaveActive } from '../../gql/cache';
import { userSavedsFragmentGql } from '../../gql/fragment';
import Svg from '../SVG';

const Bookmark = ({ label, className, disabled, articleId }) => {
  const active = useReactiveVar(setSaveActive);
  const [createSaved, { client }] = useMutation(createSavedMutationGql, {
    onCompleted: ({ createSaved: { saved } }) => setSaveActive(saved),
    refetchQueries: [
      {
        query: userQueryGql,
        variables: {
          id: setIsUserId(),
        },
      },
    ],
  });
  const [deleteSaved] = useMutation(deleteSavedMutationGql, {
    onCompleted: () => setSaveActive(null),
    update(
      cache,
      {
        data: {
          deleteSaved: { saved },
        },
      }
    ) {
      cache.modify({
        fields: {
          articles(existingArticles = [], { readField }) {
            return existingArticles.filter(
              (commentRef) => saved.article.id !== readField('id', commentRef)
            );
          },
        },
      });
    },
    refetchQueries: [
      {
        query: userQueryGql,
        variables: {
          id: setIsUserId(),
        },
      },
    ],
  });

  useEffect(() => {
    const dataSaved = client.readFragment({
      id: `UsersPermissionsUser:${setIsUserId()}`,
      fragment: userSavedsFragmentGql,
    });

    const active = _.find(
      _.filter(dataSaved.saveds, ({ article }) => !!article),
      ({ article }) => article.id === articleId
    );

    setSaveActive(active);

    return () => setSaveActive(null);
  }, []);

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!!active) {
        deleteSaved({
          variables: {
            input: {
              where: {
                id: active.id,
              },
            },
          },
        });
      } else {
        createSaved({
          variables: {
            input: {
              data: {
                article: articleId,
                user: setIsUserId(),
              },
            },
          },
        });
      }
    },
    [active, articleId, createSaved, deleteSaved]
  );

  const bookmarkClass = cx(
    'bookmark-container',
    className,
    !!active && 'active',
    disabled && 'disabled'
  );

  // const bookmarkIconClass = cx('bookmark bookmark-icon-bg', { active: !!active });

  return (
    <div className={bookmarkClass} onClick={handleClick}>
      {/*<span className={bookmarkIconClass} />*/}
      {!!active ? (
        <Svg className="bookmark-filled" name="bookmark-filled" />
      ) : (
        <Svg className="bookmark" name="bookmark" />
      )}
      {label && <span>Save</span>}
    </div>
  );
};
export default Bookmark;
