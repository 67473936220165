import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import Avatar from '../../common/Avatar/Avatar';
import { useHistory } from 'react-router-dom';
import { setIsUserId } from '../../gql/cache';
import { useReactiveVar } from '@apollo/client';
import { setIsAuthorized } from '../../gql/cache';

const ArticleUser = ({ authorLogo, author, authorId }) => {
  const [firstName, lastName] = _.map(_.words(author), _.capitalize);
  const { push } = useHistory();
  const authorized = useReactiveVar(setIsAuthorized);

  const goToProfile = useMemo(() => {
    if (!authorized) {
      return '/login';
    }
    if (!authorId) {
      return '/profile';
    }
    if (setIsUserId() === authorId) {
      return '/profile';
    }
    return `/profile/${authorId}`;
  }, [authorId, authorized]);

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      push(goToProfile);
    },
    [goToProfile, push]
  );

  return (
    <div onClick={onClick} className="article-item-user">
      <Avatar avatarImg={authorLogo} author={author} size="lg" />
      <div className="user-name">
        <span>
          {firstName} {lastName}
        </span>
      </div>
    </div>
  );
};
export default ArticleUser;
