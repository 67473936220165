import React from 'react';
import Button from '../../common/Button/Button';
import People from '../../common/People/People';
import LoginWrapper from './LoginWrapper';

const organizationsList = [
  {
    link: '/',
    logo: 'logo-company-1.png',
    name: 'Hexagon',
    users: ['avatar-person-1.png', 'avatar-person-2.png', 'avatar-person-3.png'],
    usersAmount: '28',
  },
  {
    link: '/',
    logo: 'logo-company-2.png',
    name: 'Coingaming',
    users: ['avatar-person-1.png', 'avatar-person-4.png', 'avatar-person-5.png'],
    usersAmount: '92',
  },
];

const AvaliableOrganizations = () => {
  return (
    <LoginWrapper>
      <div className="login__title">
        Available organizations for
        <div className="login__email">a@hexagon.agency</div>
      </div>

      {organizationsList.map(({ link, logo, name, users, usersAmount }, index) => (
        <div key={index} className="login-organization">
          <img src={`/images/${logo}`} className="login-organization__logo" />
          <div className="login-organization__inner">
            <div className="login-organization__title">{name}</div>
            <People amount={20} people={[]} />
          </div>

          <Button text="Open" type="text" />
        </div>
      ))}
    </LoginWrapper>
  );
};

export default AvaliableOrganizations;
