import React, { useEffect, useRef } from 'react';
import cx from 'classnames';

const defaultStyle = {
  display: 'block',
  overflow: 'hidden',
  resize: 'none',
  width: '100%',
  backgroundColor: 'mediumSpringGreen',
};

const Textarea = ({ value, placeholder, className, onChange, disabled }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 'px';
  }, [value]);

  const textareaClassName = cx('textarea', className);

  return (
    <textarea
      disabled={disabled}
      className={textareaClassName}
      ref={textareaRef}
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default Textarea;
