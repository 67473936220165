import React from 'react';
import { Slide, ToastContainer as ToastContainerComponent } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import cx from 'classnames';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const ToastContainer = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const alertClassName = cx('alert', { mobile: isMobile });
  return (
    <ToastContainerComponent
      className={alertClassName}
      hideProgressBar
      transition={Slide}
      closeButton={false}
      draggable={false}
      autoClose={7000}
      limit={1}
    />
  );
};

export default ToastContainer;
