import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import { instanceFetch } from '../../helper/instanceFetch';
import { session } from '../../helper';
import { useLocation, useHistory } from 'react-router-dom';
import client from '../../gql/client';
import { userQueryGql, meQueryGql } from '../../gql/query';
import { setIsAuthorized, setIsUserId } from '../../gql/cache';

const Oauth = () => {
  const [percent, setPercent] = useState(0);
  const location = useLocation();
  const { push } = useHistory();

  useEffect(() => {
    setPercent(20);
    instanceFetch
      .get(`/auth/google/callback${location.search}`)
      .then(({ jwt, user }) => {
        session.set('token', jwt);
        setPercent(40);
        return user;
      })
      .then((user) => {
        setPercent(60);
        return client.query({ query: meQueryGql });
      })
      .then(({ data: { me } }) => {
        setPercent(100);
        setIsUserId(me.id);
        return client.query({ query: userQueryGql, variables: { id: me.id } });
      })
      .then(({ data: { user } }) => {
        setIsAuthorized(true);
        if (user.onboardingPassed) {
          push('/dashboard');
        } else {
          push('/onboarding');
        }
      })
      .catch(console.log);
  }, []);

  return <Progress type="circle" percent={percent} />;
};

export default Oauth;
