import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import ModalWrapper from '../../components/Modals/ModalWrapper';
import TabLabel from '../../common/Tab/TabLabel';
import cx from 'classnames';
import FeedSidebarTopics from './FeedSidebarTopics';
import FeedSidebarPublishers from './FeedSidebarPublishers';

const sideBarFeedTabs = [
  {
    label: 'Topics',
    content: <FeedSidebarTopics />,
  },
  {
    label: 'Publishers',
    content: <FeedSidebarPublishers />,
  },
];

const RightSidebarFeed = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [active, setActive] = useState(1);

  const handleClick = () => {
    setIsModalEditVisible(!isModalEditVisible);
  };

  if (isMobile) {
    return (
      <>
        <div
          className={cx('mobile-modal__dropdown-btn text', isModalEditVisible && 'active')}
          onClick={handleClick}
        >
          Interests
        </div>
        <ModalWrapper
          isModalVisible={isModalEditVisible}
          handleClick={handleClick}
          modalWidth="100%"
          className="right-sidebar-feed-page-modal modal-with-sticky-header"
        >
          <div className="ant-modal-body-container modal__profile-container">
            <div className="ant-modal-sticky-header">Interests</div>

            <div className="mobile-tab-bar__tab-items tab-label-list">
              {sideBarFeedTabs.map(({ label }, key) => (
                <TabLabel
                  transparent
                  key={label}
                  label={label}
                  onClick={() => setActive(key)}
                  active={active === key}
                />
              ))}
            </div>

            <div className="ant-modal-body-content">
              {sideBarFeedTabs.map(({ content }, key) => {
                return <React.Fragment key={key}>{active === key && content}</React.Fragment>;
              })}
            </div>
          </div>
        </ModalWrapper>
      </>
    );
  }

  return (
    <div className="right-sidebar">
      <div className="right-sidebar__item">
        <div className="right-sidebar__header">
          <span>Topics</span>
          {/*TODO: Feed filtration*/}
          {/*<span className="right-sidebar-filter-btn">click to filter</span>*/}
        </div>

        <div className="right-sidebar__items">
          <FeedSidebarTopics />
        </div>
      </div>
      <div className="right-sidebar__item">
        <div className="right-sidebar__header">Publishers</div>
        <div className="right-sidebar__items">
          <FeedSidebarPublishers />
        </div>
      </div>
    </div>
  );
};

export default RightSidebarFeed;
