import React, { useMemo, useCallback } from 'react';
import cx from 'classnames';
import Svg from '../SVG';
import Badge from '../Badge/Badge';
import Checkbox from '../Checkbox/Checkbox';
import TagLabel from '../Tag/TagLabel';
import Avatar from '../Avatar/Avatar';
import { useMutation } from '@apollo/client';
import { updateUserMutationGql } from '../../gql/mutation';
import { followingsFollowersFragmentGql } from '../../gql/fragment';
import { setIsUserId } from '../../gql/cache';
import _ from 'lodash';

const TabRightFollow = ({ isBtnFollowLabel, active, onClickFollow }) => {
  // const [updateFollowings, { client }] = useMutation(updateUserMutationGql);
  // const { followings } = client.readFragment({
  //   id: `UsersPermissionsUser:${setIsUserId()}`,
  //   fragment: followingsFollowersFragmentGql,
  // });

  // const active = useMemo(() => {
  //   return _.some(followings, { id: userId });
  // }, [userId, followings]);

  // const onClick = useCallback(
  //   (e) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     if (active) {
  //       return updateFollowings({
  //         variables: {
  //           input: {
  //             where: {
  //               id: setIsUserId(),
  //             },
  //             data: {
  //               followings: _.without(_.map(followings, 'id'), userId),
  //             },
  //           },
  //         },
  //       });
  //     }
  //     return updateFollowings({
  //       variables: {
  //         input: {
  //           where: {
  //             id: setIsUserId(),
  //           },
  //           data: {
  //             followings: [..._.map(followings, 'id'), userId],
  //           },
  //         },
  //       },
  //     });
  //   },
  //   [active, userId, followings, updateFollowings]
  // );

  const tabClassName = cx('tab__right follow-btn', active && 'active');

  return (
    <div
      className={tabClassName}
      onClick={(e) => {
        e.stopPropagation();
        onClickFollow();
      }}
    >
      {isBtnFollowLabel &&
        (active ? (
          <div className="tab__link">Following</div>
        ) : (
          <div className="tab__link">Follow</div>
        ))}

      <Svg name={active ? 'checkmark' : 'plus'} />
    </div>
  );
};

const TabRightBadge = ({ badge }) => {
  return (
    <div className="tab__right">{badge && <Badge label={badge} size="xs" isIcon={false} />}</div>
  );
};

const TabRightCheckbox = ({ active }) => {
  return (
    <div className="tab__right">
      <Checkbox checked={active} />
    </div>
  );
};

const TabRightCategory = ({ formatText, formatIcon }) => {
  return (
    <div className="tab__right">
      <TagLabel text={formatText} icon={formatIcon} size="xs" pointerEvents />
    </div>
  );
};

const Tab = (props) => {
  const colorsObj = {
    whitePurple: 'white-purple', // white - bg, purple- hover
    whiteGray: 'white-gray',
    transparentNone: 'transparent-none',
    transparentGray: 'transparent-gray',
  };

  const {
    formatText,
    formatIcon,
    typeRight,
    textLeft,
    iconLeft,
    imageLeft,
    iconRight,
    avatar,
    avatarImg,
    active,
    disabled,
    onClick,
    onClickFollow,
    onClickLeft,
    className,
    badge = null,
    block,
    isBtnFollowLabel = true, // render follow label in btn
    color,
    isOpen,
    id,
  } = props;

  const tabRight = {
    follow: (
      <TabRightFollow
        iconRight={iconRight}
        isBtnFollowLabel={isBtnFollowLabel}
        userId={id}
        active={active}
        onClickFollow={onClickFollow}
      />
    ),
    badge: <TabRightBadge badge={badge} active={active} />,
    checkbox: <TabRightCheckbox active={active} />,
    category: <TabRightCategory formatText={formatText} formatIcon={formatIcon} active={active} />,
    arrow: <Svg name={isOpen ? 'arrow-up' : 'arrow-down'} />,
  };

  const tabClassName = cx(
    'tab',
    active && 'active',
    disabled && 'disabled',
    block && 'block',
    className,
    colorsObj[color]
  );

  return (
    <button type="button" className={tabClassName} onClick={onClick}>
      <div className="tab__inner">
        <div className="tab__left">
          {avatar && (
            <Avatar
              className="people-item__user"
              avatarImg={avatarImg}
              author={textLeft}
              onClick={onClickLeft}
            />
          )}
          {iconLeft && <Svg name={iconLeft} />}
          {imageLeft && (
            <img className="tab-image" src={`/icons/${imageLeft}.png`} alt={imageLeft} />
          )}
          <div className="tab__text" onClick={onClickLeft}>
            {textLeft}
          </div>
        </div>
        {tabRight[typeRight]}
      </div>
    </button>
  );
};

export default Tab;
