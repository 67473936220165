import React, { useEffect, useState } from 'react';
import ArticlesItem from './ArticlesItem';
import Masonry from 'react-masonry-css';
import ModalWrapper from '../Modals/ModalWrapper';
import ResizeArticle from './ResizeArticle';
import { useReactiveVar } from '@apollo/client';
import { setArticlesArguments, setIsAuthorized, setIsMobile } from '../../gql/cache';
import cx from 'classnames';
import { useLazyQuery } from '@apollo/client';
import { articlesQueryShortFormGql } from '../../gql/query';
import ArticleModalWrapper from '../Modals/ArticleModalWrapper';

const MasonryArticlesList = ({ isFeedPage, sort }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const authorized = useReactiveVar(setIsAuthorized);
  const argumentsArticles = useReactiveVar(setArticlesArguments);

  const [articleId, setArticleId] = useState('');
  const [getArticles, { data }] = useLazyQuery(articlesQueryShortFormGql);

  const handleClick = () => {
    setArticleId('');
  };

  ResizeArticle(isFeedPage);

  const masonryClass = cx(
    'masonry-articles-list',
    { 'feed-page': isFeedPage },
    !isFeedPage && (authorized ? 'authorized' : 'unauthorized')
  );

  useEffect(() => {
    getArticles({
      variables: {
        sort,
        where: {
          draft: false,
          ...argumentsArticles,
        },
      },
    });
  }, [argumentsArticles, getArticles, sort]);

  if (!data?.articles) return null;

  return (
    <>
      <Masonry breakpointCols={2} className={masonryClass} columnClassName="masonry-articles-col">
        {data?.articles.map((item) => (
          <ArticlesItem
            key={item.id}
            {...item}
            classic
            size={data?.articles.length === 1 && 'md'}
            handleClick={setArticleId}
          />
        ))}
      </Masonry>

      <ModalWrapper isModalVisible={Boolean(articleId)} handleClick={handleClick} modalWidth="100%">
        <ArticleModalWrapper articleId={articleId} />
      </ModalWrapper>
    </>
  );
};

export default MasonryArticlesList;
