import { useCallback, useMemo, useEffect } from 'react';
import { setAuthorFeedFilter, setTopicsFeedFilter } from '../../gql/cache';
import _ from 'lodash';
import { useReactiveVar } from '@apollo/client';

export const useFeedFilter = () => {
  const topics = useReactiveVar(setTopicsFeedFilter);
  const authors = useReactiveVar(setAuthorFeedFilter);

  const clearAll = useCallback(() => {
    setTopicsFeedFilter([]);
    setAuthorFeedFilter([]);
  }, []);

  const list = useMemo(() => {
    const t = _.map(topics, ({ name }) => ({ name, type: 'topic' }));
    const a = _.map(authors, ({ name }) => ({ name, type: 'author' }));

    return [...a, ...t];
  }, [topics, authors]);

  const setTopics = useCallback(
    (item) => {
      if (_.some(topics, ['name', item.name])) {
        setTopicsFeedFilter(_.filter(topics, ({ name }) => name !== item.name));
      } else {
        setTopicsFeedFilter([...topics, item]);
      }
    },
    [topics]
  );

  const setAuthor = useCallback(
    (item) => {
      if (_.some(authors, ['name', item.name])) {
        setAuthorFeedFilter(_.filter(authors, ({ name }) => name !== item.name));
      } else {
        setAuthorFeedFilter([...authors, item]);
      }
    },
    [authors]
  );

  const removeFilter = (item) => {
    if (item.type === 'author') {
      setAuthor(item);
    } else {
      setTopics(item);
    }
  };

  useEffect(() => {
    return clearAll;
  }, []);

  return {
    clearAll,
    list,
    setTopics,
    setAuthor,
    removeFilter,
    interestsList: _.map(topics, 'id'),
    authorList: _.map(authors, 'id'),
  };
};
