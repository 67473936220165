import React from 'react';
import ArticlesItem from '../../components/Articles/ArticlesItem';
import Masonry from 'react-masonry-css';
import useResizeArticleProfile from './useResizeArticleProfile';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const ProfileArticles = ({ articles, masonry }) => {
  const isMobile = useReactiveVar(setIsMobile);
  useResizeArticleProfile(!!articles.length);

  return (
    <Masonry
      breakpointCols={2}
      className="masonry-articles-list"
      columnClassName="masonry-articles-col"
    >
      {articles.map((item) => (
        <ArticlesItem
          key={item.id}
          {...item}
          size={(masonry || isMobile) && 'xs'}
          classic
          personal
        />
      ))}
    </Masonry>
  );
};

export default ProfileArticles;
