import { makeVar } from '@apollo/client';
import { isMobileDevice } from '../helper/helper';

export const setIsAuthorized = makeVar(false);

export const setIsMobile = makeVar(isMobileDevice());

export const setIsUserId = makeVar('');

export const setArticlesArguments = makeVar({});

export const setOpenModal = makeVar(false);

export const setSaveActive = makeVar(null);

export const setAuthorFeedFilter = makeVar([]);

export const setTopicsFeedFilter = makeVar([]);
