import React from 'react';
import RelatedArticles from '../Articles/RelatedArticles/RelatedArticles';
import ArticleModalFooter from '../Articles/ArticleModalFooter';
import Button from '../../common/Button/Button';
import ActionsCourse from './ActionsCourse';
import ActionsArticle from './ActionsArticle';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

const ArticleModal = ({
  children,
  course = false,
  lessons,
  usersStarted,
  comments,
  articleId,
  clapsNumber,
  activeClap,
  author,
  interests,
  slug,
  likes,
}) => {
  const isMobile = useReactiveVar(setIsMobile);
  const history = useHistory();

  const ArticleModalClassName = cx('article-modal', { mobile: isMobile });

  return (
    <div className={ArticleModalClassName}>
      <div className="article-modal__container">
        <div className="article-modal__header">
          <Button
            onClick={() => history.goBack()}
            iconLeft="arrow-left"
            text="Previous page"
            type="text"
            size="md"
          />
        </div>
        <div className="article-modal__content">
          <div className="article-modal__body">
            {children}
            {course && <ActionsCourse lessons={lessons} usersStarted={usersStarted} />}
            <div className="article-modal__footer">
              <ArticleModalFooter
                author={author}
                interests={interests}
                comments={comments}
                articleId={articleId}
                clapsNumber={clapsNumber}
                activeClap={activeClap}
                slug={slug}
              />
              <RelatedArticles course={course} />
            </div>
          </div>

          {!isMobile && (
            <div className="actions-sticky">
              <div className="article-modal__actions">
                <div className="actions-article">
                  <ActionsArticle
                    comments={comments}
                    articleId={articleId}
                    activeClap={activeClap}
                    slug={slug}
                    likes={likes}
                  />
                </div>
                {course && <ActionsCourse lessons={lessons} usersStarted={usersStarted} />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticleModal;
