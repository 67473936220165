import React, { useCallback } from 'react';
import cx from 'classnames';
import { useMediaQuery } from '../../pages/Dashboard/ResizeArticleInPage';
import { getImageURL } from '../../helper';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const KnowledgeBaseCard = ({ name, description, coverImage, id, className, listView }) => {
  let searchCardSize = useMediaQuery('(min-width: 1920px)');
  const { push } = useHistory();
  const url = coverImage ? coverImage.url : '';

  const cardClassName = cx(
    'knowledge-base-card',
    className,
    !listView && (searchCardSize ? 'lg' : 'md')
  );

  const onClick = useCallback((event) => {
    event.preventDefault();
    push(`/knowledge-base-company-folder/${id}`);
  }, []);

  return (
    <div className={cardClassName} onClick={onClick}>
      <div
        style={{ backgroundImage: `url(${getImageURL(url)})` }}
        className="knowledge-base-card__bg"
      />

      <div className="knowledge-base-card__content">
        <div className="knowledge-base-card__title">{name}</div>
        <div className="knowledge-base-card__text">{description}</div>
      </div>
    </div>
  );
};

const KnowledgeBaseCompanyList = ({ list, name, viewListBtn, viewGridBtn }) => {
  const isMobile = useReactiveVar(setIsMobile);
  if (!list.length)
    return (
      <div className="knowledge-base__empty">{_.capitalize(name)} hasn’t added any folders yet</div>
    );

  const cardViewClass = cx(
    { 'cards-grid': viewGridBtn || isMobile },
    { 'cards-grid-list-view': viewListBtn && !isMobile }
  );
  return (
    <div className={cardViewClass}>
      {list.map((item) => (
        <KnowledgeBaseCard listView {...item} key={item.id} />
      ))}
    </div>
  );
};

export default KnowledgeBaseCompanyList;
