import { gql } from '@apollo/client';
import {
  USER,
  LIKE,
  COMMENT,
  LESSON,
  USER_ALL_PARAMS,
  INTERESTS,
  ARTICLE_SHORT_FORMAT,
  COURSE,
  KNOWLEDGE_BASES,
  ORGANIZATION,
} from './constQuery';

export const meQueryGql = gql`
  query me {
    me {
      id
      username
      email
      role {
        name
        type
      }
    }
  }
`;

export const articleQueryGql = gql`
  ${USER}
  ${COMMENT}
  ${LIKE}
  query getArticle($id: ID!) {
    article(id: $id) {
      id
      slug
      headline
      author {
        ...user
      }
      contents
      interests {
        interestTitle
      }
      comments {
        ...comment
      }
      likes {
        ...like
      }
    }
  }
`;

export const articleDraftQueryGql = gql`
  ${USER}
  query getArticleDraft($id: ID!) {
    article(id: $id) {
      id
      subtitle
      shortLead
      headline
      backgroundHEX
      contents
      slug
      author {
        ...user
      }
      coverImage {
        id
        url
      }
      department {
        name
      }
      interests {
        id
      }
    }
  }
`;

export const articlesQueryShortFormGql = gql`
  ${USER}
  ${COMMENT}
  ${LIKE}
  ${ARTICLE_SHORT_FORMAT}
  query getArticlesShortForm($limit: Int, $where: JSON, $sort: String) {
    articles(limit: $limit, where: $where, sort: $sort) {
      ...article
    }
  }
`;

export const knowledgeBasesQueryGql = gql`
  ${USER}
  ${KNOWLEDGE_BASES}
  query getKnowledgeBases($limit: Int, $where: JSON) {
    knowledgeBases(limit: $limit, where: $where) {
      ...knowledgeBases
    }
  }
`;

export const knowledgeBaseQueryGql = gql`
  ${USER}
  ${COMMENT}
  ${LIKE}
  ${ARTICLE_SHORT_FORMAT}
  query getKnowledgeBase($id: ID!) {
    knowledgeBase(id: $id) {
      id
      coverImage {
        url
      }
      name
      description
      articles {
        ...article
      }
    }
  }
`;

export const organizationsQueryGql = gql`
  ${ORGANIZATION}
  ${USER}
  ${KNOWLEDGE_BASES}
  query getOrganizations($limit: Int, $where: JSON) {
    organizations(limit: $limit, where: $where) {
      ...organization
    }
  }
`;

export const organizationQueryGql = gql`
  ${ORGANIZATION}
  ${USER}
  ${KNOWLEDGE_BASES}
  query getOrganization($id: ID!) {
    organization(id: $id) {
      ...organization
    }
  }
`;

export const eventsQueryGql = gql`
  ${USER}
  query getEvents($limit: Int, $where: JSON) {
    events(limit: $limit, where: $where) {
      id
      name
      date
      description
      author {
        ...user
      }
      type
      auditory {
        ...user
      }
    }
  }
`;

export const userQueryGql = gql`
  ${USER_ALL_PARAMS}
  ${INTERESTS}
  query getUser($id: ID!) {
    user(id: $id) {
      ...user
    }
  }
`;

export const departmentsQueryGql = gql`
  query departments {
    departments {
      id
      name
    }
  }
`;

export const interestsQueryGql = gql`
  ${INTERESTS}
  query interests {
    interests {
      ...interest
    }
  }
`;

export const articleTypesQueryGql = gql`
  query articleTypes {
    articleTypes {
      slug
      type
      id
    }
  }
`;

export const coursesQueryGql = gql`
  ${USER}
  ${COMMENT}
  ${LIKE}
  ${LESSON}
  ${COURSE}
  query courses($where: JSON, $sort: String) {
    courses(where: $where, sort: $sort) {
      ...course
    }
  }
`;

export const courseQueryGql = gql`
  ${USER}
  ${COMMENT}
  ${LIKE}
  ${LESSON}
  ${COURSE}
  query course($id: ID!) {
    course(id: $id) {
      ...course
    }
  }
`;

export const lessonQueryQgl = gql`
  ${USER}
  ${LESSON}
  query getLesson($id: ID!) {
    lesson(id: $id) {
      ...lesson
    }
  }
`;

export const usersQueryGql = gql`
  ${USER}
  query getUsers($where: JSON, $limit: Int) {
    users(where: $where, limit: $limit) {
      ...user
    }
  }
`;

export const coverImagePatternsQueryGql = gql`
  query coverImagePatterns {
    coverImagePatterns {
      id
      coverImage {
        id
        url
        caption
      }
    }
  }
`;

export const aggregateConnectionGql = gql`
  ${USER}
  ${COMMENT}
  ${LIKE}
  ${LESSON}
  ${ARTICLE_SHORT_FORMAT}
  ${COURSE}
  query articlesConnection($input: String!, $sort: String, $limit: Int) {
    articles: articlesConnection(where: { headline_contains: $input }, limit: $limit, sort: $sort) {
      values {
        ...article
      }
      aggregate {
        count
        totalCount
      }
    }
    courses: coursesConnection(where: { subtitle_contains: $input }, limit: $limit, sort: $sort) {
      values {
        ...course
      }
      aggregate {
        count
        totalCount
      }
    }
    events: eventsConnection(where: { name_contains: $input }, limit: $limit, sort: $sort) {
      values {
        id
        name
      }
      aggregate {
        count
        totalCount
      }
    }
    interests: interestsConnection(
      where: { interestTitle_contains: $input }
      limit: $limit
      sort: $sort
    ) {
      values {
        id
        interestTitle
      }
      aggregate {
        count
        totalCount
      }
    }
    knowledge: knowledgeBasesConnection(
      where: { name_contains: $input }
      limit: $limit
      sort: $sort
    ) {
      values {
        id
        name
      }
      aggregate {
        count
        totalCount
      }
    }
    lessons: lessonsConnection(where: { headline_contains: $input }, limit: $limit, sort: $sort) {
      values {
        id
        headline
      }
      aggregate {
        count
        totalCount
      }
    }
    users: usersConnection(where: { username_contains: $input }, limit: $limit, sort: $sort) {
      values {
        ...user
      }
      aggregate {
        count
        totalCount
      }
    }
  }
`;

export const aggregateConnectionUserProfileGql = gql`
  ${USER}
  ${COMMENT}
  ${LIKE}
  ${LESSON}
  ${ARTICLE_SHORT_FORMAT}
  ${COURSE}
  query articlesConnection($author: ID!, $articleType: [String], $sort: String) {
    publications: articlesConnection(
      where: { draft: false, author: $author, articleType: $articleType }
      limit: 10
      sort: $sort
    ) {
      values {
        ...article
      }
      aggregate {
        count
        totalCount
      }
    }
    claps: articlesConnection(
      where: { draft: false, likes: { user: $author }, articleType: $articleType }
      limit: 10
    ) {
      values {
        ...article
      }
      aggregate {
        count
        totalCount
      }
    }
    courses: coursesConnection(where: { draft: false, usersPassed: $author }, limit: 10) {
      values {
        ...course
      }
      aggregate {
        count
        totalCount
      }
    }
  }
`;

export const likesGql = gql`
  ${LIKE}
  ${USER}
  query likes($where: JSON) {
    likes(where: $where) {
      ...like
    }
  }
`;

export const likeGql = gql`
  ${LIKE}
  ${USER}
  query like($id: ID!) {
    like(id: $id) {
      ...like
    }
  }
`;
