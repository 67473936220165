import { memo } from 'react';
// import { Redirect } from 'react-router-dom';
import { excludeRoute } from '../../constants';
import { useReactiveVar } from '@apollo/client';
import { setIsAuthorized } from '../../gql/cache';
import { Redirect } from 'react-router-dom';

const Guard = (props) => {
  const authorized = useReactiveVar(setIsAuthorized);

  const {
    location: { pathname },
    children,
  } = props;

  if (excludeRoute.includes(pathname) || authorized) {
    return children;
  }

  if (!authorized) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return null;

  // return (
  //   <Redirect
  //     to={{
  //       pathname: '/',
  //       state: { from: props.location },
  //     }}
  //   />
  // );
};

export default memo(Guard);
