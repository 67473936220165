import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './assets/styles/styles.scss';
import AuthProvider from './components/Guard/AuthContext';
import AppLayout from './AppLayout';
import ToastContainer from './common/Toast/ToastContainer';

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppLayout />
        <ToastContainer />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
