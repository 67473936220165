import React from 'react';
import { useQuery } from '@apollo/client';
import { articleQueryGql } from '../../gql/query';
import ArticleModal from './ArticleModal';
import { createArticlesElement } from '../../helper';

const ArticleModalWrapper = ({ articleId }) => {
  const { data } = useQuery(articleQueryGql, {
    variables: {
      id: articleId,
    },
  });

  if (!data) return null;

  return (
    <ArticleModal
      comments={data.article.comments}
      articleId={data.article.id}
      author={data.article.author}
      interests={data.article.interests}
      slug={data.article.slug}
    >
      <h1 className="l-1">{data.article.headline}</h1>
      {data.article.contents &&
        data.article.contents.map((item, index) => createArticlesElement(item, index))}
    </ArticleModal>
  );
};

export default ArticleModalWrapper;
