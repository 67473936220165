import React from 'react';
import cx from 'classnames';
import Button from '../Button/Button';
import { useMediaQuery } from '../../pages/Dashboard/ResizeArticleInPage';
import { getImageURL } from '../../helper';

const SearchCard = ({ item, className }) => {
  let searchCardSize = useMediaQuery('(min-width: 1920px)');

  const cardClassName = cx('search-card', className, searchCardSize ? 'lg' : 'md');
  const { coverImage, avatar, username: name, jobTitle: position } = item;
  const bg = coverImage ? coverImage.url : '';
  const av = avatar ? avatar.url : '';

  return (
    <div className={cardClassName}>
      <div style={{ backgroundImage: `url(${getImageURL(bg)})` }} className="search-card__bg" />

      <div className="search-card__content">
        <div className="search-card-avatar">
          <div
            style={{ backgroundImage: `url(${getImageURL(av)})` }}
            className="search-card-avatar__img"
          />
        </div>

        <div className="search-card__inner">
          <div className="search-card__name">{name}</div>
          <div className="search-card__position">{position}</div>

          <Button iconLeft="plus" type="secondary" text="Follow" />
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
