import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router';
import ArticleModalCard from '../../components/Articles/ArticleModalCard';
import CourseModal from '../../components/Modals/CourseModal';
import Timeline from '../../components/Timeline/Timeline';
import { useReactiveVar, useQuery, useMutation } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../gql/cache';
import { courseQueryGql, lessonQueryQgl } from '../../gql/query';
import { createArticlesElement } from '../../helper';
import { updateUserMutationGql, updateLessonMutationGql } from '../../gql/mutation';

const MaterialCourse = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const { idCourse, idLesson } = useParams();
  const { goBack, push } = useHistory();
  const [updateCourse] = useMutation(updateUserMutationGql, {
    onCompleted: () => push('/dashboard/your-learnings'),
  });
  const [updateLesson] = useMutation(updateLessonMutationGql, {
    variables: {
      input: {
        where: {
          id: idLesson,
        },
        data: {
          usersPassed: setIsUserId(),
        },
      },
    },
  });

  const { data } = useQuery(lessonQueryQgl, {
    variables: {
      id: idLesson,
    },
  });

  const { data: courseData } = useQuery(courseQueryGql, {
    variables: {
      id: idCourse,
    },
  });

  const usersPassed = useMemo(() => {
    if (data) {
      return _.includes(_.map(data.lesson.usersPassed, 'id'), setIsUserId());
    }
    return [];
  }, [data]);

  const nextLesson = useMemo(() => {
    if (courseData) {
      const list = _.get(courseData, 'course.lessons', []);
      const index = _.findIndex(list, ['id', idLesson]) + 1;
      return {
        list,
        index,
      };
    }
    return {
      list: [],
      index: 1,
    };
  }, [courseData, idLesson]);

  const nextClick = useCallback(async () => {
    const { list, index } = nextLesson;
    if (list[index]) {
      if (!usersPassed) {
        await updateLesson();
      }
      return push(list[index].id);
    }

    if (!courseData) {
      return;
    }
    const coursesStarted = _.remove(_.map(courseData.coursesStarted, 'id'), idCourse);
    const coursesPassed = [..._.map(courseData.coursesPassed, 'id'), idCourse];
    await updateLesson();
    updateCourse({
      variables: {
        input: {
          where: {
            id: setIsUserId(),
          },
          data: {
            coursesStarted,
            coursesPassed,
          },
        },
      },
    });
  }, [nextLesson, courseData, idCourse, updateLesson, updateCourse, usersPassed, push]);

  const timeLineArray = useMemo(() => {
    if (courseData) {
      return _.map(_.get(courseData, 'course.lessons'), ({ id, headline, usersPassed }) => {
        const type =
          id === idLesson
            ? 'progress'
            : _.includes(_.map(usersPassed, 'id'), setIsUserId())
            ? 'done'
            : 'waiting';
        return {
          type,
          title: headline,
        };
      });
    }
    return [];
  }, [courseData, idLesson]);

  if (!data) {
    return null;
  }
  const { contents } = data.lesson;
  const { list, index } = nextLesson;
  const text = list[index] ? 'Next step' : 'Finish';
  return (
    <>
      {isMobile && <Timeline timeLineArray={timeLineArray} swiper />}
      <div className="page__material">
        <div className="dashboard__content">
          <CourseModal nextClick={nextClick} prevClick={goBack} text={text}>
            {contents && contents.map((item, index) => createArticlesElement(item, index))}
            <ArticleModalCard />
            <ArticleModalCard description={false} />
          </CourseModal>
        </div>
      </div>
    </>
  );
};

export default MaterialCourse;
