import React from 'react';
import TagLabel from '../../common/Tag/TagLabel';
import ActionsArticle from '../Modals/ActionsArticle';
import { useReactiveVar } from '@apollo/client';
import { setIsAuthorized, setIsMobile } from '../../gql/cache';
import Avatar from '../../common/Avatar/Avatar';
import ArticleFooterFollowBtn from './ArticleFooterFollowBtn';

const ArticleModalFooter = ({
  author,
  interests,
  comments,
  articleId,
  clapsNumber,
  activeClap,
  slug,
}) => {
  const authorized = useReactiveVar(setIsAuthorized);
  return (
    <>
      <div className="article-footer__user">
        <Avatar author={author.username} avatarImg={author.avatar} />
        <div className="user-info">
          <span className="user-info__title">PUBLISHED BY</span>
          <span className="user-info__name">{author.username}</span>
        </div>
        {authorized && <ArticleFooterFollowBtn author={author} />}
      </div>
      <div className="article-item__tags">
        {interests.map(({ id, interestTitle }) => (
          <TagLabel key={id + interestTitle} className="article" text={interestTitle} size="lg" />
        ))}
      </div>
      <div className="article-item__actions">
        <ActionsArticle
          comments={comments}
          clapsNumber={clapsNumber}
          articleId={articleId}
          activeClap={activeClap}
          slug={slug}
        />
      </div>
    </>
  );
};

export default ArticleModalFooter;
