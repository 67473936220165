import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import WelcomeBlock from '../../common/WelcomeBlock/WelcomeBlock';
import YourLearning from './YourLearning';
import DashboardOnboardingDesktop from './DashboardOnboardingDesktop';
import UpcomingEvents from './UpcomingEvents';
import DashboardOnboardingMobile from './DashboardOnboardingMobile';
import SeeAllArticles from '../../common/PageTemplate/SeeAllArticles';
import { useProgress } from '../../gql/gqlHooks';

const Dashboard = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const { dashboardPassed } = useProgress();

  return (
    <>
      <div className={isMobile ? 'l-5' : 'l-2'}>Dashboard</div>
      {!Boolean(dashboardPassed) && (
        <WelcomeBlock
          type="dashboardPassed"
          title="Welcome to Dashboard"
          description="Here you can find recommended materials, get a quick access to your last saved ones, see your learning progress, and get notified on upcoming events"
        />
      )}

      {isMobile ? <DashboardOnboardingMobile /> : <DashboardOnboardingDesktop />}

      <SeeAllArticles title="Lastest Articles" linkTo="/feed" />

      <div className="dashboard-learning-and-events">
        {/*<YourLearning />*/}
        <UpcomingEvents />
      </div>

      <SeeAllArticles title="Last Saved" linkTo="/dashboard/saved" />
    </>
  );
};

export default Dashboard;
