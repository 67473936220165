import React, { useCallback, useState } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { updateUserMutationGql } from '../../gql/mutation';
import { usersQueryGql } from '../../gql/query';
import { setIsMobile, setIsUserId } from '../../gql/cache';
import { followingsFollowersFragmentGql } from '../../gql/fragment';
import _ from 'lodash';
import Tab from '../../common/Tab/Tab';
import ShowMore from '../../common/ShowMore/ShowMore';
import { useFeedFilter } from './feedFilter';

const FeedSidebarPublishers = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const countPublishersToShow = 5;
  const [isPublishersShowMore, setPublishersShowMore] = useState(false);
  const { setAuthor } = useFeedFilter();

  // TODO: Feed filtration
  // const [isFiltered, setFiltered] = useState(true);
  const [updateUser] = useMutation(updateUserMutationGql);
  const { data, client } = useQuery(usersQueryGql, {
    variables: {
      where: {
        id_ne: setIsUserId(),
        articlesOwned_null: false,
      },
      limit: 10,
    },
  });

  const { followings } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: followingsFollowersFragmentGql,
  });

  const onClick = useCallback(
    (active, id) => {
      const input = {
        where: { id: setIsUserId() },
        data: {},
      };
      if (active) {
        input.data.followings = _.without(_.map(followings, 'id'), id);
      } else {
        input.data.followings = [..._.map(followings, 'id'), id];
      }
      updateUser({
        variables: {
          input,
        },
      });
    },
    [followings, updateUser]
  );

  if (!data) {
    return null;
  }

  const activeItems = isPublishersShowMore
    ? data?.users
    : data?.users.slice(0, countPublishersToShow);

  return (
    <>
      {activeItems.map(({ username, avatar, id }) => {
        const [firstName, lastName] = _.map(_.words(username), _.capitalize);
        const active = _.some(followings, ['id', id]);
        return (
          <Tab
            key={id}
            typeRight="follow"
            avatar
            onClick={() => setAuthor({ id, name: username })}
            onClickFollow={() => onClick(active, id)}
            avatarImg={avatar}
            textLeft={firstName + ' ' + lastName}
            btnFollowLabel
            color="transparentGray"
            active={active}
            // TODO: Feed filtration
            // className={isFiltered && 'white-purple active'}
          />
        );
      })}
      {!isMobile && data?.users.length >= 5 && (
        <ShowMore
          isShowMore={isPublishersShowMore}
          setShowMore={setPublishersShowMore}
          openLabel="Show more publishers"
          closeLabel="Show less publishers"
        />
      )}
    </>
  );
};

export default FeedSidebarPublishers;
