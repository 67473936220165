import React, { useState } from 'react';
import ArticlesItem from '../../components/Articles/ArticlesItem';
import { useMediaQuery } from '../Dashboard/ResizeArticleInPage';
import PageTemplate from '../../common/PageTemplate/PageTemplate';
import { useTypes } from '../../common/PageTemplate/articleTypesFilter';
import { useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import { knowledgeBaseQueryGql } from '../../gql/query';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

const nav = ['Less completed first', 'More completed first', 'Last added', 'First added'];

const KnowledgeBaseCompanyFolder = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const { id } = useParams();
  let articleSize = useMediaQuery('(min-width: 1920px)');
  const { data } = useQuery(knowledgeBaseQueryGql, {
    variables: {
      id,
    },
  });

  const { types } = useTypes();

  const [viewListBtn, setViewListBtn] = useState(true);
  const [viewGridBtn, setViewGridBtn] = useState(false);

  const handleViewListBtn = () => {
    if (viewGridBtn) {
      setViewGridBtn(false);
    }
    if (viewGridBtn && viewListBtn) {
      setViewListBtn(true);
    }
    setViewListBtn(true);
  };

  const handleViewGridBtn = () => {
    if (viewListBtn) {
      setViewListBtn(false);
    }
    if (viewGridBtn && viewListBtn) {
      setViewGridBtn(true);
    }
    setViewGridBtn(true);
  };

  const cardViewClass = cx(
    { 'cards-grid': viewGridBtn },
    { 'cards-grid-list-view': viewListBtn && !isMobile }
  );

  return (
    <PageTemplate
      // onlyDropdown
      dropdownPosition="right"
      dropdownLabelArr={nav}
      dropdownCheckboxArr={types}
      isKnowledge
      coverImage={data?.knowledgeBase.coverImage}
      name={data?.knowledgeBase.name}
      description={data?.knowledgeBase.description}
      viewBtn
      viewListBtn={viewListBtn}
      viewGridBtn={viewGridBtn}
      onClickViewListBtn={handleViewListBtn}
      onClickViewGridBtn={handleViewGridBtn}
    >
      <div className={cardViewClass}>
        {data?.knowledgeBase.articles.map((item) => {
          return (
            <ArticlesItem
              key={item.id}
              {...item}
              related
              size={viewListBtn ? 'xs' : isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
              viewList={viewListBtn && !isMobile}
            />
          );
        })}
      </div>

      {/* <LoadMore /> */}
    </PageTemplate>
  );
};

export default KnowledgeBaseCompanyFolder;
