import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Upload } from 'antd';
import Button from '../../common/Button/Button';
import Select from '../../common/Select/Select';
import Radio from '../../common/Radio/Radio';
import ArticleItemHeader from '../../components/Articles/ArticleItemHeader';
import ArticleItemFooter from '../../components/Articles/ArticleItemFooter';
import Textarea from '../../common/Textarea/Textarea';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import {
  articleDraftQueryGql,
  coverImagePatternsQueryGql,
  interestsQueryGql,
} from '../../gql/query';
import _ from 'lodash';
import { getImageURL } from '../../helper';
import { setIsUserId } from '../../gql/cache';
import { departmentsFragmentGql } from '../../gql/fragment';
import { updateArticleMutationGql, uploadMutationGql } from '../../gql/mutation';
import { useTypes } from '../../common/PageTemplate/articleTypesFilter';
import DropdownCheckboxPage from '../../components/Dropdown/DropdownCheckboxPage';

const onChange = (fn, key, prev) => (value) => {
  if (key === 'interests') {
    if (_.includes(prev.interests, value)) {
      const values = [..._.remove(prev.interests, (i) => i !== value)];
      return fn({
        ...prev,
        interests: values,
      });
    }
    return fn({ ...prev, interests: [...prev.interests, value] });
  }
  fn({
    ...prev,
    [key]: value,
  });
};

const PreparingMaterialArticle = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const [typeCover, setTypeCover] = useState('patterns');
  const [fileList, setFileList] = useState([]);
  const [patterns, setPatterns] = useState([]);
  const { types, selectedIds, selected, onSelectOneType } = useTypes();

  const [selectedCover, setSelectedCover] = useState({ id: '', url: '' });
  const [articleValues, setArticleValues] = useState({
    headline: '',
    shortLead: '',
    subtitle: '',
    coverImage: '',
    interests: [],
  });

  const [updateArticle] = useMutation(updateArticleMutationGql, {
    onCompleted: ({
      updateArticle: {
        article: { slug },
      },
    }) => {
      push(`/article/${slug}`);
    },
  });
  const { data, client } = useQuery(articleDraftQueryGql, {
    variables: {
      id,
    },
  });

  const { data: dataInterests } = useQuery(interestsQueryGql);

  useQuery(coverImagePatternsQueryGql, {
    onCompleted: ({ coverImagePatterns }) => {
      const cover = _.pull(_.map(coverImagePatterns, 'coverImage'), null);
      setPatterns(cover);
    },
  });

  const [uploadFile] = useMutation(uploadMutationGql, {
    onCompleted: ({ upload }) => setSelectedCover(upload),
  });

  useEffect(() => {
    if (data) {
      const { headline, shortLead, subtitle, coverImage, interests } = data.article;
      setArticleValues((prev) => {
        return {
          ...prev,
          headline,
          shortLead: shortLead ? shortLead : '',
          subtitle: subtitle ? subtitle : '',
          interests: _.map(interests, 'id'),
        };
      });
      if (coverImage) {
        setSelectedCover(coverImage);
      }
      if (!coverImage && patterns.length) {
        setSelectedCover(patterns[0]);
      }
    }
  }, [data, patterns]);

  const { disabled, options, departments } = useMemo(() => {
    if (data) {
      const disabled = data.article.author.id !== setIsUserId();
      const { departments } = client.readFragment({
        id: `UsersPermissionsUser:${data.article.author.id}`,
        fragment: departmentsFragmentGql,
      });
      const options = _.map(departments, 'name');
      return {
        disabled,
        options,
        departments,
      };
    }
    return {
      disabled: true,
      options: [],
      departments: [],
    };
  }, [client, data]);

  const saveArticle = useCallback(
    (draft) => {
      if (selectedIds.length) {
        updateArticle({
          variables: {
            input: {
              where: { id: data.article.id },
              data: {
                ...articleValues,
                coverImage: selectedCover.id,
                articleType: selectedIds[0],
                draft,
              },
            },
          },
        });
      }
    },
    [articleValues, departments, id, selectedCover.id, selectedIds, updateArticle]
  );

  const filteredOptions = useMemo(() => {
    if (!dataInterests) {
      return [];
    }
    return dataInterests?.interests
      .map((item) => item.interestTitle)
      .filter((value) => !articleValues.interests.includes(value));
  }, [articleValues.interests, dataInterests]);

  const popularTopics = useMemo(() => {
    if (!dataInterests) return [];
    return dataInterests?.interests.map((item) => {
      return (
        <Button
          type="text"
          className={cx(
            's-6',
            _.includes(articleValues.interests, item.interestTitle) && 'disabled'
          )}
          key={item.id}
          onClick={() => {
            onChange(setArticleValues, 'interests', articleValues)(item.interestTitle);
          }}
        >
          {item.interestTitle}
        </Button>
      );
    });
  }, [articleValues, dataInterests]);

  const selectorTopics = useMemo(() => {
    if (!dataInterests) return [];
    return (
      <Select
        mode="multiple"
        placeholder="Inserted are removed"
        value={articleValues.interests}
        onChange={(value) => {
          setArticleValues({
            ...articleValues,
            interests: value,
          });
        }}
        options={filteredOptions}
      />
    );
  }, [dataInterests, articleValues, filteredOptions]);
  return (
    <div className="page__material">
      <div className="material-preparing">
        <div className="material-preparing__wrapper">
          <div className="article-modal__header">
            <Button iconLeft="arrow-left" text="Previous page" type="text" size="md" />
          </div>

          <div className="l-2">Prepare for publishing</div>
        </div>

        {/* <div className="material-preparing__section">
          <div className="material-preparing__content">
            <div className="material-preparing__left">
              <div className="material-preparing__subtitle">Publish as</div>
            </div>

            <div className="material-preparing__center">
              <div className="material-preparing-selects">
                <Select options={publishAs} placeholder="Select user" />

                <div className="material-preparing-selects__to">to</div>

                <Select options={publishAs} placeholder="Select user" />
              </div>

              <div className="material-preparing-checkboxes">
                {checkboxesList.map(({ text, isChecked }, index) => (
                  <Checkbox key={index} text={text} checked={isChecked} />
                ))}
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="material-preparing__section">
          <div className="material-preparing__content">
            <div className="material-preparing__left">
              <div className="material-preparing__subtitle">Access have</div>
            </div>
            <div className="material-preparing__center">
              <div className="material-preparing-selects">
                <Select options={publishAs} placeholder="Select user" />
              </div>

              <div className="material-preparing-checkboxes">
                <Checkbox text="Share with friendly organizations" checked={false} />
                <div className="material-preparing-checkboxes__description">
                  Selected access level applies in the same way to friendly organizations
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="material-preparing__section">
          <div className="material-preparing__content">
            <div className="material-preparing__left">
              <div className="material-preparing__subtitle">Card preview:</div>
            </div>
            <div className="material-preparing__center">
              <div className="material-preparing-card">
                <ArticleItemHeader
                  author={data?.article.author.username}
                  authorLogo={data?.article.author.avatar}
                />

                <div
                  className="material-preparing-card-cover"
                  style={{ backgroundImage: `url('${getImageURL(selectedCover.url)}')` }}
                >
                  <Textarea
                    disabled
                    className="material-preparing-card__name"
                    placeholder="Headline"
                    value={articleValues.headline}
                    onChange={onChange(setArticleValues, 'headline', articleValues)}
                  />

                  <div className="material-preparing-card__formats">
                    <DropdownCheckboxPage
                      choice="Article"
                      label={selected.length ? selected : 'Select type'}
                      array={types}
                      selected={selected}
                      onClick={onSelectOneType}
                      className={selected.length ? '' : 'notselect'} //change style
                    />
                  </div>
                </div>

                <div className="material-preparing-card__box">
                  <Textarea
                    className="material-preparing-card__title"
                    value={articleValues.headline}
                    placeholder="Subtitle"
                    onChange={onChange(setArticleValues, 'headline', articleValues)}
                  />
                  <Textarea
                    className="material-preparing-card__text"
                    value={articleValues.shortLead}
                    placeholder="Short lead"
                    onChange={onChange(setArticleValues, 'shortLead', articleValues)}
                  />
                </div>
                <ArticleItemFooter isPreparePublication />
              </div>
            </div>

            <div className="material-preparing__right">
              <div className="material-preparing__subtitle">Background:</div>
              <div className="material-preparing-background">
                <div className="material-preparing-background-box">
                  <div className="material-preparing-patterns">
                    <Radio
                      text="Select from patterns"
                      checked={typeCover === 'patterns'}
                      onClick={() => setTypeCover('patterns')}
                    />

                    <div className="material-preparing-background-box__expand">
                      <div className="material-preparing-patterns__list">
                        {patterns.map(({ id, url }) => (
                          <button
                            key={id}
                            type="button"
                            onClick={() => setSelectedCover({ id, url })}
                            className={`material-preparing-patterns-item ${
                              selectedCover.id === id ? 'checked' : ''
                            }`}
                          >
                            <div
                              className="material-preparing-patterns-item__bg"
                              style={{ backgroundImage: `url('${getImageURL(url)}')` }}
                            />
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="material-preparing-background-box">
                  <Radio
                    text="Enter a Hex code"
                    checked={typeCover === 'hex'}
                    onClick={() => setTypeCover('hex')}
                  />

                  <div className="material-preparing-background-box__expand">
                    <div className="material-preparing-hex">
                      <div
                        style={{ backgroundColor: `#${articleValues.backgroundHEX}` }}
                        className="material-preparing-hex__color"
                      />
                      <BlockPicker color={articleValues.backgroundHEX || ''} onChangeComplete={onChange(setArticleValues, 'backgroundHEX')}/>
                      <InputText value={articleValues.backgroundHEX} onChange={onChange(setArticleValues, 'backgroundHEX')} />
                    </div>
                  </div>
                </div> */}
                <div className="material-preparing-background-box">
                  <Radio
                    text="Upload an image"
                    checked={typeCover === 'upload'}
                    onClick={() => setTypeCover('upload')}
                  />
                  <div className="material-preparing-background-box__expand">
                    <Upload
                      action={(file) =>
                        uploadFile({
                          variables: {
                            file,
                          },
                        })
                      }
                      fileList={fileList}
                      multiple={false}
                      onChange={onChange}
                    >
                      <Button text="Choose an image" type="primary" size="md" />
                    </Upload>
                    {/* <div className="material-preparing-background-box__image-name">Image.jpg</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="material-preparing__section">
          <div className="material-preparing__content">
            <div className="material-preparing__left">
              <div className="material-preparing__subtitle">Topics:</div>
            </div>

            <div className="material-preparing__center">
              {selectorTopics}

              <div className="material-preparing-topics">
                <div className="material-preparing-topics__title">Popular topics</div>
                <div className="material-preparing-topics__list">{popularTopics}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="material-preparing-footer">
          <div className="material-preparing-footer__inner">
            <Button
              disabled={disabled}
              text="Publish"
              type="primary"
              size="md"
              onClick={() => saveArticle(false)}
            />
            <Button
              disabled={disabled}
              text="Save to drafts"
              type="secondary"
              size="md"
              onClick={() => saveArticle(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreparingMaterialArticle;
