import React, { useMemo, useCallback } from 'react';
import ProfileSidebarHeader from '../ProfileSidebarComponents/ProfileSidebarHeader';
import ProfileSidebarSocial from '../ProfileSidebarComponents/ProfileSidebarSocial';
import ProfileSidebarDescription from '../ProfileSidebarComponents/ProfileSidebarDescription';
import ProfileSidebarTags from '../ProfileSidebarComponents/ProfileSidebarTags';
import PeopleFollow from '../../../common/People/PeopleFollow';
import _ from 'lodash';
import { setIsUserId } from '../../../gql/cache';
import { useMutation } from '@apollo/client';
import { updateUserMutationGql } from '../../../gql/mutation';
import { followingsFollowersFragmentGql } from '../../../gql/fragment';

const ProfileSidebarPublicMobile = ({ user }) => {
  const { username, interests, jobTitle, instagram, facebook } = user;
  const [updateFollowings, { client }] = useMutation(updateUserMutationGql);
  const { followings } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: followingsFollowersFragmentGql,
  });

  const active = useMemo(() => {
    return _.some(followings, { id: user.id });
  }, [followings, user.id]);

  const onClick = useCallback(() => {
    if (active) {
      return updateFollowings({
        variables: {
          input: {
            where: {
              id: setIsUserId(),
            },
            data: {
              followings: _.without(_.map(followings, 'id'), user.id),
            },
          },
        },
      });
    }
    return updateFollowings({
      variables: {
        input: {
          where: {
            id: setIsUserId(),
          },
          data: {
            followings: [..._.map(followings, 'id'), user.id],
          },
        },
      },
    });
  }, [active, user.id, followings, updateFollowings]);

  const descriptionText = useMemo(() => {
    return _.split(_.trim(user.aboutMe), '.', 3);
  }, [user.aboutMe]);

  const following = useMemo(() => {
    return _.map(user.followings, 'id');
  }, [user.followings]);

  const followers = useMemo(() => {
    return _.map(user.followers, 'id');
  }, [user.followers]);

  return (
    <div className="profile-sidebar">
      <div className="profile-sidebar__header-mobile">
        <ProfileSidebarHeader
          name={username}
          position={jobTitle}
          dataUser={user}
          changeAvatar={false}
        />

        <ProfileSidebarSocial
          follow
          instagram={instagram}
          facebook={facebook}
          handleEditClick={onClick}
          active={active}
        />
      </div>

      <div className="profile-sidebar__follows">
        <PeopleFollow followAmount={following.length} following userIds={following} />
        <PeopleFollow followAmount={followers.length} following userIds={followers} />
      </div>

      <ProfileSidebarDescription text={descriptionText} />

      <ProfileSidebarTags tagsArray={interests} />
    </div>
  );
};

export default ProfileSidebarPublicMobile;
