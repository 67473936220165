import React, { useMemo, useCallback } from 'react';
import _ from 'lodash';
import ProfileSidebarHeader from '../ProfileSidebarComponents/ProfileSidebarHeader';
import ProfileSidebarSocial from '../ProfileSidebarComponents/ProfileSidebarSocial';
import ProfileSidebarUser from '../ProfileSidebarComponents/ProfileSidebarUser';
import ProfileSidebarDescription from '../ProfileSidebarComponents/ProfileSidebarDescription';
import ProfileSidebarTags from '../ProfileSidebarComponents/ProfileSidebarTags';
import PeopleFollow from '../../../common/People/PeopleFollow';
import { USER_INFO } from '../../../constants';
import { useMutation } from '@apollo/client';
import { updateUserMutationGql } from '../../../gql/mutation';
import { followingsFollowersFragmentGql } from '../../../gql/fragment';
import { setIsUserId } from '../../../gql/cache';

const ProfileSidebarPublic = ({ user }) => {
  const [updateFollowings, { client }] = useMutation(updateUserMutationGql);
  const { followings } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: followingsFollowersFragmentGql,
  });

  const active = useMemo(() => {
    return _.some(followings, { id: user.id });
  }, [followings, user.id]);

  const onClick = useCallback(() => {
    if (active) {
      return updateFollowings({
        variables: {
          input: {
            where: {
              id: setIsUserId(),
            },
            data: {
              followings: _.without(_.map(followings, 'id'), user.id),
            },
          },
        },
      });
    }
    return updateFollowings({
      variables: {
        input: {
          where: {
            id: setIsUserId(),
          },
          data: {
            followings: [..._.map(followings, 'id'), user.id],
          },
        },
      },
    });
  }, [active, user.id, followings, updateFollowings]);

  const { username, interests, jobTitle, instagram, facebook } = user;
  const userInfo = useMemo(() => {
    return _.map(USER_INFO, (item) => {
      return {
        ...item,
        value: _.isArray(user[item.value])
          ? _.get(user, `${item.value}[0].name`, item.value)
          : _.get(user, item.value, item.value),
      };
    });
  }, [user]);

  const descriptionText = useMemo(() => {
    return _.split(_.trim(user.aboutMe), '.', 3);
  }, [user.aboutMe]);

  const following = useMemo(() => {
    return _.map(user.followings, 'id');
  }, [user.followings]);

  const followers = useMemo(() => {
    return _.map(user.followers, 'id');
  }, [user.followers]);

  return (
    <div className="profile-sidebar">
      <ProfileSidebarHeader
        name={username}
        position={jobTitle}
        dataUser={user}
        changeAvatar={false}
      />

      <ProfileSidebarSocial
        follow
        instagram={instagram}
        facebook={facebook}
        handleEditClick={onClick}
        active={active}
      />

      <div className="profile-sidebar__follows">
        <PeopleFollow
          user={user}
          userName={username}
          followAmount={followers.length}
          followers
          userIds={followers}
        />

        <PeopleFollow
          user={user}
          userName={username}
          followAmount={following.length}
          following
          userIds={following}
        />
      </div>

      {descriptionText[0] !== '' && <ProfileSidebarDescription text={descriptionText} />}

      {!interests && <ProfileSidebarTags tagsArray={interests} userName={username} />}

      <ProfileSidebarUser userInfo={userInfo} />
    </div>
  );
};

export default ProfileSidebarPublic;
