import React from 'react';
import cx from 'classnames';
import CommentAdd from './CommentAdd';
import Avatar from '../Avatar/Avatar';
import moment from 'moment';
import ClapComments from '../Claps/ClapComments';
import DrawerModal from '../Drawer/Drawer';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const CommentModal = ({ openModal, handleClick, articleId, commentsData, slug }) => {
  const isMobile = useReactiveVar(setIsMobile);

  return (
    <DrawerModal
      width={420}
      placement="right"
      title="Comments"
      isDrawerVisible={openModal}
      handleClick={handleClick}
      className={cx('modal-comment', { mobile: isMobile })}
    >
      <div className="modal-add-comment">
        <CommentAdd articleId={articleId} slug={slug} />
        {/*<div className="modal-add-comment__tab">*/}
        {/*  <div className="tab-label-list">*/}
        {/*    <TabLabel transparent label="Popular" active />*/}
        {/*    <TabLabel transparent label="Newest" />*/}
        {/*    <TabLabel transparent label="Oldest" />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <div className="modal-comment__list">
        {commentsData.map(({ id, comment, user }) => {
          return (
            <div className="modal-comment__item" key={id}>
              <Avatar size="xlg" avatarImg={user?.avatar} author={user?.username} />
              <div className="modal-comment-item__comment">
                <div className="modal-comment-item__comment-info">
                  <div className="modal-comment-item__comment-info-author">
                    <span className="name">{user?.username}</span>
                    <span className="date">{moment().format('MMMM Do YYYY, h:mm:ss a')}</span>
                  </div>
                  <ClapComments short label articleSlug={slug} commentId={id} type="comment" />
                </div>
                <div className="modal-comment__item__content">{comment}</div>
              </div>
            </div>
          );
        })}
      </div>
    </DrawerModal>
  );
};

export default CommentModal;
