import React, { useEffect, useRef, useState } from 'react';
import ProfileSidebarPersonal from './ProfileSidebarPersonal';
import ProfileSidebarPublic from './ProfileSidebarPublic';
import cx from 'classnames';
import Alert from '../../../common/Alert/Alert';

const ProfileSidebar = ({ user, itIsMe }) => {
  const sidebar = useRef(null);
  const [profileClass, setClassName] = useState(null);

  const handleEvent = () => {
    if (sidebar.current && sidebar.current.offsetHeight > window.innerHeight) {
      setClassName('max-height');
    } else {
      setClassName(null);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleEvent);
    handleEvent();
    return () => {
      window.removeEventListener('resize', handleEvent);
    };
  }, [sidebar.current]);

  let profileClassName = cx('profile-sidebar-container', profileClass);

  return (
    <div className={profileClassName} ref={sidebar}>
      {itIsMe ? <ProfileSidebarPersonal user={user} /> : <ProfileSidebarPublic user={user} />}
    </div>
  );
};

export default ProfileSidebar;
