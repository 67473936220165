import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import MasonryArticlesList from '../../components/Articles/MasonryArticlesList';
import SwipeTagsList from '../../components/SwipeTags/SwipeTags';
import { setIsAuthorized } from '../../gql/cache';

const Landing = () => {
  const { push } = useHistory();
  const authorized = useReactiveVar(setIsAuthorized);

  useEffect(() => {
    if (authorized) {
      push('/dashboard');
    }
  }, [authorized, push]);

  return (
    <div className="page__landing">
      <div className="swiper__landing-page">
        <SwipeTagsList />
      </div>

      <div className="articles-container">
        <MasonryArticlesList />
      </div>
    </div>
  );
};

export default Landing;
