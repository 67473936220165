import React, { useEffect, useState } from 'react';
import PageTemplateDropdown from './PageTemplateDropdown';
import PageTemplateTab from './PageTemplateTab';
import RightSidebarFeed from '../../pages/Feed/RightSidebarFeed';
import { useReactiveVar } from '@apollo/client';
import { setArticlesArguments, setIsMobile } from '../../gql/cache';
import { getImageURL } from '../../helper';
import WelcomeBlock from '../WelcomeBlock/WelcomeBlock';
import DropdownLabelPage from '../../components/Dropdown/DropdownLabelPage';
import { nav, useTypes } from './articleTypesFilter';
import ButtonIcon from '../Button/ButtonIcon';

export const KnowledgeRender = ({ url, name, description }) => (
  <div className="knowledge-base-folder">
    <div className="knowledge-base-folder-banner" style={{ backgroundImage: `url(${url})` }}>
      <div className="knowledge-base-folder-banner__text">{name}</div>
    </div>

    <div className="knowledge-base-folder__description">{description}</div>
  </div>
);

const PageTemplate = ({
  title,
  children,
  pageFilter,
  onlyDropdown = false,
  dropdownPosition,
  dropdownLabelArr,
  viewBtn,
  viewListBtn,
  viewGridBtn,
  onClickViewListBtn,
  onClickViewGridBtn,
  types,
  tabList,
  tabsArr,
  activeTab,
  setActiveTab,
  searchRequest,
  isKnowledge,
  isFeed,
  selectedLabel,
  onSelectLabel,
  selectedTypes,
  onSelectTypes,
  coverImage,
  name,
  description,
  welcomeBlock,
  welcomeBlockType,
  welcomeBlockTitle,
  welcomeBlockDescription,
}) => {
  const isMobile = useReactiveVar(setIsMobile);
  const url = coverImage ? getImageURL(coverImage.url) : '/images/knowledge-base-folder-bg.png';

  const [typesSort, setTypesSort] = useState('Newest');
  const { selectedIds } = useTypes();

  useEffect(() => {
    setArticlesArguments({
      articleType: selectedIds,
    });
    return () => setArticlesArguments({});
  }, [selectedIds, typesSort]);

  const renderDropdown = dropdownPosition && (
    <PageTemplateDropdown
      dropdownPosition={dropdownPosition}
      dropdownLabelArr={dropdownLabelArr}
      types={types}
      onSelectLabel={onSelectLabel}
      onSelectTypes={onSelectTypes}
      selectedLabel={selectedLabel}
      selectedTypes={selectedTypes}
    />
  );

  const renderViewBtn = viewBtn && !isMobile && (
    <div className="page-filter-view-btn">
      <ButtonIcon icon="view-grid" size="xxs" active={viewGridBtn} onClick={onClickViewGridBtn} />
      <ButtonIcon
        icon="view-list-big"
        size="xxs"
        active={viewListBtn}
        onClick={onClickViewListBtn}
      />
    </div>
  );

  const renderContent = (
    <div className="page-template__content">
      {title && (
        <div className={isMobile ? 'l-5' : 'l-2'}>
          {title}
          {searchRequest && <span className="search-page-title__sought">{searchRequest}</span>}
        </div>
      )}

      {isKnowledge && <KnowledgeRender url={url} name={name} description={description} />}

      {isKnowledge && (
        <div className="page-filter-container page-filter-wrap-view-btn">
          <DropdownLabelPage
            label={typesSort}
            array={nav}
            selected={[typesSort]}
            onClick={setTypesSort}
          />
          {renderViewBtn}
        </div>
      )}

      {pageFilter && (
        <div className="page-filter-container">
          {tabList && (
            <PageTemplateTab tabsArr={tabsArr} activeTab={activeTab} setActiveTab={setActiveTab} />
          )}
          {renderDropdown}
          {renderViewBtn}
        </div>
      )}

      {onlyDropdown && isMobile && renderDropdown}
      {onlyDropdown && !isMobile && <div className="page-filter-container">{renderDropdown}</div>}

      {welcomeBlock && (
        <WelcomeBlock
          type={welcomeBlockType}
          title={welcomeBlockTitle}
          description={welcomeBlockDescription}
        />
      )}

      {children}
    </div>
  );

  return isFeed ? (
    <div className={isFeed}>
      {renderContent}
      {isFeed && <RightSidebarFeed />}
    </div>
  ) : (
    renderContent
  );
};

export default PageTemplate;
