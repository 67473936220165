import React, { useMemo } from 'react';
import Timeline from '../Timeline/Timeline';
import { useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../gql/cache';
import { courseQueryGql } from '../../gql/query';
import _ from 'lodash';
import { useLocation, Link } from 'react-router-dom';

const SidebarCourse = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const { pathname } = useLocation();
  const { idLesson, idCourse } = useMemo(() => {
    const arr = _.split(pathname, '/');
    const { length } = arr;
    const idLesson = arr[length - 1];
    const idCourse = arr[length - 2];
    return {
      idLesson,
      idCourse,
    };
  }, [pathname]);
  const { data } = useQuery(courseQueryGql, {
    variables: {
      id: idCourse,
    },
    isMobile,
  });

  const timeLineArray = useMemo(() => {
    if (data) {
      return _.map(_.get(data, 'course.lessons'), ({ id, headline, usersPassed }) => {
        const type =
          id === idLesson
            ? 'progress'
            : _.includes(_.map(usersPassed, 'id'), setIsUserId())
            ? 'done'
            : 'waiting';
        return {
          type,
          title: headline,
        };
      });
    }
    return [];
  }, [data, idLesson]);

  if (isMobile) return null;
  return (
    <aside className="sidebar-course">
      <Link className="sidebar-logo" to="/">
        <img className="sidebar-logo__img" src="/images/logo.png" alt="logo" />
      </Link>
      <Timeline timeLineArray={timeLineArray} vertical />
    </aside>
  );
};

export default SidebarCourse;
