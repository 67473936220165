import React from 'react';
import { Input } from 'antd';
import Svg from '../SVG';
import OutsideClickWrapper from '../OutsideClickWrapper/OutsideClickWrapper';
import Popups from '../Popups/Popups';

const SearchInput = (props) => {
  const { placeholder, id, children, value, showChildren, setSearch, close, goToToSearch } = props;

  const handleInput = ({ target: { value } }) => {
    setSearch(value);
  };

  return (
    <OutsideClickWrapper close={close}>
      <div className="search">
        <div className="search__inner">
          <Input
            onKeyDown={goToToSearch}
            onChange={(e) => handleInput(e)}
            className="search__input"
            placeholder={placeholder}
            id={id}
            value={value}
          />

          <label htmlFor={id} className="search__label">
            <Svg name="search" />
          </label>
        </div>

        {showChildren && <Popups>{children}</Popups>}
      </div>
    </OutsideClickWrapper>
  );
};

export default SearchInput;
