import React, { useCallback, useMemo } from 'react';
import Button from '../../common/Button/Button';
import { useMutation, useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import { updateUserMutationGql } from '../../gql/mutation';
import { followingsFollowersFragmentGql } from '../../gql/fragment';
import { setIsUserId } from '../../gql/cache';
import _ from 'lodash';

const ArticleFooterFollowBtn = ({ author }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const [updateFollowings, { client }] = useMutation(updateUserMutationGql);
  const { followings } = client.readFragment({
    id: `UsersPermissionsUser:${setIsUserId()}`,
    fragment: followingsFollowersFragmentGql,
  });

  const active = useMemo(() => {
    return _.some(followings, { id: author.id });
  }, [author.id, followings]);

  const onClick = useCallback(() => {
    if (active) {
      return updateFollowings({
        variables: {
          input: {
            where: {
              id: setIsUserId(),
            },
            data: {
              followings: _.without(_.map(followings, 'id'), author.id),
            },
          },
        },
      });
    }
    return updateFollowings({
      variables: {
        input: {
          where: {
            id: setIsUserId(),
          },
          data: {
            followings: [..._.map(followings, 'id'), author.id],
          },
        },
      },
    });
  }, [active, author.id, followings, updateFollowings]);

  return (
    <Button
      onClick={onClick}
      type="secondary"
      size={isMobile ? 'xs' : 'md'}
      text={active ? 'Following' : 'Follow'}
      iconLeft={active ? 'checkmark' : 'plus'}
    />
  );
};

export default ArticleFooterFollowBtn;
