import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import Button from '../../common/Button/Button';

const LoginWithGoogle = () => {
  const isMobile = useReactiveVar(setIsMobile);
  return (
    <a href={`${process.env.REACT_APP_API_URL}/connect/google`}>
      <Button
        text="Log in with Google"
        type="outline-purple"
        size={isMobile ? 'md' : 'lg'}
        block
        imageLeft="/images/google-logo.svg"
      />
    </a>
  );
};

export default LoginWithGoogle;
