import React, { useState, useCallback, useMemo } from 'react';
import Svg from '../../common/SVG';
import cx from 'classnames';
import SidebarAuthNav from './SidebarAuthNav';
import { Link } from 'react-router-dom';
import SidebarKnowledgeBase from './SidebarKnowledgeBase';

const SidebarAuthDesktop = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [collapsedSidebar, setCollapsedSidebar] = useState(false);

  const renderCollapsedSidebar = useCallback(() => {
    setTimeout(() => {
      setCollapsedSidebar(!collapsedSidebar);
    }, 150);
  }, [collapsedSidebar]);

  const handleClick = () => {
    setIsCollapsed(!isCollapsed);
    renderCollapsedSidebar();
  };

  const sidebarCollapsed = useMemo(() => {
    if (isCollapsed && collapsedSidebar) {
      return (
        <div className={cx({ 'sidebar-collapsed': isCollapsed })}>
          <Link className="sidebar-logo" to="/dashboard">
            <img className="sidebar-logo__img" src="/images/logoS.svg" alt="logo" />
          </Link>
          <div className="collapse-btn" onClick={handleClick}>
            <Svg name="arrow-right" />
          </div>
        </div>
      );
    }
  }, [isCollapsed, collapsedSidebar]);

  return (
    <aside className={cx('sidebar auth', { 'sidebar-collapse': isCollapsed })}>
      <div className="sidebar__box">
        <div className="sidebar-collapse">
          <Link className="sidebar-logo" to="/dashboard">
            <img className="sidebar-logo__img" src="/images/logo.png" alt="logo" />
          </Link>
          <SidebarAuthNav />

          <SidebarKnowledgeBase />
        </div>
        <div className="collapse-btn sidebar-collapse-btn" onClick={handleClick}>
          <Svg name="arrow-left" />
        </div>
        {sidebarCollapsed}
      </div>
    </aside>
  );
};

export default SidebarAuthDesktop;
