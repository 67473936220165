import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import ProfileSidebarHeader from '../ProfileSidebarComponents/ProfileSidebarHeader';
import ProfileSidebarSocial from '../ProfileSidebarComponents/ProfileSidebarSocial';
import ProfileSidebarDescription from '../ProfileSidebarComponents/ProfileSidebarDescription';
import ProfileSidebarTags from '../ProfileSidebarComponents/ProfileSidebarTags';
import ProfileSidebarFooter from '../ProfileSidebarComponents/ProfileSidebarFooter';
import ProfileSidebarUser from '../ProfileSidebarComponents/ProfileSidebarUser';
import ProfileEditModal from '../../../components/Modals/ProfileEditModal';
import ModalWrapper from '../../../components/Modals/ModalWrapper';
import PeopleFollow from '../../../common/People/PeopleFollow';
import { USER_INFO } from '../../../constants';

const ProfileSidebarPersonal = ({ user }) => {
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const { username, interests, jobTitle, instagram, facebook } = user;

  const handleEditClick = () => {
    setIsModalEditVisible(!isModalEditVisible);
  };

  const userInfo = useMemo(() => {
    return _.map(USER_INFO, (item) => {
      return {
        ...item,
        value: _.isArray(user[item.value])
          ? _.get(user, `${item.value}[0].name`, item.value)
          : _.get(user, item.value, item.value),
      };
    });
  }, [user]);

  const descriptionText = useMemo(() => {
    return _.split(_.trim(user.aboutMe), '.', 3);
  }, [user.aboutMe]);

  const following = useMemo(() => {
    return _.map(user.followings, 'id');
  }, [user.followings]);

  const followers = useMemo(() => {
    return _.map(user.followers, 'id');
  }, [user.followers]);

  return (
    <div className="profile-sidebar">
      <ProfileSidebarHeader name={username} position={jobTitle} dataUser={user} />

      <ProfileSidebarSocial
        handleEditClick={handleEditClick}
        edit
        instagram={instagram}
        facebook={facebook}
      />

      <div className="profile-sidebar__follows">
        <PeopleFollow
          userName={username}
          followAmount={following.length}
          following
          userIds={following}
        />

        <PeopleFollow
          userName={username}
          followAmount={followers.length}
          followers
          userIds={followers}
        />
      </div>

      {descriptionText[0] !== '' && <ProfileSidebarDescription text={descriptionText} />}

      {/*TODO: don't use in personal profile*/}
      {/*<ProfileSidebarTags tagsArray={interests} />*/}

      <ProfileSidebarUser userInfo={userInfo} />

      {/*<ProfileSidebarFooter />*/}

      <ModalWrapper
        isModalVisible={isModalEditVisible}
        handleClick={handleEditClick}
        modalWidth="448px"
        className="modal-with-sticky-header"
      >
        <ProfileEditModal handleEditClick={handleEditClick} user={user} />
      </ModalWrapper>
    </div>
  );
};

export default ProfileSidebarPersonal;
