import React, { useState, useMemo } from 'react';
import ProfileSidebarHeader from '../ProfileSidebarComponents/ProfileSidebarHeader';
import ProfileSidebarSocial from '../ProfileSidebarComponents/ProfileSidebarSocial';
import ProfileSidebarDescription from '../ProfileSidebarComponents/ProfileSidebarDescription';
import ProfileSidebarTags from '../ProfileSidebarComponents/ProfileSidebarTags';
import ProfileEditModal from '../../../components/Modals/ProfileEditModal';
import ModalWrapper from '../../../components/Modals/ModalWrapper';
import PeopleFollow from '../../../common/People/PeopleFollow';
import _ from 'lodash';

const ProfileSidebarPersonalMobile = ({ user }) => {
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const { username, avatar, interests, jobTitle, instagram, facebook } = user;

  const handleEditClick = () => {
    setIsModalEditVisible(!isModalEditVisible);
  };

  const descriptionText = useMemo(() => {
    return _.split(_.trim(user.aboutMe), '.', 3);
  }, [user.aboutMe]);

  const following = useMemo(() => {
    return _.map(user.followings, 'id');
  }, [user.followings]);

  const followers = useMemo(() => {
    return _.map(user.followers, 'id');
  }, [user.followers]);

  return (
    <div className="profile-sidebar">
      <div className="profile-sidebar__header-mobile">
        <ProfileSidebarHeader name={username} position={jobTitle} dataUser={user} />

        <ProfileSidebarSocial
          handleEditClick={handleEditClick}
          edit
          instagram={instagram}
          facebook={facebook}
        />
      </div>

      <div className="profile-sidebar__follows">
        <PeopleFollow
          userName={username}
          followAmount={following.length}
          following
          userIds={following}
        />

        <PeopleFollow
          userName={username}
          followAmount={followers.length}
          followers
          userIds={followers}
        />
      </div>

      {descriptionText[0] !== '' && <ProfileSidebarDescription text={descriptionText} />}

      <ProfileSidebarTags tagsArray={interests} />

      <ModalWrapper
        isModalVisible={isModalEditVisible}
        handleClick={handleEditClick}
        modalWidth="448px"
      >
        <ProfileEditModal handleEditClick={handleEditClick} />
      </ModalWrapper>
    </div>
  );
};

export default ProfileSidebarPersonalMobile;
