import React, { useEffect } from 'react';
import Dropdown from './Dropdown';
import Tab from '../../common/Tab/Tab';
import DropdownSearch from './DropdownSearch';
import _ from 'lodash';

const DropdownCheckboxPage = (
  { className = 'dropdown-page', label, array, position = 'left', selected, onClick, choice },
  isRenderSeearch
) => {
  useEffect(() => {
    if (choice) {
      onClick(choice);
    }
  }, [choice, onClick]);

  return (
    <Dropdown className={className} label={label} position={position}>
      {isRenderSeearch && <DropdownSearch id="dropdownSearch" />}
      <div className="dropdown-content-list__nav">
        {array.map(({ icon, name }) => (
          <Tab
            key={name}
            iconLeft={icon}
            textLeft={_.capitalize(name)}
            typeRight="checkbox"
            block
            active={_.includes(selected, name)}
            color="whitePurple"
            onClick={() => onClick(name)}
          />
        ))}
      </div>
    </Dropdown>
  );
};

export default DropdownCheckboxPage;
