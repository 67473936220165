import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import DashboardBlockInner from './DashboardBlockInner';
import Button from '../../common/Button/Button';
import UpcomingEventsItem from './UpcomingEventsItem';
import { useQuery } from '@apollo/client';
import { eventsQueryGql, organizationsQueryGql } from '../../gql/query';
import ModalWrapper from '../../components/Modals/ModalWrapper';
import { setIsUserId } from '../../gql/cache';

const UpcomingEvents = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible((isModalVisible) => !isModalVisible);
  };
  const { data: eventsData } = useQuery(eventsQueryGql, {
    variables: {
      where: {
        date_gte: moment().format('YYYY-MM-DD'),
        date_lte: moment().add(30, 'days').format('YYYY-MM-DD'),
      },
    },
  });

  const { data: organizationsData } = useQuery(organizationsQueryGql, {
    variables: {
      where: {
        usersPermissions: {
          id: setIsUserId(),
        },
      },
    },
  });

  if (!eventsData || !organizationsData) return null;

  const upcomingEventsClass = cx(
    'upcoming-events',
    // { modal: isModalVisible },
    { 'upcoming-empty': !eventsData?.events.length }
  );

  return (
    <>
      <DashboardBlockInner className={upcomingEventsClass}>
        <div className="dashboard__block-header">
          <div className="m-5">Upcoming events</div>
          {!isModalVisible && (
            <Button
              type="text"
              size="md"
              text="See all"
              onClick={handleClick}
              disabled={!eventsData?.events.length}
            />
          )}
        </div>
        <div
          className={eventsData?.events.length ? 'upcoming-events-list' : 'upcoming-events-empty'}
        >
          {eventsData?.events.length ? (
            eventsData?.events.map((item) => <UpcomingEventsItem key={item.id} {...item} />)
          ) : (
            <span className="empty-articles-title__label">
              {organizationsData?.organizations[0]?.name} hasn’t added any events yet
            </span>
          )}
        </div>
      </DashboardBlockInner>
      {isModalVisible && eventsData?.events.length && (
        <ModalWrapper
          className="modal__upcoming-events modal-with-sticky-header"
          isModalVisible={isModalVisible}
          handleClick={handleClick}
        >
          <div className="upcoming-events ant-modal-body-container">
            <div className="ant-modal-sticky-header">Upcoming events</div>
            <div className="ant-modal-body-content upcoming-events-list">
              {eventsData?.events.map((item) => (
                <UpcomingEventsItem key={item.id} {...item} />
              ))}
            </div>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};

export default UpcomingEvents;
