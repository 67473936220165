import React from 'react';
import cx from 'classnames';

const Radio = ({ id, text, disabled, checked, onClick }) => {
  const radioClassName = cx('radio', disabled && 'disabled', checked && !disabled && 'checked');

  return (
    <div className={radioClassName}>
      <button type="button" className="radio__btn" id={id} onClick={onClick}>
        {checked && <div className="radio__circle" />}
      </button>
      {text && (
        <span className="radio__label" htmlFor={id}>
          {text}
        </span>
      )}
    </div>
  );
};

export default Radio;
