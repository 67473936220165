import React from 'react';
import RecommendedArticles from '../../RecommendedArticles';
import ArticlesItem from '../../../../components/Articles/ArticlesItem';
import { useMediaQuery } from '../../ResizeArticleInPage';
import PageTemplate from '../../../../common/PageTemplate/PageTemplate';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { articlesQueryShortFormGql, userQueryGql } from '../../../../gql/query';
import { setIsMobile, setIsUserId } from '../../../../gql/cache';
import { Spin } from 'antd';
import _ from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';

const RecommendedPage = () => {
  let articleSize = useMediaQuery('(min-width: 1920px)');
  const [getArticle, { data }] = useLazyQuery(articlesQueryShortFormGql);
  useQuery(userQueryGql, {
    variables: {
      id: setIsUserId(),
    },
    onCompleted({ user: { organizations, interests: userInterests } }) {
      const organization = _.map(organizations, 'id');
      const interests = _.map(userInterests, 'id');
      getArticle({
        variables: {
          where: {
            draft: false,
            organization,
            interests,
          },
        },
      });
    },
  });

  const isMobile = useReactiveVar(setIsMobile);

  if (!data) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <Spin indicator={antIcon} />;
  }

  return (
    <PageTemplate title="Recommended" onlyDropdown dropdownPosition="left">
      <div className="cards-grid">
        {data?.articles.map((item) => {
          return (
            <ArticlesItem
              {...item}
              related
              size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
              direction="col"
            />
          );
        })}
      </div>
    </PageTemplate>
  );
};
export default RecommendedPage;
