import React, { useState } from 'react';
import { useQuery, useReactiveVar, useLazyQuery } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import cx from 'classnames';
import OutsideClickWrapper from '../../common/OutsideClickWrapper/OutsideClickWrapper';
import Svg from '../../common/SVG';
import ButtonIcon from '../../common/Button/ButtonIcon';
import Avatar from '../../common/Avatar/Avatar';
import { meQueryGql, userQueryGql } from '../../gql/query';
import _ from 'lodash';

const Dropdown = ({
  btnType = 'default',
  position = 'right',
  className,
  label,
  children,
  icon,
}) => {
  const isMobile = useReactiveVar(setIsMobile);
  const [isOpen, setIsOpen] = useState(false);

  const handleAccountDropdown = () => {
    setIsOpen(!isOpen);
  };

  const DefaultButton = () => {
    return (
      <button
        type="button"
        className={`dropdown-btn ${isOpen ? 'active' : ''}`}
        onClick={handleAccountDropdown}
      >
        {label && <div className="dropdown-btn__name">{label}</div>}
        <Svg name={isOpen ? 'arrow-up' : 'arrow-down'} />
      </button>
    );
  };

  const AccountButton = () => {
    const [getUser, { data: dataUser }] = useLazyQuery(userQueryGql);

    useQuery(meQueryGql, {
      onCompleted({ me }) {
        getUser({ variables: { id: me.id } });
      },
    });

    if (!dataUser) {
      return null;
    }

    const [firstName, lastName] = _.map(_.words(dataUser?.user.username), _.capitalize);

    return (
      <button
        type="button"
        className={`dropdown-btn ${isOpen ? 'active' : ''}`}
        onClick={handleAccountDropdown}
      >
        {label && (
          <div className="dropdown-btn__name">
            {firstName} {lastName}
          </div>
        )}
        <Avatar size="lg" author={dataUser.user.username} avatarImg={dataUser.user.avatar} />
        <Svg name={isOpen ? 'arrow-up' : 'arrow-down'} />
      </button>
    );
  };

  const NotificationButton = () => {
    return (
      <ButtonIcon
        icon="bell"
        size={isMobile ? 'xs' : 'md'}
        active={isOpen}
        onClick={handleAccountDropdown}
      />
    );
  };

  const IconButton = () => {
    return <Svg name={icon} className="md" onClick={handleAccountDropdown} />;
  };

  const dropdownButtonType = {
    default: <DefaultButton />,
    account: <AccountButton />,
    notification: <NotificationButton />,
    icon: <IconButton />,
  };

  const dropdownClass = cx('dropdown', btnType, className, { active: isOpen }, position);

  return (
    <OutsideClickWrapper close={setIsOpen}>
      <div className={dropdownClass}>
        {dropdownButtonType[btnType]}

        {isOpen && <div className="dropdown-content-list">{children}</div>}
      </div>
    </OutsideClickWrapper>
  );
};

export default Dropdown;
