import React from 'react';
import Clap from '../../common/Claps/Clap';
import Bookmark from '../../common/Bookmark/Bookmark';
import Comment from '../../common/Comment/Comment';
import { useReactiveVar } from '@apollo/client';
import { setIsAuthorized } from '../../gql/cache';

const ActionsArticle = ({ comments, articleId, clapsNumber, activeClap, slug, likes }) => {
  const authorized = useReactiveVar(setIsAuthorized);

  return (
    <>
      <Clap
        label
        articleId={articleId}
        total={clapsNumber}
        active={activeClap}
        slug={slug}
        likes={likes}
      />
      {authorized && <Bookmark label articleId={articleId} />}
      <Comment label comments={comments} articleId={articleId} slug={slug} />
    </>
  );
};

export default ActionsArticle;
