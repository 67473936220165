import React, { useState } from 'react';
import { nav, useTypes } from '../../common/PageTemplate/articleTypesFilter';
import PageTemplate from '../../common/PageTemplate/PageTemplate';
import ArticlesItem from '../../components/Articles/ArticlesItem';
import { useMediaQuery } from '../Dashboard/ResizeArticleInPage';
import { useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import { articlesQueryShortFormGql } from '../../gql/query';

const Shared = () => {
  const isMobile = useReactiveVar(setIsMobile);
  let articleSize = useMediaQuery('(min-width: 1920px)');
  const { types } = useTypes();
  const { data } = useQuery(articlesQueryShortFormGql, {
    variables: {
      where: {
        draft: false,
      },
    },
  });
  const [activeTab, setActiveTab] = useState(1);

  const sharedTabsArr = [
    {
      label: 'Feed publications',
      badgeAmount: '95',
      content: data?.articles.map((item) => {
        return (
          <ArticlesItem
            {...item}
            learning
            size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
            direction="col"
          />
        );
      }),
    },
    {
      label: 'Learning publications',
      badgeAmount: '36',
      content: data?.articles.map((item) => {
        return (
          <ArticlesItem
            {...item}
            related
            size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
            direction="col"
          />
        );
      }),
    },
    {
      label: 'Courses',
      badgeAmount: '112',
      content: data?.articles.map((item) => {
        return (
          <ArticlesItem
            {...item}
            related
            size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
            direction="col"
          />
        );
      }),
    },
    {
      label: 'Quizzes',
      badgeAmount: '4',
      content: data?.articles.map((item) => {
        return (
          <ArticlesItem
            {...item}
            related
            size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
            direction="col"
          />
        );
      }),
    },
  ];

  return (
    <PageTemplate
      title="Aurocraft’s materials"
      pageFilter
      dropdownPosition="right"
      dropdownLabelArr={nav}
      dropdownCheckboxArr={types}
      tabList
      tabsArr={sharedTabsArr}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    >
      <div className="cards-grid">
        {sharedTabsArr.map(({ content }, key) => {
          return <React.Fragment key={key}>{activeTab === key && content}</React.Fragment>;
        })}
      </div>
    </PageTemplate>
  );
};

export default Shared;
