import React, { useMemo } from 'react';
import ArticleUser from './ArticleUser';
import Svg from '../../common/SVG';
import Comment from '../../common/Comment/Comment';
import Clap, { ClapIcon } from '../../common/Claps/Clap';
import Progressbar from '../../common/Progressbar/Progressbar';
import ArticlePublicationTime from './ArticlePublicationTime';
import _ from 'lodash';
import { setIsAuthorized, setIsUserId } from '../../gql/cache';
import { useReactiveVar } from '@apollo/client';

const ArticleItemFooter = ({
  id,
  related,
  learning,
  draft,
  author,
  authorLogo,
  likes = [],
  comments = [],
  authorId,
  progress,
  isPreparePublication,
  updatedAt,
  slug,
}) => {
  const authorized = useReactiveVar(setIsAuthorized);

  const active = useMemo(() => {
    return !!_.find(likes, ['user.id', setIsUserId()]);
  }, [likes]);

  if (related || draft) {
    return (
      <div className="article-item-footer">
        <ArticleUser authorLogo={authorLogo} author={author} authorId={authorId} />
        {draft ? (
          <div className="article-item-publish">
            <Svg name="draft" />
            <span>Drafts</span>
          </div>
        ) : (
          <div className="article-item-publish">
            <Svg name="clock" />
            <ArticlePublicationTime updatedAt={updatedAt} />
          </div>
        )}
      </div>
    );
  } else if (learning) {
    return <Progressbar type="article" text={`${progress}% progress`} progress={progress} />;
  }
  return (
    <div className="article-item-footer">
      <div className="article-item-footer__comment">
        <Comment
          disabled={isPreparePublication}
          label
          comments={comments}
          articleId={id}
          slug={slug}
          isRenderAvatar
        />
      </div>
      <div className="article-item-footer__clap">
        {authorized ? (
          <Clap
            disabled={isPreparePublication}
            label
            total={likes.length}
            active={active}
            articleId={id}
            likes={likes}
          />
        ) : (
          <div className="claps-container disabled">
            <ClapIcon />
          </div>
        )}
      </div>
    </div>
  );
};
export default ArticleItemFooter;
