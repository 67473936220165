import React, { useState } from 'react';
import Svg from '../../common/SVG';
import Button from '../../common/Button/Button';
import ModalWrapper from '../Modals/ModalWrapper';
import UpcomingEvents from '../../pages/Dashboard/UpcomingEvents';

const UpcomingEventsPush = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible((isModalVisible) => !isModalVisible);
  };

  return (
    <>
      <div className="header-notification-item">
        <div className="header-notification-item__icon">
          <Svg name="draft" />
        </div>

        <div className="header-notification-item__inner">
          <div className="header-notification-item__title">New event added</div>

          <div className="header-notification-item__text">Retrospective</div>
        </div>

        <Button onClick={handleClick} text="See it" type="primary" />
      </div>

      {isModalVisible && (
        <ModalWrapper
          className="modal__upcoming-events"
          isModalVisible={isModalVisible}
          handleClick={handleClick}
        >
          <UpcomingEvents modal />
        </ModalWrapper>
      )}
    </>
  );
};
export default UpcomingEventsPush;
