import { memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Guard from './components/Guard/Guard';
import Landing from './pages/Landing/Landing';
import Login from './pages/Login/Login';
import CheckEmail from './pages/Login/CheckEmail';
import AvailableOrganizations from './pages/Login/AvailableOrganizations';
import UserOnboarding from './pages/Login/UserOnboarding';
import Profile from './pages/Profile/Profile';
import Dashboard from './pages/Dashboard/Dashboard';
import YourLearningPage from './pages/Dashboard/Pages/YourLearnings/YourLearningPage';
import RecommendedPage from './pages/Dashboard/Pages/Recommended/RecommendedPage';
import SavedPage from './pages/Dashboard/Pages/Saved/SavedPage';
import DraftsPage from './pages/Dashboard/Pages/Drafts/Drafts';
import Feed from './pages/Feed/Feed';
import MaterialArticle from './pages/Material/MaterialArticle';
import MaterialCourse from './pages/Material/MaterialCourse';
import Search from './pages/Search/Search';
import KnowledgeBaseCompany from './pages/KnowledgeBase/KnowledgeBaseCompany';
import KnowledgeBaseQuizzes from './pages/KnowledgeBase/KnowledgeBaseQuizzes';
import KnowledgeBaseLearning from './pages/KnowledgeBase/KnowledgeBaseLearning';
import KnowledgeBaseCompanyFolder from './pages/KnowledgeBase/KnowledgeBaseCompanyFolder';
import Shared from './pages/Shared/Shared';
import Cms from './pages/Cms/Cms';
import PreparingMaterialArticle from './pages/Material/PreparingMaterialArticle';
import Oauth from './components/Guard/Oauth';

const mainRoutes = [
  {
    path: '/',
    component: Landing,
    exact: true,
  },
  {
    path: '/oauth',
    component: Oauth,
    exact: true,
  },
  {
    path: '/article/:id',
    component: MaterialArticle,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: false,
  },
  {
    path: '/сheck-email',
    component: CheckEmail,
    exact: false,
  },
  {
    path: '/onboarding',
    component: UserOnboarding,
    exact: false,
  },
  {
    path: '/available-organizations',
    component: AvailableOrganizations,
    exact: false,
  },
  {
    path: '/profile/:id',
    component: Profile,
    exact: true,
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
  },
  {
    path: '/drafts',
    component: DraftsPage,
    exact: false,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    routes: [
      {
        path: '/your-learnings',
        component: YourLearningPage,
        exact: true,
      },
      {
        path: '/your-learnings/:id',
        component: MaterialArticle,
        exact: true,
      },
      {
        path: '/your-learnings/:idCourse/:idLesson',
        component: MaterialCourse,
        exact: true,
      },
      {
        path: '/recommended',
        component: RecommendedPage,
        exact: false,
      },
      {
        path: '/saved',
        component: SavedPage,
        exact: false,
      },
    ],
  },
  {
    path: '/feed',
    component: Feed,
    exact: false,
  },
  {
    path: '/search',
    component: Search,
    exact: false,
  },
  {
    path: '/knowledge-base-company/:id',
    component: KnowledgeBaseCompany,
    exact: false,
  },
  {
    path: '/knowledge-base-company-folder/:id',
    component: KnowledgeBaseCompanyFolder,
    exact: true,
  },
  {
    path: '/knowledge-base-publications',
    component: KnowledgeBaseQuizzes,
    exact: false,
  },
  {
    path: '/knowledge-base-courses',
    component: KnowledgeBaseQuizzes,
    exact: false,
  },
  {
    path: '/knowledge-base-quizzes',
    component: KnowledgeBaseQuizzes,
    exact: false,
  },
  {
    path: '/knowledge-base-learning',
    component: KnowledgeBaseLearning,
    exact: false,
  },
  {
    path: '/shared',
    component: Shared,
    exact: false,
  },
  {
    path: '/cms',
    component: Cms,
    exact: false,
  },
  {
    path: '/preparing-material/:id',
    component: PreparingMaterialArticle,
    exact: false,
  },
];

const RenderRoutes = () => {
  return mainRoutes.map((router) => {
    if (router.routes) {
      const Child = router.component;
      return (
        <>
          <Route
            {...router}
            component={(props) => (
              <Guard {...props}>
                <Child />
              </Guard>
            )}
          />
          {router.routes.map((childrenRouter) => {
            const Child = childrenRouter.component;
            return (
              <Route
                key={router.path + childrenRouter.path}
                path={router.path + childrenRouter.path}
                exact={childrenRouter.exact}
                component={(props) => (
                  <Guard {...props}>
                    <Child />
                  </Guard>
                )}
              />
            );
          })}
          <Redirect to="/" />
        </>
      );
    }
    const Child = router.component;
    return (
      <>
        <Route
          key={router.path}
          exact={router.exact}
          path={router.path}
          component={(props) => (
            <Guard {...props}>
              <Child />
            </Guard>
          )}
        />
        <Redirect to="/" />
      </>
    );
  });
};

export default memo(RenderRoutes);
