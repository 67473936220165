import React from 'react';
import Button from '../Button/Button';
import DashboardBlockInner from '../../pages/Dashboard/DashboardBlockInner';
import { useMutation, useReactiveVar } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../gql/cache';
import { updateUserMutationGql } from '../../gql/mutation';

const WelcomeBlock = ({ title, description, type }) => {
  const isMobile = useReactiveVar(setIsMobile);
  const [closeDashboard] = useMutation(updateUserMutationGql, {
    variables: {
      input: {
        where: {
          id: setIsUserId(),
        },
        data: {
          [type]: true,
        },
      },
    },
  });

  return (
    <DashboardBlockInner className="dashboard-welcome">
      <img className="dashboard-welcome__icon" src="/icons/knowledge.svg" alt="knowledge" />

      <div className="dashboard-welcome__inner">
        <div className="m-6">{title}</div>
        <div className="s-5">{description}</div>
      </div>

      <Button onClick={closeDashboard} text="Got it" type="primary" size="md" block={isMobile} />
    </DashboardBlockInner>
  );
};

export default WelcomeBlock;
