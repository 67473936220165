import React, { useState } from 'react';
import DropdownIconPage from '../Dropdown/DropdownIconPage';
import ModalWrapper from '../Modals/ModalWrapper';
import Button from '../../common/Button/Button';

const ArticleOptions = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleDelete = () => {
    setModalVisible(!isModalVisible);
  };

  const nav1 = [
    {
      icon: 'edit',
      name: 'Edit',
    },
    {
      icon: 'draft',
      name: 'Archive to drafts',
    },
    {
      icon: 'delete',
      name: 'Delete',
      onClick: handleDelete,
    },
  ];

  return (
    <>
      <DropdownIconPage icon="options" array={nav1} position="custom" />
      {isModalVisible && (
        <ModalWrapper
          className="modal__classic"
          isModalVisible={isModalVisible}
          handleClick={handleDelete}
        >
          <div className="m-1 modal__title">Confirmation</div>
          <div className="s-6 modal__description">
            Are you sure you want to delete material <br /> «Material name»?
          </div>
          <div className="modal__buttons">
            <Button text="Cancel" size="md" type="outline-purple" />
            <Button text="Delete" size="md" type="primary" />
          </div>
        </ModalWrapper>
      )}
    </>
  );
};

export default ArticleOptions;
