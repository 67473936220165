import React, { useEffect, useState } from 'react';
import ArticlesItem from '../../../../components/Articles/ArticlesItem';
import { useMediaQuery } from '../../ResizeArticleInPage';
import { nav, useTypes } from '../../../../common/PageTemplate/articleTypesFilter';
import PageTemplate from '../../../../common/PageTemplate/PageTemplate';
import { useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile, setIsUserId } from '../../../../gql/cache';
import { articlesQueryShortFormGql } from '../../../../gql/query';
import { userSavedsFragmentGql } from '../../../../gql/fragment';
import _ from 'lodash';

const SavedPage = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const [ids, setIds] = useState([]);
  const [typesSort, setTypesSort] = useState('Newest');
  let articleSize = useMediaQuery('(min-width: 1920px)');
  const { types, selectedIds, selected, onSelect } = useTypes();
  const { data, client } = useQuery(articlesQueryShortFormGql, {
    skip: !ids.length,
    variables: {
      sort: typesSort === 'Newest' ? 'updated_at:DESC' : 'updated_at:ASC',
      where: {
        draft: false,
        id: ids,
        articleType: selectedIds,
      },
    },
  });

  useEffect(() => {
    const dataSaved = client.readFragment({
      id: `UsersPermissionsUser:${setIsUserId()}`,
      fragment: userSavedsFragmentGql,
    });
    const articleIds = _.map(dataSaved.saveds, 'article.id');
    setIds(articleIds);
  }, [client]);

  return (
    <PageTemplate
      title="Saved"
      onlyDropdown
      dropdownPosition="left"
      dropdownLabelArr={nav}
      types={types}
      selectedTypes={selected}
      onSelectTypes={onSelect}
      selectedLabel={[typesSort]}
      onSelectLabel={setTypesSort}
    >
      <div className="cards-grid">
        {data?.articles.map((item) => {
          return (
            <ArticlesItem
              key={item.id}
              {...item}
              related
              size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
              direction="col"
            />
          );
        })}
      </div>
    </PageTemplate>
  );
};

export default SavedPage;
