import React from 'react';
import ArticleUser from './ArticleUser';
import Button from '../../common/Button/Button';

const ArticleItemHeader = ({ author, authorLogo, authorId }) => {
  return (
    <div className="article-item-header">
      <ArticleUser authorLogo={authorLogo} author={author} authorId={authorId} />
      <Button type="secondary" size="xs" text="Following" iconLeft="plus" disabled />
    </div>
  );
};
export default ArticleItemHeader;
