import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import './assets/styles/styles.scss';
import Routes from './Routes';
import { useReactiveVar } from '@apollo/client';
import { setIsAuthorized, setIsMobile } from './gql/cache';
import cx from 'classnames';
import { pageName } from './constants';
import HeaderDesktop from './components/Header/HeaderDesktop';
import HeaderMobile from './components/Header/HeaderMobile';

const checkRoute = (pathname) => {
  const getPath = pathname.split('/')[1];

  if (pathname === '/') return 'landing-page';

  return pageName.includes(getPath) && `${getPath}-page`;
};

const AppLayout = () => {
  const authorized = useReactiveVar(setIsAuthorized);
  const isMobile = useReactiveVar(setIsMobile);
  const { pathname } = useLocation();

  const pageClassName = cx(
    'page',
    authorized ? 'auth' : 'unauth',
    checkRoute(pathname),
    isMobile ? 'mobile' : 'desktop'
  );

  const Head = isMobile ? HeaderMobile : HeaderDesktop;

  return (
    <div className={pageClassName}>
      <Sidebar />
      <div className="page__content">
        <div className="page__container">
          {authorized && <Head />}
          <Switch>
            <Routes />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
