import React from 'react';
import { isNull } from 'lodash';
import { DatePicker } from 'antd';
import Label from '../Label/Label';
import moment from 'moment';

const DatepickerWithLabel = (props) => {
  const { labelText, id, value, size, allowClear, onChange } = props;

  let isNullValue = isNull(value);

  return (
    <div className="field">
      {labelText && <Label id={id} text={labelText} />}

      <DatePicker
        value={isNullValue ? '' : moment(value)}
        size={size}
        allowClear={allowClear}
        onChange={onChange}
      />
    </div>
  );
};

export default DatepickerWithLabel;
