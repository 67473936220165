import React, { useEffect, useState } from 'react';
import DropdownLabelPage from '../../components/Dropdown/DropdownLabelPage';
import DropdownCheckboxPage from '../../components/Dropdown/DropdownCheckboxPage';
import { useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import { setArticlesArguments, setIsMobile } from '../../gql/cache';
import { useTypes, nav } from '../../common/PageTemplate/articleTypesFilter';

const onClick = (fn, prev) => (item) => () => {
  if (_.includes(prev, item)) {
    return fn([..._.remove(prev, (i) => i !== item)]);
  }
  return fn([...prev, item]);
};

const DropdownProfileTopBar = () => {
  const isMobile = useReactiveVar(setIsMobile);
  const { types, selectedIds, setSelected, selected } = useTypes();
  const [typesSort, setTypesSort] = useState('Newest');

  useEffect(() => {
    setArticlesArguments({
      articleType: selectedIds,
    });
    return () => setArticlesArguments({});
  }, [selectedIds, typesSort]);

  return (
    <>
      <DropdownCheckboxPage
        label="All formats"
        array={types}
        position={isMobile ? 'left' : 'right'}
        selected={selected}
        onClick={onClick(setSelected, selected)}
      />
      <DropdownLabelPage
        label={typesSort}
        array={nav}
        selected={[typesSort]}
        position={isMobile ? 'left' : 'right'}
        onClick={setTypesSort}
      />
    </>
  );
};

export default DropdownProfileTopBar;
