import React from 'react';
import EmptyDashedArticleGrid from '../../components/Articles/EmptyDashedArticleGrid';

const SearchLearningMaterials = () => {
  return (
    <EmptyDashedArticleGrid
      title="There’re no publications yet, be the first to publish"
      image="pensil.svg"
      btn="Add new material"
      sheet
    />
  );
};

export default SearchLearningMaterials;
