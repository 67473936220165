import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { setIsAuthorized } from '../../gql/cache';

const authArticleSize = [
  '(max-width: 909px)',
  '(min-width: 910px) and (max-width: 1196px)',
  '(min-width: 1197px) and (max-width: 1399px)',
  '(min-width: 1400px) and (max-width: 1599px)',
  '(min-width: 1600px)',
];

const unAuthArticleSize = [
  '(max-width: 1289px)',
  '(min-width: 1290px) and (max-width: 1439px)',
  '(min-width: 1440px) and (max-width: 1640px)',
  '(min-width: 1641px) and (max-width: 1919px)',
  '(min-width: 1920px)',
];

const feedPageArticleSize = [
  '(max-width: 1332px)',
  '(min-width: 1333px) and (max-width: 1479px)',
  '(min-width: 1480px) and (max-width: 1719px)',
  '(min-width: 1720px) and (max-width: 1919px)',
  '(min-width: 1920px)',
];

const ResizeArticle = (isFeedPage) => {
  const authorized = useReactiveVar(setIsAuthorized);

  const mediaArticleSize = authorized
    ? isFeedPage
      ? feedPageArticleSize
      : authArticleSize
    : unAuthArticleSize;

  const [media1000, media1200, media1365, media1599, media1600] = mediaArticleSize.map((value) =>
    window.matchMedia(value)
  );

  const mobileMedia = window.matchMedia('(min-width: 320px) and (max-width:1024px)');

  const col = [0, 1];

  useEffect(() => {
    if (document.getElementsByClassName('masonry-articles-col')[0] === undefined) {
      return null;
    }

    if (document.getElementsByClassName('masonry-articles-col')[1] === undefined) {
      return null;
    }

    const [firstCol, secondCol] = col.map(
      (value) => document.getElementsByClassName('masonry-articles-col')[value]
    );

    const colArr = [firstCol, secondCol];

    const [arrFirstCol, arrSecondCol] = colArr.map((value) => [...value.children]);

    const handle1000 = () => {
      if (media1000.matches) {
        arrFirstCol.map((value) => {
          value.classList.add('lg');
          value.classList.remove('xlg', 'md');
        });
        arrSecondCol.map((value) => {
          value.classList.add('lg');
          value.classList.remove('md', 'xlg', 'xs');
        });
      }
    };

    const handle1200 = () => {
      if (media1200.matches) {
        arrFirstCol.map((value) => {
          value.classList.add('md');
          value.classList.remove('lg', 'xlg', 'xs');
        });
        arrSecondCol.map((value) => {
          value.classList.add('xs');
          value.classList.remove('md', 'lg', 'xlg');
        });
      }
    };
    const handle1200Feed = () => {
      if (media1200.matches) {
        arrFirstCol.map((value) => {
          value.classList.add('xlg');
          value.classList.remove('lg', 'md');
        });
        arrSecondCol.map((value) => {
          value.classList.add('xlg');
          value.classList.remove('md', 'lg', 'xs');
        });
      }
    };

    const handle1365 = () => {
      if (media1365.matches) {
        arrFirstCol.map((value) => {
          value.classList.add('md');
          value.classList.remove('lg', 'xlg');
        });
        arrSecondCol.map((value) => {
          value.classList.add('xs');
          value.classList.remove('md', 'lg', 'xlg');
        });
      }
    };

    const handle1599 = () => {
      if (media1599.matches) {
        arrFirstCol.map((value) => {
          value.classList.add('lg');
          value.classList.remove('md', 'xlg');
        });
        arrSecondCol.map((value) => {
          value.classList.add('md');
          value.classList.remove('xs', 'lg', 'xlg');
        });
      }
    };
    const handle1500 = () => {
      if (media1600.matches) {
        arrFirstCol.map((value) => {
          value.classList.add('xlg');
          value.classList.remove('lg');
        });
        arrSecondCol.map((value) => {
          value.classList.add('lg');
          value.classList.remove('md', 'xlg');
        });
      }
    };

    const handleMobile = (mobileMedia) => {
      if (mobileMedia.matches) {
        arrFirstCol.map((value) => {
          value.classList.add('xs');
          value.classList.remove('xlg', 'md', 'lg');
        });
        arrSecondCol.map((value) => {
          value.classList.add('xs');
          value.classList.remove('xlg', 'lg');
        });
      }
    };

    media1000.addListener(handle1000);
    isFeedPage ? media1200.addListener(handle1200Feed) : media1200.addListener(handle1200);
    media1365.addListener(handle1365);
    media1599.addListener(handle1599);
    media1600.addListener(handle1500);
    mobileMedia.addListener(handleMobile);

    handle1000(media1000);
    isFeedPage ? handle1200Feed(media1200) : handle1200(media1200);
    handle1365(media1365);
    handle1599(media1599);
    handle1500(media1600);
    handleMobile(mobileMedia);

    return () => {
      media1000.removeListener(handle1000);
      isFeedPage ? media1200.removeListener(handle1200Feed) : media1200.removeListener(handle1200);
      media1365.removeListener(handle1365);
      media1599.removeListener(handle1599);
      media1600.removeListener(handle1500);
      mobileMedia.removeListener(handleMobile);
    };
  });
};

export default ResizeArticle;
