import React from 'react';
import { useMediaQuery } from '../Dashboard/ResizeArticleInPage';
import ArticlesItem from '../../components/Articles/ArticlesItem';
import { nav, useTypes } from '../../common/PageTemplate/articleTypesFilter';
import PageTemplate from '../../common/PageTemplate/PageTemplate';
import { useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import { articlesQueryShortFormGql } from '../../gql/query';

const KnowledgeBaseQuizzes = () => {
  const isMobile = useReactiveVar(setIsMobile);
  let articleSize = useMediaQuery('(min-width: 1920px)');
  const { types } = useTypes();
  const { data } = useQuery(articlesQueryShortFormGql, {
    variables: {
      where: {
        draft: false,
      },
    },
  });

  return (
    <PageTemplate
      title="Quizzes"
      onlyDropdown
      dropdownPosition="left"
      dropdownLabelArr={nav}
      dropdownCheckboxArr={types}
    >
      <div className="cards-grid">
        {data?.articles.map((item) => {
          return (
            <ArticlesItem
              {...item}
              related
              size={isMobile ? 'xs' : articleSize ? 'lg' : 'md'}
              direction="col"
            />
          );
        })}
      </div>
    </PageTemplate>
  );
};

export default KnowledgeBaseQuizzes;
