import React, { useCallback } from 'react';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Button from '../../common/Button/Button';
import Tab from '../../common/Tab/Tab';
import UpcomingEventsPush from './UpcomingEventsPush';
import SearchData from './SearchData';
import { excludeRoute } from '../../constants';
import { logout } from '../../helper';
import Svg from '../../common/SVG';
import { Dropdown } from 'antd';
import Avatar from '../../common/Avatar/Avatar';
import { meQueryGql, userQueryGql } from '../../gql/query';

import _ from 'lodash';
import ButtonIcon from '../../common/Button/ButtonIcon';

const accountNav = [
  {
    icon: 'user',
    name: 'profile',
    to: '/profile',
  },
  {
    icon: 'draft',
    name: 'drafts',
    to: '/drafts',
  },
];

const notificationNewList = [
  {
    avatar: '/images/avatar-person-1.png',
    title: 'Dima mentioned you in the comments',
    text: '«AI-powered tools for Product Designers»',
  },
  {
    avatar: '/images/avatar-person-2.png',
    title: 'Dima mentioned you in the comments',
    text: '«AI-powered tools for Product Designers»',
  },
  {
    avatar: '/images/avatar-person-3.png',
    title: 'Dima mentioned you in the comments',
    text: '«AI-powered tools for Product Designers»',
  },
];

const notificationEarlierList = [
  {
    avatar: '/images/avatar-person-1.png',
    user: 'Vadim Abramov',
    title: 'followed you',
  },
  {
    avatar: '/images/avatar-person-2.png',
    user: 'Vadim Abramov',
    title: 'followed you',
  },
  {
    avatar: '/images/avatar-person-3.png',
    user: 'Vadim Abramov',
    title: 'followed you',
  },
];

const HeaderDesktop = () => {
  // const [shadowElement, setShadowElement] = useState('');
  const isMobile = useReactiveVar(setIsMobile);
  const { pathname } = useLocation();
  const { push } = useHistory();

  const onClick = useCallback(() => {
    logout(push);
  }, [push]);

  const [getUser, { data: dataUser }] = useLazyQuery(userQueryGql);

  useQuery(meQueryGql, {
    onCompleted({ me }) {
      getUser({ variables: { id: me.id } });
    },
  });

  // const handleScroll = e => {
  //   const element = e.target;
  //   if (element.scrollTop > 0) {
  //     setShadowElement('header-shadow');
  //   } else setShadowElement('');
  // };
  //
  // useEffect(() => {
  //   const scrollElement = document.getElementsByClassName('page__content')[0];
  //
  //   scrollElement.addEventListener('scroll', handleScroll);
  //
  //   return () => {
  //     scrollElement.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const [firstName, lastName] = _.map(_.words(dataUser?.user.username), _.capitalize);

  if (excludeRoute.includes(pathname) || isMobile || !dataUser) {
    return null;
  }

  const accountMenu = (
    <>
      <div className="dropdown-content-list__nav">
        {accountNav.map(({ name, icon, to }, index) => (
          <Link to={to} key={index}>
            <Tab key={name} textLeft={name} iconLeft={icon} block color="whitePurple" />
          </Link>
        ))}
      </div>

      <Tab textLeft="Log out" iconLeft="logout" block color="whitePurple" onClick={onClick} />
    </>
  );

  const notificationMenu = (
    <>
      <div className="header-notification-status new">
        <div className="header-notification-status__label">New</div>
        <div className="header-notification-status__line" />
      </div>

      <div className="header-notification__list new">
        {notificationNewList.map(({ avatar, title, text }, index) => (
          <div className="header-notification-item" key={index}>
            <div
              style={{ backgroundImage: `url(${avatar})` }}
              className="header-notification-item__avatar"
            />

            <div className="header-notification-item__inner">
              <div className="header-notification-item__title">{title}</div>

              <div className="header-notification-item__text">{text}</div>
            </div>

            <Button onClick={() => alert('Add new material')} text="See it" type="primary" />
          </div>
        ))}
      </div>

      <div className="header-notification-status earlier">
        <div className="header-notification-status__label">Earlier</div>
        <div className="header-notification-status__line" />
      </div>

      <div className="header-notification__list earlier">
        <UpcomingEventsPush />

        {notificationEarlierList.map(({ avatar, user, title }) => (
          <div className="header-notification-item" key={title}>
            <div
              style={{ backgroundImage: `url(${avatar})` }}
              className="header-notification-item__avatar"
            />

            <div className="header-notification-item__inner">
              <div className="header-notification-item__title">
                <Link to="#user">{user}</Link> {title}
              </div>
            </div>

            <Button onClick={() => alert('Add new material')} text="Follow back" type="primary" />
          </div>
        ))}
      </div>
    </>
  );

  return (
    <header className="header desktop">
      <SearchData />
      <div className="header__controls">
        <Link className="btn md outline-white" to="/cms">
          <Svg className="btn__icon-left" name="plus" />
          Add new material
        </Link>

        <div className="header-notification">
          <Dropdown
            overlay={notificationMenu}
            trigger={['click']}
            placement="bottomRight"
            className="header-notification-dropdown"
            disabled
          >
            <ButtonIcon disabled icon="bell" size="md" />
          </Dropdown>
        </div>

        <div className="header-account">
          <Dropdown
            overlay={accountMenu}
            trigger={['click']}
            placement="bottomRight"
            className="header-notification-dropdown"
          >
            <Link onClick={(e) => e.preventDefault()}>
              <div className="dropdown-btn__name">
                {firstName} {lastName}
              </div>
              <Avatar size="lg" author={dataUser.user.username} avatarImg={dataUser.user.avatar} />
              <Svg className="icon-arrow" name="arrow-down" />
            </Link>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default HeaderDesktop;
