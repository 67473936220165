import React from 'react';
import DropdownLabelPage from '../../components/Dropdown/DropdownLabelPage';
import { nav } from './articleTypesFilter';
import DropdownCheckboxPage from '../../components/Dropdown/DropdownCheckboxPage';
import MobileModalPageDropdown from '../../components/Dropdown/MobilePageDropdown/MobileModalPageDropdown';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const PageTemplateDropdown = ({
  dropdownPosition,
  dropdownLabelArr = nav,
  types,
  onSelectLabel,
  onSelectTypes,
  selectedLabel,
  selectedTypes,
}) => {
  const isMobile = useReactiveVar(setIsMobile);

  if (isMobile) {
    return (
      <MobileModalPageDropdown
        types={types}
        selectedTypes={selectedTypes}
        selectedLabel={selectedLabel}
        onSelectTypes={onSelectTypes}
        onSelectLabel={onSelectLabel}
      />
    );
  }

  return (
    <div className="dropdown-list">
      {dropdownLabelArr && (
        <DropdownLabelPage
          label={selectedLabel}
          array={dropdownLabelArr}
          position={dropdownPosition}
          onClick={onSelectLabel}
          selected={selectedLabel}
        />
      )}
      {types && (
        <DropdownCheckboxPage
          label="All Formats"
          array={types}
          selected={selectedTypes}
          position={dropdownPosition}
          onClick={(name) => onSelectTypes(name)}
        />
      )}
    </div>
  );
};

export default PageTemplateDropdown;
