import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { articleTypesQueryGql } from '../../gql/query';

export const nav = ['Newest', 'Oldest'];

export const nav2 = ['Less completed first', 'More completed first', 'Last added', 'First added'];

const TYPES = {
  'article': 'article',
  'video-1': 'video',
  'audio': 'audio',
  'document': 'document',
  'file': 'attach',
  'quiz': 'quiz',
  'web': 'link',
};

export const useTypes = () => {
  const { data } = useQuery(articleTypesQueryGql);
  const [selected, setSelected] = useState([]);

  const types = useMemo(() => {
    if (data) {
      return _.map(data.articleTypes, ({ type, slug }) => {
        return {
          name: type,
          icon: TYPES[slug],
        };
      });
    }
    return [];
  }, [data]);

  const selectedIds = useMemo(() => {
    if (data) {
      return _.map(
        _.filter(data.articleTypes, (i) => _.includes(selected, i.type)),
        'id'
      );
    }
    return [];
  }, [data, selected]);

  const onSelect = useCallback(
    (item) => {
      if (item === 'del') return setSelected([]);

      if (_.includes(selected, item)) {
        return setSelected([..._.remove(selected, (i) => i !== item)]);
      }

      return setSelected([...selected, item]);
    },
    [selected]
  );

  const onDeleteSelected = useCallback(() => {
    // console.log(selected, '<<')
    setSelected([...selected]);
  }, [selected]);

  const onSelectOneType = useCallback((item) => {
    setSelected([item]);
  }, []);

  return { selectedIds, types, selected, setSelected, onSelect, onDeleteSelected, onSelectOneType };
};
