import React from 'react';
import Button from '../../common/Button/Button';
import { useHistory, Link } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';

const LoginDefault = ({ children }) => {
  const isMobile = useReactiveVar(setIsMobile);
  let history = useHistory();

  return (
    <section className="login">
      <Link className="login-logo" to="/">
        <img className="login-logo__img" src="/images/logo.png" alt="logo" />
      </Link>

      <div className="login__inner">
        <Button
          onClick={() => history.push('/')}
          iconLeft="arrow-left"
          text="Back to landing page"
          type="text"
          size={isMobile ? 'md' : 'lg'}
        />

        <div className="login__wrapper">{children}</div>
      </div>
    </section>
  );
};

export default LoginDefault;
