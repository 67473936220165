import React from 'react';
import { setIsUserId } from '../../../gql/cache';
import ProfileSidebarPublicMobile from './ProfileSidebarPublicMobile';
import ProfileSidebarPersonalMobile from './ProfileSidebarPersonalMobile';
import { toast } from 'react-toastify';
import Svg from '../../../common/SVG';

const ProfileSidebarMobile = ({ user, itIsMe }) => {
  return (
    <div className="profile-sidebar-container">
      {user.id === setIsUserId() ? (
        <ProfileSidebarPersonalMobile user={user} />
      ) : (
        <ProfileSidebarPublicMobile user={user} />
      )}

      {/*TODO: use to show alert when update profile*/}
      {/*{toast.success(<div className="alert__text">Your profile changes have been successfully made</div>, {
        position: toast.POSITION.TOP_CENTER,
        icon: <Svg name="checkmark" />,
      })}*/}
    </div>
  );
};

export default ProfileSidebarMobile;
