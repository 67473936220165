import React, { useState } from 'react';
import Tab from '../../common/Tab/Tab';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { organizationsQueryGql } from '../../gql/query';
import { setIsUserId } from '../../gql/cache';

const SidebarKnowledgeBase = () => {
  const [isOpenCompany, setIsOpenCompany] = useState(false);
  const [isOpenLearning, setIsOpenLearning] = useState(false);

  const { data } = useQuery(organizationsQueryGql, {
    variables: {
      where: {
        usersPermissions: {
          id: setIsUserId(),
        },
      },
    },
  });

  const {
    push,
    location: { pathname },
  } = useHistory();

  const getPath = pathname.split('/')[1];
  const getPathId = pathname.split('/')[2];

  const handleFolder = (id) => {
    push(`/knowledge-base-company-folder/${id}`);
  };

  const handleCompany = (link) => {
    push(link);
  };

  const handleOpenCompany = (id) => {
    setIsOpenCompany(!isOpenCompany);
    handleCompany(`/knowledge-base-company/${id}`);
  };

  const handleOpenLearning = () => {
    setIsOpenLearning(!isOpenLearning);
    handleCompany('/knowledge-base-learning');
  };

  return (
    <div className="sidebar-sub-nav">
      <span className="sidebar-sub-nav-title">Knowledge base</span>
      {data?.organizations.map(({ id, name, logo, knowledgeBases }) => {
        return (
          <div key={id}>
            <Tab
              typeLeft="item"
              avatar
              avatarImg={logo}
              textLeft={name}
              typeRight="arrow"
              color="whitePurple"
              isOpen={isOpenCompany}
              active={isOpenCompany}
              onClick={() => handleOpenCompany(id)}
            />
            {isOpenCompany && (
              <div className="sidebar-sub-nav-list">
                {knowledgeBases.map(({ name, id }) => (
                  <Tab
                    onClick={() => handleFolder(id)}
                    key={id}
                    typeLeft="item"
                    textLeft={name}
                    active={getPath === 'knowledge-base-company-folder' && getPathId === id}
                    color="whitePurple"
                  />
                ))}
              </div>
            )}
          </div>
        );
      })}

      {/*<Tab*/}
      {/*  typeLeft="item"*/}
      {/*  textLeft="Learning"*/}
      {/*  iconLeft="learning"*/}
      {/*  typeRight="arrow"*/}
      {/*  color="whitePurple"*/}
      {/*  isOpen={isOpenLearning}*/}
      {/*  active={isOpenLearning}*/}
      {/*  onClick={handleOpenLearning}*/}
      {/*/>*/}

      {/*{isOpenLearning && (*/}
      {/*  <div className="sidebar-sub-nav-list">*/}
      {/*    {navList.map(({ icon, name, link }) => (*/}
      {/*      <>*/}
      {/*        <Tab*/}
      {/*          onClick={() => handleCompany(link)}*/}
      {/*          key={name}*/}
      {/*          typeLeft="item"*/}
      {/*          textLeft={name}*/}
      {/*          iconLeft={icon}*/}
      {/*          active={pathname === link}*/}
      {/*          color="whitePurple"*/}
      {/*        />*/}
      {/*      </>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default SidebarKnowledgeBase;
